import { Injectable } from '@angular/core';
// import { HttpClientService } from '../../../services/http-client.service';
import { TokenService } from '../token/token.service';

import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Login } from '../../../interfaces';
import { ClientService } from 'src/app/services/client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  prefix = 'Registration';
  private hideBrands = new BehaviorSubject(false);
  hideBrandsObservable = this.hideBrands.asObservable();
  private clientActivity

  constructor(
    // private http: HttpClientService,
    private tokenService: TokenService,
    private router: Router,
    private clientService: ClientService
  ) { }

  hideAllBrands() {
    this.hideBrands.next(true);
  }

  showAllBrands() {
    this.hideBrands.next(false);
  }

  public isAuthenticated(): boolean {
    const interval = 3 * 60 * 1000;
    const token = this.tokenService.getAuthToken();
    const client = JSON.parse(localStorage.getItem('client'));

    if (token && client) {
      this.clientActivityUpdate(client.id)
      this.clientActivity = setInterval(() => {
        this.clientActivityUpdate(client.id);
      }, interval);
      return true;
    }
    return false;
  }

  clientActivityUpdate(clientId: number){
    // this.clientService.updateClientStauts(clientId)
    // .subscribe(() => {});
  }

  public isRegistrationFirstStepPassed(): boolean {
    const client = this.getAuthClient();

    return client ? true : false;
  }

  // login(client): Observable<Login> {
  //   return this.http.post<Login>(this.prefix + '/Login', client);
  // }

  logout() {
    this.removeLoginSession();

    window.location.href = window.location.origin;
  }

  removeLoginSession() {
    this.tokenService.removeAuthToken();
    this.tokenService.removeAuthClient();
    localStorage.removeItem('activeAccount');
    localStorage.removeItem('mainInfo');
    localStorage.removeItem('tmp_account');
    localStorage.removeItem('registrationStep');
  }

  // forgotPassword(email) {
  //   return this.http.post('/forgotPassword', email)
  //     .toPromise()
  //     .then((response: Response) => {
  //       return response;
  //     });
  // }

  // checkResetPasswordToken(data) {
  //   const params = new HttpParams().append('token', data.token);
  //   params.append('email', data.email);
  //
  //   return this.http.get('/checkResetPasswordToken', {params: data})
  //     .toPromise();
  // }

  // resetPassword(token) {
  //   return this.http.post('/resetPassword', token)
  //     .toPromise()
  //     .then((response: Response) => {
  //       return response;
  //     });
  // }

  setAuthClient(client): void {
    localStorage.setItem('client', JSON.stringify(client));
  }

  getAuthClient(): any {
    return JSON.parse(localStorage.getItem('client'));
  }

  getAuthClientId(): number {
    const client = JSON.parse(localStorage.getItem('client'));
    if (client) {
      return client['id'];
    }
  }

}
