import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegistrationService } from '../services/registration/registration.service';
import { ToasterService } from 'angular2-toaster';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  private subscription: Subscription;
  public clientId: number;
  message=false
  code=''
  constructor(
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private toasterService: ToasterService,
    private router: Router,
    private authService: AuthService,

  ) { }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(param => {
      this.clientId = param['id'];
      // this.sendVerifyEmail()
    });

  }

  sendVerifyEmail() {
    this.registrationService.verifyEmail(this.clientId).subscribe(res => {
      if (res) {
        this.toasterService.pop('success', 'The verify email is sent');
      } else {
        this.toasterService.pop('error', 'something went wrong');
      }
    }, error => {
      this.toasterService.pop('error', 'something went wrong');
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  accept(){
    if(this.code){
      const payload ={
        code: this.code
      }
      this.registrationService.verifyCode(this.clientId, payload).subscribe(res => {
        this.message = true
        if (res.data) {
          this.authService.setAuthClient(res.data);
          this.router.navigate(['register-step-2', this.clientId]);
        } else {
          this.toasterService.pop('error', 'something went wrong');
        }
      }, error => {
        this.toasterService.pop('error', 'something went wrong');
      });
    }
  }
}
