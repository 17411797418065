import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from "angular2-toaster";
import { BrandService } from 'src/app/auth/services/brand/brand.service';
import { BrandIdEnum } from 'src/app/brand.enum';


import { Currency,} from '../../../../../interfaces';
import { AccountService } from '../../../../services/account/account.service';
import {LanguageService} from '../../../../../languages/services/language/language.service';


@Component({
  selector: 'app-bank-wire',
  templateUrl: './bank-wire.component.html',
  styleUrls: ['./bank-wire.component.scss']
})

export class BankWireComponent implements OnInit {
  bankWireForm: FormGroup;
  currencies: Currency[];
  bankList: any;
  bankInfoList: any;
  isChina = false;
  exChange = 1;
  validCurrency = true;
  isInternational = false;
  isEu = false;
  id = '14';


  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toasterService: ToasterService,
    private brandService: BrandService


  ) { }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.bankWireFormValidations();

    this.accountService.getPspCurrency(this.id).subscribe(currencies => {
      this.currencies = currencies.data;
    });

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.International:
        this.isInternational = true
        break;
      case BrandIdEnum.EU:
        this.isEu= true
        break;
      default:
        break;
    }
  }

  getBank(currencyIsoCode) {
    this.accountService.getPspBankList(this.id, currencyIsoCode).subscribe( banks => {
      this.bankList = banks.data;
      this.controls.bank.patchValue("");

    });
  }

  getBankInfo(recordId) {
    this.accountService.getPspBankInformation(this.id, recordId).subscribe( bankInfo => {
      this.bankInfoList = bankInfo.data;
    });
  }

  copyTarget(value) {
    navigator.clipboard.writeText(value)
    this.toasterService.pop("success", 'Copied !');
  }

  get controls() { return this.bankWireForm.controls; }

  bankWireFormValidations() {
    this.bankWireForm = this.formBuilder.group({
      currency: ['', [Validators.required]],
      bank: ['', [Validators.required]],
    });
  }

}
