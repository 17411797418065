import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { HttpClientService } from '../../../services/http-client.service';
// import { HttpClientService } from '../../../../services/http-client.service';
import { AppTest } from '../../../../interfaces/app-test';
import { EnableAppTest } from '../../../../interfaces/enable-app-test';
import { AcceptDecline } from '../../../../interfaces/accept-decline-test';
import { environment } from '../../../../../environments/environment';
import { NewHttpClientService } from 'src/app/services/new-http-client.service';

@Injectable()
export class AppropriatenessTestService {



  private tests = new BehaviorSubject(null);
  private answers = new BehaviorSubject([]);
  // New /////////////
  private appTestData = new BehaviorSubject([]);
  appTestDataObsevable = this.appTestData.asObservable();

  private financialPortraitForm = new BehaviorSubject(null);
  financialPortraitFormObservable = this.financialPortraitForm.asObservable()
/////////////////////
  // TODO: add interface for tests ?
  testsObservable = this.tests.asObservable();
  answersObservable = this.answers.asObservable();
  finalPortraitAnswers = [];
  levelOfKnowledge = [];
  apiURL = '';

  constructor(
    private newHttp: NewHttpClientService,
    private httpClient: HttpClient,
  ) {
    // this.setNewData(this.tempData.data)
  }

  setTests(tests: object) {
    this.tests.next(tests);
  }

  //// NEW /////////////////

  setNewData( data) {
    this.appTestData.next(data);
  }

  setFinancialPortraitFormObservable(data) {
    this.financialPortraitForm.next(data)
  }


  getAppTest(): Observable<any> {
    const url = `AppropriatenessTest`;
    return this.newHttp.get<any>(url);
  }


  setAppTest(clientId, payload): Observable<any> {
    const url = `AppropriatenessTest/${clientId}`;
    return this.newHttp.put<any>(url, payload);
  }

  appTestEnableCheck(clientId): Observable<any> {
    const url = `AppropriatenessTest/${clientId}`;
    return this.newHttp.post<any>(url, null);
  }

  setAppTestStatusByPopUpAction(clientId, payload): Observable<any> {
    const url = `AppropriatenessTest/${clientId}`;
    return this.newHttp.patch<any>(url, payload);
  }


  getCountryShortList(): Observable<any> {
    const url = `country/shortlist`;
    return this.newHttp.get<any>(url);
  }

  getCountryBlockedShortList(): Observable<any> {
    const url = `country/shortlist/registration`;
    return this.newHttp.get<any>(url);
  }

  /////////////////////

  // getTestsFromJson() {
  //   // return this.httpClient.get('./assets/mockups/tests.json');
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/GetAppropriatnestTestQuestionsAnswers?ClientId='  + clientId);
  // }

  // public getAppropriatnesstTest(brandId: number) {
  //   return this.httpClient.get(
  //     this.apiURL + 'Registration/GetAppropratnessTest?BrandId=' + brandId
  //   );
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/GetAppropratnessTest?BrandId=' + brandId);
  // }

  // getClientTestAnswers(id: number)
  // {
  //    return this.httpClient.get(this.apiURL + 'Registration/GetEnabledClientAnswers/' + id);
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/GetEnabledClientAnswers/' + id);
  // }

  // getClientTestScore(id: number) {
  //   return this.httpClient.get(
  //     this.apiURL + 'Registration/GetAppropraitnessTestClientScore?id=' + id
  //   );
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/GetAppropraitnessTestClientScore?id=' + id);
  // }

  // TODO REVIEW
  // getTestTerms(id: number) {
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/GetTestTerms?ClientId=' + id);
  //   return this.httpClient.get(
  //     this.apiURL + 'Registration/GetTestTerms?ClientId=' + id
  //   );
  // }

  // public setAppropriatnessTestQuestionAnswers(client): Observable<AppTest> {
  //   return this.httpClient.post<AppTest>(
  //     this.apiURL + 'Registration/SetAppropriatenessTestQuestionAnswers',
  //     client
  //   );
  //   // return this.httpClient.post<AppTest>('https://localhost:44366/api/Registration/SetAppropriatenessTestQuestionAnswers', client);
  // }

  // public enablePassAppropriatnestTest(client): Observable<EnableAppTest> {
  //   return this.httpClient.post<EnableAppTest>(this.apiURL, client);
  //   // return this.httpClient.put<EnableAppTest>('https://localhost:44366/api/Registration/RetakeAppropriatenessTest', client);
  // }

  // public acceptDeclineTestResult(client): Observable<AcceptDecline> {
  //   return this.httpClient.post<AcceptDecline>(
  //     this.apiURL + 'Registration/AcceptDeclineTestResult',
  //     client
  //   );
  //   // return this.httpClient.put<AcceptDecline>('https://localhost:44366/api/Registration/AcceptDeclineTestResult', client);
  // }

  // public IsClientAppropraitnessTestEnabled(id: number): Observable<boolean> {
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/IsClientAppropraitnessTestEnabled/' + id);
  //   return this.httpClient.get<boolean>(
  //     this.apiURL + 'Registration/IsClientAppropraitnessTestEnabled/' + id
  //   );
  // }
  // public isComplianceSettingsAppropraitnessTestEnabled(
  //   id: number
  // ): Observable<boolean> {
  //   // return this.httpClient.get('https://localhost:44366/api/Registration/IsClientAppropraitnessTestEnabled/' + id);
  //   return this.httpClient.get<boolean>(
  //     this.apiURL +
  //       'Registration/IsAppropraitnessTestComplianceSettingsEnabled/' +
  //       id
  //   );
  // }

  getTests(): object {
    return this.tests.value;
  }

  addAnswers(answers: {}[]): void {
    this.answers.next(this.answers.getValue().concat(answers));
    this.finalPortraitAnswers = answers;
  }

  addFinalPortraitAnswers(answers: {}[]): void {
    this.answers.next(this.answers.getValue().concat(answers));
    this.levelOfKnowledge = answers;
  }

  public getFinalPortraitAnswers() {
    return this.finalPortraitAnswers;
  }

  public getLevelOfKnowledge() {
    return this.levelOfKnowledge;
  }

  clearTestAnswers() {
    this.answers.next([]);
  }

  clearSecondStepAnswers() {
    this.answers.next([]);
  }

  clearTests() {
    this.tests.next([]);
  }

  getAnswers(): {}[] {
    return this.answers.value;
  }

  updateAnswers(answers: {}[]) {
    this.answers.next(answers);
  }
}
