import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountTypeService } from './account-type/services/account-type.service';
import { Router } from '@angular/router';
import { AccountService } from '../services/account/account.service';
import { AuthService } from '../../auth/services/auth/auth.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  currentType: object;
  isAccountTypeChosen: boolean;
  isAccountExist = true;
  private currentTypeSubscription;
  private isAccountTypeSubscription;
  clientId: number;

  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private accountTypeService: AccountTypeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.clientId = this.authService.getAuthClientId();

    this.accountService.getClientAccounts(this.clientId).subscribe(accounts => {
      if(accounts.status===1){
        if (!accounts.data.length) {
          this.isAccountExist = false;
        } else {
          this.isAccountExist = true;
        }
      }
    });

    this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(type => {
      if ('undefined' !== typeof type && 'undefined' !== typeof type['id']) {
        this.currentType = type;
      }
    });

    this.isAccountTypeSubscription = this.accountTypeService.isAccountTypeChosenObservable.subscribe(type => {
      this.isAccountTypeChosen = type;
    });
  }

  changeAccountType() {
    this.router.navigate(['account-type']);
  }

  ngOnDestroy() {
    this.currentTypeSubscription.unsubscribe();
    this.currentTypeSubscription.complete();

    this.isAccountTypeSubscription.unsubscribe();
    this.isAccountTypeSubscription.complete();
  }

}
