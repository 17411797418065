import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DepositTypeService } from '../services/deposit-type.service';
import { BrandService } from 'src/app/auth/services/brand/brand.service';
import { BrandIdEnum } from 'src/app/brand.enum';

@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements OnInit {

  constructor(private depositTypeService: DepositTypeService, private brandService: BrandService, private router: Router) {}
  payNowsorinDescription = true;
  hash: string;
  hostName: string;
  pspId = 16;
  displayName: string;
  public isOtb: boolean;
  public isRevolution: boolean;

  ngOnInit() {
    this.hostName = window.location.hostname;
      switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
           break;
      case BrandIdEnum.International:
      case BrandIdEnum.southAfrica:
        break;
      case BrandIdEnum.OTB:
        this.isOtb = true;
        break;
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
         break;
      default:
        break;
    }
    this.getPayNowById();
  }

  getPayNowById() {
    this.depositTypeService
      .getPayNowById(this.pspId)
      .subscribe(result => {
        if (result.data) {
          this.hash = result.data.link;
          this.displayName = result.data.displayName;
        }
      });
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val['innerText'];
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  navigateTo() {
    // this.router.navigate([`${this.hash}`]);
    console.log(this.hash);
    window.open(this.hash, '_blank');
  }

}
