import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../auth/services/auth/auth.service";
import { ComplianceService } from "../services/compliance/compliance.service";
import { RegistrationService } from "../../auth/services/registration/registration.service";
import { AppTestService } from "../../auth/services/app-test/app-test.service";
import { AppropriatenessTestService } from "../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { forkJoin } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-verify-account-layout",
  templateUrl: "./verify-account-layout.component.html",
  styleUrls: ["./verify-account-layout.component.scss"],
})
export class VerifyAccountLayoutComponent implements OnInit {
  // isOtb = false;
  complianceStatus;
  testStatus;
  showAppropriatenessTestStatus: boolean;
  clientId: number;
  brandId: number;
  tuDoFx = false
  localBrandId = `${localStorage.getItem('localBrandId')}`;
  constructor(
    private router: Router,
    private authService: AuthService,
    private complianceService: ComplianceService,
    private registrationService: RegistrationService,
    private appropriatenessTestService: AppropriatenessTestService
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((val) =>{
      if (val instanceof NavigationEnd){
        this.getAppTestCheck()
      }
    })
    this.clientId = this.authService.getAuthClientId();

    this.getAppTestCheck()


    if (+this.localBrandId === BrandIdEnum.TuDoFx) {
      this.tuDoFx = true;
    }

    this.complianceService.getComplianceStatus().subscribe((res) => {
      let name;
      switch (res.data) {
        case 1:
          name = "New";
          break;
        case 2:
          name = "In Review";
          break;
        case 3:
          name = "Approved";
          break;
        case 4:
          name = "Rejected";
          break;
        case 5:
          name = "Closed";
          break;
        case 0:
        default:
          name = "";
          break;
      }
      this.complianceStatus = name;
    });

    this.getAppropriatenessTestStatus();

    // if (window.location.hostname !== 'portal.fxcm-globe.com') {
    //   this.getAppropriatenessTestStatus();
    // }

    // if (window.location.hostname === 'portal.fxcm-globe.com') {
    //   this.showAppropriatenessTestStatus = false;
    // }
  }

  getAppTestCheck(){
    this.appropriatenessTestService.appTestEnableCheck(this.clientId)
    .subscribe((result)=>{
      if (result.status === 1) {
        if(result.data){
          this.getAppTest()
        }
       this.showAppropriatenessTestStatus = result.data
      }
    })
  }

  getAppTest(){
    this.appropriatenessTestService.getAppTest()
    .subscribe((result)=>{
      if (result.status === 1) {
        this.appropriatenessTestService.setNewData(result.data)
      }
    })
  }

  getAppropriatenessTestStatus() {
    // this.registrationService
    //   .getAppropriatenessTestLevel(this.clientId)
    //   .subscribe((res) => {
    //     let name;
    //     switch (res["status"]) {
    //       case 0:
    //         name = "INCOMPLETE";
    //         break;
    //       case 1:
    //         name = "REJECTED";
    //         break;
    //       case 2:
    //       case 3:
    //       case 4:
    //         name = "APPROVED";
    //         break;
    //     }

    //     this.testStatus = name;
    //     // this.appTestService.setTestStatus(name);
    //   });
  }
}
