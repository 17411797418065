import { Injectable } from '@angular/core';
// import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs';
import { Country } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  prefix = 'ClientStatus';

  constructor(
    // private http: HttpClientService,
  ) { }

  // public updateClientStauts(clientId: number): Observable<any> {
  //   const url = `${this.prefix}/UpdateOnlineStatus?clientId=${clientId}`
  //   return this.http.put<any>(url, null);
  // }
}
