import { Component, OnInit, Input, Output,  OnDestroy, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from '../../../../page.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AppropriatenessTestService } from '../sevices/appropriateness-test.service';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../../../auth/services/auth/auth.service';

@Component({
  selector: 'app-financial-portrait',
  templateUrl: './financial-portrait.component.html',
  styleUrls: ['./financial-portrait.component.scss'],

})
export class FinancialPortraitComponent implements OnInit, OnDestroy {
  financialPortraitData ={}
  radioAndFreeText={}
  check=false
  countryShortList
  ////////////
  financialPortraitForm: FormGroup;
  validForm = true;
  pageName = 'Financial Portrait';

  chooseOptionsArray = [];

  faCircle = faCircle;
  faCheckCircle = faCheckCircle;

  textbox;
  applyQuestionAnswer: boolean;
  clientId: number;
  userForm: any;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
    private authService: AuthService
  ) {
    this.financialPortraitForm = this.fb.group({})
   }



  ngOnInit() {
    const id = 1
    this.appropriatenessTestService.financialPortraitFormObservable
    .subscribe(res=>{
      this.appropriatenessTestService.appTestDataObsevable
      .subscribe((result)=>{
        if(result.length){
          this.financialPortraitData = result.find(
            category=>category.id === id
          )
          this.initForm(this.financialPortraitData)
        }

      })
      if(res){
        console.log('sdf')
        this.financialPortraitForm = res
        this.financialPortraitForm.updateValueAndValidity()
      }

    })



    this.appropriatenessTestService.getCountryShortList()
    .subscribe((result)=>{
      if(result.status === 1){
        this.countryShortList = result.data
      }

    })

  }



  initForm(data){
    data.questions.forEach(question=>{
      this.financialPortraitForm.setControl(question.id, new FormControl (null, [Validators.required]))
    })

  }

  onCheck(status){
    this.check = status
  }

  textChange(text, question, answer){
    // console.log(text, question, answer)
    this.financialPortraitForm.get(`${question}`).setValue({
      answer,
      text
    })
  }


  submit(){
    const payload = []
    for (const [key, value] of Object.entries(this.financialPortraitForm.value)) {
      if(! (value instanceof Object)){
        const cell = {
          questionId : +key,
          answerId : value,
          customText : null
        }
        payload.push(cell)
      } else {
        const cell = {
          questionId : +key,
          answerId : value['answer'],
          customText : value['text']
        }
        payload.push(cell)
      }
    }
    this.appropriatenessTestService.setFinancialPortraitFormObservable(this.financialPortraitForm)
    console.log(this.financialPortraitForm.value, this.financialPortraitForm.valid);

    if(!this.financialPortraitForm.valid){
      this.pageService.changePageName('Level of Knowledge');
      // this.router.navigate(['verify-account', 'appropriateness-test', 'level-of-knowledge']);
    }

    console.log(payload);

    // if (this.questionsForm.valid) {
    //   const data = this.questionsForm.value.questions;
    //   for(var i = 0; i < data.length; i++)
    //   {
    //     if(data[i].QuestionId === 3)
    //     {
    //       data[i].AnswerText = textvalue
    //     }
    //   }
    //   this.appropriatenessTestService.addAnswers(data);
    //   this.router.navigate(['verify-account', 'appropriateness-test', 'level-of-knowledge']);
    // } else {
    //   this.validForm = false;
    // }
  }




  ngOnDestroy() {

  }

}
