import { ResetPassword } from './../../interfaces/reset-password';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, FormControl} from '@angular/forms';
import { AccountService } from '../services/account/account.service';
import { ValidationService } from '../../services/validation/validation.service';
import { RegistrationService } from '../../auth/services/registration/registration.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { AuthService } from '../../auth/services/auth/auth.service';
import { PageService } from '../../page.service';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BasicInfo } from '../../interfaces';
import { BrandIdEnum } from 'src/app/brand.enum';



@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  websitePass = false;
  changePasswordForm: FormGroup;
  validPasswordType = true;
  validAccount = true;
  validExistingPassword = true;
  validNewPassword = true;
  validConfirmPassword = true;
  clientId: number;
  private accountsSubscription;
  passwordShowObject = {
    inputType: "password",
    icon: faEye,
  };
  passwordHideObject = {
    inputType: "text",
    icon: faEyeSlash,
  };
  passwordShowHideObject = this.passwordShowObject;
  confirmPasswordShowHideObject = this.passwordShowObject;
  successMessage: string;
  resetPassword: string;
  accounts;
  MT4Accounts;
  fullAccounts;
  showAccountSelect = false;
  public localBrandId = `${localStorage.getItem("localBrandId")}`;

  passwordTypes = [
    {
      account: "Website password",
    },
    {
      account: "MT4 password",
    },
    {
      account: "Investor password",
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private validationService: ValidationService,
    private registrationService: RegistrationService,
    private router: Router,
    private toasterService: ToasterService,
    private authService: AuthService,
    private pageService: PageService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    const hostname = window.location.hostname;
    // if(hostname !== 'portal.otb-trading.com') {
    //   this.passwordTypes.push(
    //     {
    //       account: 'Investor password'
    //     }
    //   );
    // }

    this.translateService
      .get("MESSAGES.SUCCESS.RESET_PASSWORD")
      .subscribe((text) => {
        this.successMessage = text;
      });

    this.translateService.get("RESET_PASSWORD.TITLE").subscribe((text) => {
      this.resetPassword = text;
    });

    this.changePasswordForm = this.formBuilder.group({
      password_type: ["", [Validators.required]],
      // account_number: [""],
      // email: ["", [Validators.required, Validators.email]],
      // code: ["", [Validators.required]],
      new_password: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9]).{5,15}$/),
        ],
      ],
      confirm_password: ["", [this.checkConfirm()]],
    });

    this.accountsSubscription =
      this.accountService.accountsObservable.subscribe((accounts) => {
        this.accounts = [];
        this.fullAccounts = accounts;
        this.MT4Accounts = accounts.filter(
          (elem) =>
            // !elem.isDemoAccount &&
            !elem.isWalletAccount &&
            elem.tradingPlatform === 1
        );
      });

    this.clientId = this.authService.getAuthClientId();
    switch (+this.localBrandId) {
      case BrandIdEnum.TuDoFx:
      case BrandIdEnum.tradeCenter:
      case BrandIdEnum.tradeMarkets:
      case BrandIdEnum.sefx:
        this.passwordTypes = [
          {
            account: "Website password",
          },
          {
            account: "MT4 password",
          },
        ];
    }

    this.changePasswordForm.valueChanges.subscribe((value) =>
      value.password_type == "MT4 password"
        ? (this.accounts = this.MT4Accounts)
        : (this.accounts = this.fullAccounts)
    );
  }


  submit(): void {
    if (this.changePasswordForm.valid) {

    // this.pageService.changeLoadingStatus(true);

    const password_type = this.changePasswordForm.value.password_type;
    const password = this.changePasswordForm.value.new_password;

    if (password_type === "Website password") {
      const {code, new_password} = this.changePasswordForm.value;
      const payload = {
        code,
        newPassword: new_password,
      };

      this.registrationService
        .verifyResetPass(this.clientId, payload)
        .subscribe((res) => {
          if (res.status === 1) {
            this.pageService.changeLoadingStatus(false);
            this.router.navigate(["/"]);
            this.toasterService.pop("success", "", res.message);
          }
        });
    } else if (password_type === "Investor password") {
      const account_number = this.changePasswordForm.value.account_number;
      const data = {
        // clientId: this.clientId,
        Login: account_number,
        Password: password,
      };

      this.registrationService
        .updateClientMT4InvestorPasswor(data)
        .subscribe((res) => {
          if (res.status === 1) {
            this.pageService.changeLoadingStatus(false);
            this.router.navigate(["/"]);
            this.toasterService.pop("success", "", res.message);
          }
        });
    } else {
      const account_number = this.changePasswordForm.value.account_number;
      const data = {
        // clientId: this.clientId,
        Login: account_number,
        Password: password,
      };

      this.registrationService.mt4ResetPass(data).subscribe((res) => {
        if (res.status === 1) {
          this.pageService.changeLoadingStatus(false);
          this.router.navigate(["/"]);
          this.toasterService.pop("success", "", res.message);
        }
      });
    }
  }
}

  get controls() {
    return this.changePasswordForm.controls;
  }

  getCode() {
    const payload = {
      emailAddress: this.changePasswordForm.get("email").value,
    };
    this.registrationService.resetPass(payload).subscribe((data) => {
      if (data.status === 1) {
        this.toasterService.pop("success", "", data.message);
      }
    });
  }

  changePasswordTypeSelect(ev): void {
    if (ev.target.value === "Website password") {
      this.changePasswordForm.addControl('email', new FormControl("", [Validators.required, Validators.email]));
      this.changePasswordForm.addControl('code', new FormControl("", Validators.required));
      this.websitePass = true;
    } else {
      this.changePasswordForm.removeControl('email');
      this.changePasswordForm.removeControl('code');
      this.websitePass = false;
    }

    this.validPasswordType = true;
    if (
      this.changePasswordForm.value.password_type === "MT4 password" ||
      this.changePasswordForm.value.password_type === "Investor password"
    ) {
      this.showAccountSelect = true;
      this.changePasswordForm.addControl('account_number', new FormControl(""));
      this.changePasswordForm
        .get("account_number")
        .setValidators(Validators.required);
      this.changePasswordForm.get("account_number").updateValueAndValidity();
    } else {
      this.showAccountSelect = false;
      this.changePasswordForm.get("account_number").reset();
      this.changePasswordForm.removeControl('account_number');

    }
    this.changePasswordForm.updateValueAndValidity();
  }

  changeAccountSelect(ev): void {
    this.validAccount = true;
  }

  checkConfirm(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        this.changePasswordForm &&
        control.value === this.changePasswordForm.controls.new_password.value
      ) {
        return null;
      } else {
        return { notSame: true };
      }
    };
  }

  ngOnDestroy() {
    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();
  }

  showHidePassword() {
    if (this.passwordShowHideObject.inputType === "text") {
      this.passwordShowHideObject = this.passwordShowObject;
    } else {
      this.passwordShowHideObject = this.passwordHideObject;
    }
  }

  showHideConfirmPassword() {
    if (this.confirmPasswordShowHideObject.inputType === "text") {
      this.confirmPasswordShowHideObject = this.passwordShowObject;
    } else {
      this.confirmPasswordShowHideObject = this.passwordHideObject;
    }
  }
}
