import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BrandService {
  private brands = new BehaviorSubject([]);
  private mainBrand = new BehaviorSubject({});
  private actualBrand = new BehaviorSubject("");
  private chosenBrand = new BehaviorSubject(null);
  brandsObservable = this.brands.asObservable();
  mainBrandObservable = this.mainBrand.asObservable();
  actualBrandObservable = this.actualBrand.asObservable();
  chosenBrandObservable = this.chosenBrand.asObservable();

  constructor() {}

  setBrands(brands: object[]): void {
    this.brands.next(brands);
  }

  setMainBrand(brandName: object): void {
    this.mainBrand.next(brandName);
  }

  getMainBrand(): object {
    return this.mainBrand.value;
  }

  changeChosenBrand(brand: object): void {
    this.chosenBrand.next(brand);
  }

  getChosenBrand(): object {
    return this.chosenBrand.value;
  }

  chooseBrandByCountryCode(countryCode: string): void {
    this.brands.value.forEach(brand => {
      const availableCountryCodes = brand.availableCountryCodes.split(",");
      if (availableCountryCodes.indexOf(countryCode) >= 0) {
        this.changeChosenBrand(brand);
      }
    });
  }
  getCurrentWebsitesBrandId(){
    return `${localStorage.getItem('localBrandId')}`
  }

  // TODO: Implement retrieving settings from server when access to this code is given. Currently don't have access to code, and was forced
  // to implement some MOCK method.
  // getBrandSetting(brandId: number, settingName: string) {
  //   switch (brandId) {
  //     // TODO Review most probably not in use
  //     case 1:
  //     case 5:
  //     case 2:
  //     case 6:
  //       switch (settingName) {
  //         case "skrill_pay_to_email_USD":
  //           return "hila@fxglobe.com";
  //         case "skrill_pay_to_email_EUR":
  //           return "Deposits@fxglobe.com";
  //         case "fasapay_account_no":
  //           return "FP416922";
  //       }
  //       break;
  //   }
  // }

  // TODO REPLACE MF Hardcoded part
  // getCurrentWebsitesBrandId() {
  //   const hostname = window.location.hostname;
  //   let brandId;
  //   switch (hostname) {
  //     case "localhost":
  //     case "portal.fxglobe.com":
  //     case "portal.mgflex.com":
  //     case "portal.applebrie.com":
  //     case "portal.applebrie.co":
  //       brandId = 1;
  //       break;
  //     case "portal.fxglobe.international":
  //     case "portal-dev.fxglobe.international":
  //       brandId = 2;
  //       break;
  //     case "portal.swisscapital.fm":
  //       brandId = 3;
  //       break;
  //     case "portal.vakfx.com":
  //       brandId = 7;
  //       break;
  //     case "portaleu.vakfx.com":
  //       brandId = 11;
  //       break;
  //     case "portal.fxcm-globe.com":
  //       brandId = 29;
  //       break;
  //     case "portal.otb-trading.com":
  //       brandId = 42;
  //       break;
  //   }
  //   return brandId;
  // }
}
