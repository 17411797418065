import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AccountService } from '../services/account/account.service';
import { Router } from '@angular/router';
import { AccountBalance, Balance } from '../../interfaces';
import { AuthService } from '../../auth/services/auth/auth.service';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Account } from '../../interfaces';

@Component({
  selector: 'app-manage-funds',
  templateUrl: './manage-funds.component.html',
  styleUrls: ['./manage-funds.component.scss']
})
export class ManageFundsComponent implements OnInit, OnDestroy {

  addFundsForm: FormGroup;
  transferForm: FormGroup;
  transferFromItems: object;
  transferToItems: object;
  accounts: any;
  validTransferFrom = true;
  validTransferTo = true;
  validAmount = true;
  validFromAmount = true;
  validChooseAccount = true;
  accountBalance: Balance;
  errorText = ''

  // private accountsSubscription;
  private activeAccountSubscription;
  successText: string;
  faSync = faSync;
  chosenAccount: Account;
  clientId: number;
  fundBtn = false;
  withdrawalBalance

  amountValidationRules = [
    Validators.required,
    Validators.pattern(/^\d+$/),
    Validators.min(1),
    Validators.max(this.withdrawalBalance)
  ];

  balances = {
    from: null,
    to: null
  }

  exChange = 1

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.addFundsForm = this.formBuilder.group({
      account: [''],
    });

    this.fireTransferFormValidations();

    this.clientId = this.authService.getAuthClientId();

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
    //   if (accounts.length > 0) {
    //     this.accounts = accounts;
    //     this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
    //       if (activeAccount) {
    //         this.accountBalance = undefined;
    //         this.chosenAccount = activeAccount;
    //         this.addFundsForm.get('account').patchValue(activeAccount.accountNumber);
    //         this.changeChosenAccount(activeAccount.accountNumber)

    //         // this.accountService.getBalance(this.clientId, activeAccount.accountId).subscribe(res => {
    //         //   this.accountBalance = res;
    //         // });
    //       }
    //     });

    //     this.transferFromItems = accounts;
    //     this.transferToItems = accounts;
    //   }
    // });

    this.accountService.getClientAccountByAccountType(1).subscribe((accounts) => {
      if (accounts.data.length > 0) {
        this.accounts = accounts.data
        this.activeAccountSubscription =
          this.accountService.activeAccountObservable.subscribe(
            (activeAccount: Account) => {
              if (activeAccount) {
                this.accountBalance = undefined;
                this.chosenAccount = activeAccount;
                this.addFundsForm
                  .get("account")
                  .patchValue(activeAccount.accountNumber);
                this.changeChosenAccount(activeAccount.accountNumber);
              }
            }
          );
        this.transferFromItems = this.accounts;
        this.transferToItems = this.accounts;
      }

      this.transferForm.valueChanges.subscribe(() => this.errorText = '')
  });

    this.translateService.get('MESSAGES.SUCCESS.EDIT_INFO').subscribe(text => {
      this.successText = text;
    });
  }

  submitAddFundsForm(): void {
    // const account = this.addFundsForm.value.account;
    // if (account.length > 0) {
    //   localStorage.setItem('tmp_account', account);
    // }

    this.router.navigate(['/deposit-type']);
  }

  submitManageFundForm(): void {
    if (this.transferForm.valid) {
      const transferFromId = Number(this.transferForm.value.transfer_from);
      const transferToId = Number(this.transferForm.value.transfer_to);

      const fundData = {
        sourceAccountId: transferFromId,
        destinationAccountId: transferToId,
        amount: this.transferForm.value.amountFrom,
      };

      this.accountService.manageFunds(fundData).subscribe(res=>{
        if(res.status===1){
          this.toasterService.pop("success", 'Success', res.message);

          this.router.navigate(['/accounts']);
        }
        else if(res.status===2) {
          this.errorText = res.message
        }
      })

      // this.accountService.fund(fundData).subscribe(res => {
      //   this.fundBtn = false;
      //   let successText = '';
      //   let errorText = '';
      //   let transferSuccess = '';
      //   let noEnoughBalanceError = '';

      //   this.translateService.get('MESSAGES.SUCCESS.SUCCESS').subscribe(text => {
      //     successText = text;
      //   });
      //   this.translateService.get('MESSAGES.SUCCESS.TRANSFER').subscribe(text => {
      //     transferSuccess = text;
      //   });
      //   this.translateService.get('MESSAGES.DECLINE.ERROR').subscribe(text => {
      //     errorText = text;
      //   });
      //   this.translateService.get('MESSAGES.DECLINE.ENOUGH_BALANCE').subscribe(text => {
      //     noEnoughBalanceError = text;
      //   });

      //   const status = res['status'];

      //   switch (status) {
      //     case 0: // Success
      //       this.toasterService.pop('success', successText, transferSuccess);
      //       this.makeTransferFormEmpty();

      //       if (this.chosenAccount.accountId === transferFromId || this.chosenAccount.accountId === transferToId) {
      //         // Update balance from manage-funds page
      //         this.updateBalance();
      //       }

      //       // Check if active account from header is the same as from or to
      //       const activeAccount = this.accountService.getActiveAccount();
      //       if (activeAccount.accountId === transferFromId || activeAccount.accountId === transferToId) {
      //         this.accountService.setActiveAccountBalanceChanged(true);
      //       }
      //       // TODO: update dashboard balance also if it's the same in from/to
      //       // Update balance in the header
      //       break;
      //     case 1: // AccountIdNotFound
      //       console.log('AccountIdNotFound');
      //       break;
      //     case 2: // ClientIdNotFound
      //       console.log('ClientIdNotFound');
      //       break;
      //     case 3: // NoEnoughBalance
      //       this.toasterService.pop('error', errorText, noEnoughBalanceError);
      //       break;
      //     case 4: // ConvertionRateNotFound
      //       console.log('ConvertionRateNotFound');
      //       break;
      //     default:
      //       console.log('default');
      //   }
      // }, error => {
      //   console.log(error);
      //   this.makeTransferFormEmpty();
      // });

    } else {
      this.validTransferFrom = this.controls['transfer_from'].valid;
      this.validTransferTo = this.controls['transfer_to'].valid;
      this.validAmount = this.controls['amount'].valid;
      this.validFromAmount = this.controls['amountFrom'].valid;
    }
  }

  get controls() { return this.transferForm.controls; }

  changeChosenAccount(value): void {
    this.validChooseAccount = true;
    this.accountBalance = undefined;

    const chosenAccountNumber = Number(this.addFundsForm.value.account);
    this.chosenAccount = this.accounts.find((account: Account) => {
      return account['id'] === chosenAccountNumber;
    });

    this.accountService.getMaximumWithdrawalAmount( value )
    .subscribe(res => {
      if(res.status===1){
        this.accountBalance = res;
      }
    });
  }

  changeTransferFromSelect(): void {
    this.validTransferFrom = true;
    const transferFrom = this.transferForm.value.transfer_from;
    const transferToItems = this.accounts;

    const transferToItemsFiltered = transferToItems.filter((item) => {
      if (item['id'] === Number(transferFrom)) {
        this.accountService.getMaximumWithdrawalAmount( item.accountNumber )
        .subscribe(res => {
          if(res.status===1){
            this.transferForm.get('amountFrom').setValidators(null)
            this.transferForm.get('amountFrom').updateValueAndValidity();

            let rules = [
              Validators.required,
              Validators.min(1),
              Validators.max(Number(res.data.maxAmount))
            ];

            this.transferForm.get('amountFrom').setValidators(rules)
            this.transferForm.get('amountFrom').updateValueAndValidity();

            this.balances.from = res.data.maxAmount

            if (
              this.transferForm.get('transfer_from').value &&
              this.transferForm.get('transfer_to').value
            ) {
              this.getRates()
            }
          }
        });

        return false;
      } else {
        return true;
      }
    });

    this.transferToItems = transferToItemsFiltered;
  }

  changeTransferToSelect(): void {
    this.validTransferTo = true;

    const transferTo = this.transferForm.value.transfer_to;

    const transferFromItems = this.accounts;

    const transferFromItemsFiltered = transferFromItems.filter((item) => {
      if (item['id'] === Number(transferTo)) {
        this.accountService.getMaximumWithdrawalAmount( item.accountNumber )
        .subscribe(res => {
          if(res.status===1){
            this.transferForm.get('amount').setValidators(null)
            this.transferForm.get('amount').updateValueAndValidity();

            let rules = [
              Validators.required,
              Validators.min(1),
              // Validators.max(Number(res.data.maxAmount))
            ];

            this.transferForm.get('amount').setValidators(rules)
            this.transferForm.get('amount').updateValueAndValidity();

            this.balances.to = res.data.maxAmount

            if (
              this.transferForm.get('transfer_from').value &&
              this.transferForm.get('transfer_to').value
            ) {
              this.getRates()
            }
          }
        })
        return false;
      } else {
        return true;
      }
    });

    this.transferFromItems = transferFromItemsFiltered;
  }

  getRates() {
    this.transferForm.patchValue({
      amountFrom: 0,
      amount: 0,
    })

    this.accountService.getCurrencyRateByAccounts(
      this.transferForm.get('transfer_from').value,
      this.transferForm.get('transfer_to').value
      ).subscribe(res => {
        if (res.status === 1) {
          this.exChange = res.data
        } else {
          this.exChange = 1
        }
      })
  }

  set(ev){
    this.transferForm.patchValue({
      amountFrom: (ev/this.exChange).toFixed(2)
    })
  }

  get(ev){
    this.transferForm.patchValue({
      amount: (ev*this.exChange).toFixed(2)
    })
  }

  focusOut(ev, control){
    if(ev!==null){
      this.transferForm.get(control).setValue(Number(ev).toFixed(2))
    }
  }

  fireTransferFormValidations() {
    this.transferForm = this.formBuilder.group({
      transfer_from: ['', [Validators.required]],
      transfer_to: ['', [Validators.required]],
      amount: [null,  [Validators.required]],
      amountFrom: [null, [Validators.required]]
    });
  }

  validateInt(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const regExp = /(^[0-9]([0-9]*)([.][0-9]([0-9]*))?)+$/;
      if (regExp.test(control.value) && Number(control.value) !== 0) {
        return null;
      } else {
        return {'invalid': true};
      }
    };
  }

  makeTransferFormEmpty(): void {
    this.fireTransferFormValidations();

    this.transferFromItems = this.accounts;
    this.transferToItems = this.accounts;
  }

  updateBalance(): void {
    this.accountBalance = undefined;

    const chosenAccountNumber = this.addFundsForm.value.account;

    const chosenAccount = this.accounts.find((account: Account) => {
      return account['id'] === chosenAccountNumber;
    });

    // this.accountService.getBalance(this.clientId, chosenAccount.accountId).subscribe(res => {
    //   this.accountBalance = res;
    // });
  }

  ngOnDestroy() {
    // this.accountsSubscription.unsubscribe();
    // this.accountsSubscription.complete();

    if ('undefined' !== typeof this.activeAccountSubscription) {
      this.activeAccountSubscription.unsubscribe();
      this.activeAccountSubscription.complete();
    }
  }
}
