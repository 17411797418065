import { BrandService } from "src/app/auth/services/brand/brand.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "../../auth/services/auth/auth.service";
import { RegistrationService } from "../../auth/services/registration/registration.service";
import { ToasterService } from "angular2-toaster";
import { GeneralService } from "../../services/general/general.service";
import { Client, Country, PersonalInfoModel } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  editProfileForm: FormGroup;
  clientId: number;
  clientBasicInfo: PersonalInfoModel;
  countries: Country[];
  country: string;
  backOfficeEmailAddress = "";

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private toasterService: ToasterService,
    private generalService: GeneralService,
    private translateService: TranslateService,
    private brandService: BrandService
  ) {}

  ngOnInit() {
    this.editProfileForm = this.formBuilder.group({});

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.tradeCenter:
        this.backOfficeEmailAddress = "support@invest-center.com";
        break;
      case BrandIdEnum.tradeMarkets:
        this.backOfficeEmailAddress = "info@trade-markets.com";
        break;
      case BrandIdEnum.EU:
        this.backOfficeEmailAddress = "backoffice@fxglobe.com";
        break;
      case BrandIdEnum.International:
        this.backOfficeEmailAddress = "backoffice@fxglobe.io";
        break;
      case BrandIdEnum.southAfrica:
        this.backOfficeEmailAddress = "support@fxglobe.net";
        break;
      case BrandIdEnum.OTB:
        this.backOfficeEmailAddress = "support@timestocks.com";
        break;
      case BrandIdEnum.RevolutionFX:
        this.backOfficeEmailAddress = "support@revolutionfx.net";
        break;
      case BrandIdEnum.emerald:
        this.backOfficeEmailAddress = "support@emerald-markets.com";
        break;
      case BrandIdEnum.kado:
        this.backOfficeEmailAddress = "support@kadocapital.com";
        break;
      case BrandIdEnum.greenLine:
        this.backOfficeEmailAddress = "support@greenlinepro.com";
        break;
      case BrandIdEnum.sefx:
        this.backOfficeEmailAddress = "support@7seasfx.com";
        break;
      case BrandIdEnum.growProMarkets:
        this.backOfficeEmailAddress = "support@growpromarkets.info";
        break;
      default:
        break;
    }

    this.clientId = this.authService.getAuthClientId();

    this.registrationService.getPersonalInfo().subscribe((res) => {
      this.clientBasicInfo = res.data;
    });

    // this.generalService.getCountries().subscribe(res => {
    //   this.countries = res;

    // const countryObject = this.countries.find(
    //   country => country.code === this.clientBasicInfo.basicInfo.countryCode
    // );

    // this.country = countryObject.name;
    // });
  }

  submit(): void {
    // const data = {
    //   clientId: this.clientId,
    //   phoneNumber2: this.editProfileForm.value.additional_phone
    // };
    // let successMessage: string;
    // let editProfile: string;
    // this.translateService.get("MESSAGES.SUCCESS.EDIT_INFO").subscribe(text => {
    //   successMessage = text;
    // });
    // this.translateService
    //   .get("DASHBOARD.ASIDE_MENU.EDIT_PROFILE")
    //   .subscribe(text => {
    //     editProfile = text;
    //   });
    // this.registrationService.updateBasicInfo(data).subscribe(res => {
    //   this.toasterService.pop("success", editProfile, successMessage);
    // });
  }

  get controls() {
    return this.editProfileForm.controls;
  }
}
