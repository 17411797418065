import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { RegistrationService } from '../services/registration/registration.service';
import { AccountService } from '../../dashboard/services/account/account.service';
import { PageService } from '../../page.service';

@Component({
  selector: 'app-redirect-to-registration-step-two',
  templateUrl: './redirect-to-registration-step-two.component.html',
  styleUrls: ['./redirect-to-registration-step-two.component.scss']
})
export class RedirectToRegistrationStepTwoComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private registrationService: RegistrationService,
    private accountService: AccountService,
    private pageService: PageService,
  ) { }

  ngOnInit() {
    // this.pageService.changeLoadingStatus(true);
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['redirected'] === 'true') {
        localStorage.setItem('redirected', 'true');
      }

      const client = {
        id: id
      };

      this.authService.setAuthClient(client);

      // this.registrationService.getBasicInfo(client['id']).subscribe(basicInfo => {
      //   const brandId = basicInfo['brandId'];

      //   this.accountService.getCompanyLegalName(brandId).subscribe(result => {
      //     const mainInfo = {
      //       brandId: brandId,
      //       legalName: result['legalName'],
      //     };

      //     this.registrationService.setMainInfo(mainInfo);
      //     this.pageService.changeLoadingStatus(false);

      //     this.router.navigate(['register-step-2']);
      //   });
      // });
    });
  }

}
