import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

enum DepositResponse{
  Success=1,
  Fail,
  Pending
}
@Component({
  selector: 'app-deposit-response',
  templateUrl: './deposit-response.component.html',
  styleUrls: ['./deposit-response.component.scss']
})
export class DepositResponseComponent implements OnInit {
  info ={
    status:'',
    description: '',
    location: '',
    link: '',
    icon: ''
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const { response } = params
      this.setData(+response)
    })
  }

  setData(response){
      switch (response) {
        case DepositResponse.Fail:
          this.info = {
            status:' fail.',
            description: 'Unfortunately your Deposit failed. Please contact your agent for more details',
            location: 'dashboard',
            link: 'home',
            icon: '/assets/images/Fail.svg'
          }
          break
        case DepositResponse.Success:
          this.info = {
            status:' success.',
            description: 'Thank you for Depositing. Your deposit has been successfully processed',
            location: 'deposits',
            link: 'deposit-type',
            icon: '/assets/images/Success.svg'
          }
          break
        case DepositResponse.Pending:
          this.info = {
            status:' pending.',
            description: 'Your Deposit has been submitted. Waiting for Payment System Provider response',
            location: 'dashboard',
            link: 'home',
            icon: '/assets/images/Pending.svg'
          }
          break
    }
  }

  submit(){
    this.router.navigate([this.info.link])
  }

}
