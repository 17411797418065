import { TsPaymentsComponent } from "./dashboard/info/deposit-type/ts-payments/ts-payments.component";
import { EPayComponent } from "./dashboard/info/deposit-type/e-pay/e-pay.component";
import { SorinXeComponent } from "./dashboard/info/deposit-type/sorin-xe/sorin-xe.component";
import { DepositResponseComponent } from "./dashboard/info/deposit-response/deposit-response/deposit-response.component";
import { CardPayComponent } from "./dashboard/info/deposit-type/card-pay/card-pay.component";
import { SafeChargeComponent } from "./dashboard/info/deposit-type/safe-charge/safe-charge.component";
import { TrustlyComponent } from "./dashboard/info/deposit-type/trustly/trustly.component";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { AuthGuardService as AuthGuard } from "./auth/services/auth-guard.service";
import { GuestGuardService as GuestGuard } from "./auth/services/guest-guard.service";
import { RegistrationGuardService as RegistrationGuard } from "./auth/services/registration-guard.service";
import { RegistrationFirstStepNotPassedGuard } from "./auth/services/registration-first-step-not-passed-guard.service";
import { AuthComponent } from "./auth/auth.component";
import { LoginComponent } from "./auth/login/login.component";
import { RegistrationStep1Component } from "./auth/registration-step1/registration-step1.component";
import { RegistrationStep2Component } from "./auth/registration-step2/registration-step2.component";
import { RedirectToRegistrationStepTwoComponent } from "./auth/redirect-to-registration-step-two/redirect-to-registration-step-two.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { VerifyResetPasswordComponent } from "./auth/verify-reset-password/verify-reset-password.component";
import { ResetComponent } from "./auth/reset/reset.component";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeComponent } from "./dashboard/home/home.component";
import { ProfessionalAccountLayoutComponent } from "./dashboard/professional-account-layout/professional-account-layout.component";
import { AccountTypeComponent } from "./dashboard/info/account-type/account-type.component";
import { AccountTypesComponent } from "./dashboard/account-types/account-types.component";
import { ProfessionalAccountComponent } from "./dashboard/professional-account-layout/professional-account/professional-account.component";
import { ProfessionalAccountAppComponent } from "./dashboard/professional-account-layout/professional-account-app/professional-account-app.component";
import { OpenAdditionalAccountComponent } from "./dashboard/open-additional-account/open-additional-account.component";
import { AccountsComponent } from "./dashboard/accounts/accounts.component";
import { VerifyAccountLayoutComponent } from "./dashboard/verify-account-layout/verify-account-layout.component";
import { VerifyAccountComponent } from "./dashboard/verify-account-layout/verify-account/verify-account.component";
import { AppropriatenessTestComponent } from "./dashboard/verify-account-layout/appropriateness-test/appropriateness-test.component";
import { FinancialPortraitComponent } from "./dashboard/verify-account-layout/appropriateness-test/financial-portrait/financial-portrait.component";
import { LevelOfKnowledgeComponent } from "./dashboard/verify-account-layout/appropriateness-test/level-of-knowledge/level-of-knowledge.component";
import { LevelOfExperienceComponent } from "./dashboard/verify-account-layout/appropriateness-test/level-of-experience/level-of-experience.component";

import { DepositTypeComponent } from "./dashboard/info/deposit-type/deposit-type.component";
import { CreditCardComponent } from "./dashboard/info/deposit-type/credit-card/credit-card.component";
import { SkrillComponent } from "./dashboard/info/deposit-type/skrill/skrill.component";
import { NetellerComponent } from "./dashboard/info/deposit-type/neteller/neteller.component";
import { DepositResultComponent } from "./dashboard/info/deposit-type/deposit-result/deposit-result.component";
import { FasapayComponent } from "./dashboard/info/deposit-type/fasapay/fasapay.component";
import { ZotapayComponent } from "./dashboard/info/deposit-type/zotapay/zotapay.component";
import { DotpayComponent } from "./dashboard/info/deposit-type/dotpay/dotpay.component";
import { PayboutiqueComponent } from "./dashboard/info/deposit-type/payboutique/payboutique.component";
import { SofortComponent } from "./dashboard/info/deposit-type/sofort/sofort.component";
import { WireTransferComponent } from "./dashboard/info/deposit-type/wire-transfer/wire-transfer.component";
import { IcryptFxcmComponent } from "./dashboard/info/deposit-type/icrypt-fxcm/icrypt-fxcm.component";
import { BitcoinComponent } from "./dashboard/info/deposit-type/bitcoin/bitcoin.component";
import { MessageComponent } from "./dashboard/info/deposit-type/credit-card/message/message.component";
import { DeclineMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/decline-message/decline-message.component";
import { SuccessMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/success-message/success-message.component";
import { ReviewMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/review-message/review-message.component";
import { ThankYouMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/thank-you-message/thank-you-message.component";
import { InfoComponent } from "./dashboard/info/info.component";
import { EditProfileComponent } from "./dashboard/edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./dashboard/change-password/change-password.component";
import { NationalIdComponent } from "./dashboard/national-id/national-id.component";
import { TaxIdComponent } from "./dashboard/verify-account-layout/tax-id/tax-id.component";
import { DownloadComponent } from "./dashboard/download/download.component";
import { ManageFundsComponent } from "./dashboard/manage-funds/manage-funds.component";
import { WithdrawalComponent } from "./dashboard/withdrawal/withdrawal.component";
import { WithdrawalStep2Component } from "./dashboard/withdrawal-step2/withdrawal-step2.component";
import { SubscriptionComponent } from "./dashboard/subscription/subscription.component";
import { WireStep2Component } from "./dashboard/info/deposit-type/wire-step2/wire-step2.component";
import { AppTestPassedGuardService } from "./auth/services/app-test-passed-guard.service";
import { ResetPasswordPassedGuardService } from "./auth/services/reset-password-passed-guard.service";
import { VerifyResetPasswordGuardService } from "./auth/services/verify-reset-password-guard.service";
import { WonderlandComponent } from "./dashboard/info/deposit-type/wonderland/wonderland.component";
import { EftComponent } from "./dashboard/info/deposit-type/eft/eft.component";
import { InterkassaComponent } from "./dashboard/info/deposit-type/interkassa/interkassa.component";
import { WithdrawalStep3Component } from "./dashboard/withdrawal-step3/withdrawal-step3.component";
import { SupremCashComponent } from "./dashboard/info/deposit-type/suprem-cash/suprem-cash.component";
import { ReportsComponent } from "./dashboard/reports/reports.component";
import { ZotapayVietnamComponent } from "./dashboard/info/deposit-type/zotapay-vietnam/zotapay-vietnam.component";
import { ZotapayThailandComponent } from "./dashboard/info/deposit-type/zotapay-thailand/zotapay-thailand.component";
import { BecomePartnerComponent } from "./dashboard/become-partner/become-partner.component";
import { LegalDocumentationComponent } from "./dashboard/legal-documentation/legal-documentation.component";
import { EmailVerificationComponent } from "./auth/email-verification/email-verification.component";
import { WithdrawalResponseComponent } from "./dashboard/withdrawal-step2/components/withdrawal-response/withdrawal-response.component";
import { BankWireComponent } from "./dashboard/info/deposit-type/bank-wire/bank-wire/bank-wire.component";
import { PayNowComponent } from "./dashboard/info/deposit-type/pay-now/pay-now.component";
import { ChargeMoneyComponent } from "./dashboard/info/deposit-type/charge-money/charge-money.component";
import { CreatedMt5AccountComponent } from "./dashboard/open-additional-account/created-mt5-account/created-mt5-account.component";
import { PayWayComponent } from "./dashboard/info/deposit-type/pay-way/pay-way.component";

// import { OpenDemoAccountComponent } from './dashboard/open-demo-account/open-demo-account.component';

const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    canActivate: [GuestGuard],
    children: [
      { path: "", component: LoginComponent },
      {
        path: "register-step-1",
        component: RegistrationStep1Component,
        //canActivate: [RegistrationFirstStepNotPassedGuard]
      },
      {
        path: "sign-up",
        component: RegistrationStep1Component,
        //canActivate: [RegistrationFirstStepNotPassedGuard]
      },
      {
        path: "email-verification/:id",
        component: EmailVerificationComponent,
      },
      {
        path: "register-step-2/:id",
        component: RegistrationStep2Component,
        // canActivate: [RegistrationGuard]
      },
      {
        path: "redirect-to-registration-step-two/:id",
        component: RedirectToRegistrationStepTwoComponent,
      },
      { path: "reset-password", component: ResetPasswordComponent },
      {
        path: "verify-reset-password/:id",
        component: VerifyResetPasswordComponent,
        canActivate: [ResetPasswordPassedGuardService],
      },
      {
        path: "reset",
        component: ResetComponent,
        canActivate: [VerifyResetPasswordGuardService],
      },
    ],
  },
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "home", component: HomeComponent },
      {
        path: "open-additional-account",
        component: OpenAdditionalAccountComponent,
      },
      {
        path: "created-mt5-account/:accountId",
        component: CreatedMt5AccountComponent,
      },
      // {path: 'open-demo-account', component: OpenDemoAccountComponent},
      { path: "accounts", component: AccountsComponent },
      {
        path: "verify-account",
        component: VerifyAccountLayoutComponent,
        children: [
          { path: "", component: VerifyAccountComponent },
          {
            path: "appropriateness-test",
            component: AppropriatenessTestComponent,
            canActivate: [AppTestPassedGuardService],
            children: [
              { path: "", component: FinancialPortraitComponent },
              {
                path: "level-of-knowledge",
                component: LevelOfKnowledgeComponent,
              },
              {
                path: "level-of-experience",
                component: LevelOfExperienceComponent,
              },
            ],
          },
        ],
      },
      {
        path: "deposit-response/:response",
        component: DepositResponseComponent,
      },
      {
        path: "",
        component: InfoComponent,
        children: [
          { path: "account-type", component: AccountTypeComponent },
          {
            path: "deposit-type",
            component: DepositTypeComponent,
            children: [
              { path: "ts", component: TsPaymentsComponent },
              { path: "1", component: PayboutiqueComponent },
              { path: "2", component: TrustlyComponent },
              { path: "3", component: ZotapayComponent },
              { path: "12", component: SupremCashComponent },
              { path: "14", component: BankWireComponent },
              { path: "5", component: NetellerComponent },
              { path: "6", component: SkrillComponent },
              { path: "7", component: SafeChargeComponent },
              { path: "9", component: CreditCardComponent },
              { path: "8", component: InterkassaComponent },
              { path: "10", component: CardPayComponent },
              { path: "results", redirectTo: "results/" },
              { path: "results/:status", component: DepositResultComponent },
              { path: "11", component: BitcoinComponent },
              { path: "13", component: SorinXeComponent },
              { path: "15", component: EPayComponent },
              { path: "16", component: PayNowComponent },
              { path: "17", component: ChargeMoneyComponent },
              { path: "18", component: PayWayComponent },
            ],
          },
        ],
      },
      {
        path: "message",
        component: MessageComponent,
        children: [
          { path: "decline", component: DeclineMessageComponent },
          { path: "success", component: SuccessMessageComponent },
          { path: "review", component: ReviewMessageComponent },
          { path: "thank-you", component: ThankYouMessageComponent },
        ],
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "edit",
          },
          { path: "edit", component: EditProfileComponent },
          { path: "change-password", component: ChangePasswordComponent },
          { path: "national-id", component: NationalIdComponent },
          { path: "subscription", component: SubscriptionComponent },
          {
            path: "",
            component: ProfessionalAccountLayoutComponent,
            children: [
              {
                path: "professional-account-app",
                component: ProfessionalAccountAppComponent,
              },
              {
                path: "professional-account",
                component: ProfessionalAccountComponent,
              },
            ],
          },
        ],
      },
      { path: "download", component: DownloadComponent },
      { path: "manage-funds", component: ManageFundsComponent },
      { path: "withdrawal", component: WithdrawalComponent },
      { path: "withdrawal-step2/:id", component: WithdrawalStep2Component },
      { path: "withdrawal-success", component: WithdrawalResponseComponent },
      { path: "withdrawal-step3", component: WithdrawalStep3Component },
      { path: "wire-step2", component: WireStep2Component },
      { path: "reports", component: ReportsComponent },
      { path: "account-types", component: AccountTypesComponent },
      { path: "become-a-partner", component: BecomePartnerComponent },
      { path: "legal-documentation", component: LegalDocumentationComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const ROUTED_COMPONENTS = [
  LoginComponent,
  RegistrationStep1Component,
  RegistrationStep2Component,
];
