import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class NewHttpClientService {
  private httpBackendService: HttpClient
  baseApiUrl: string = environment.baseApiUrl

  private httpOptions = {}

  constructor(
    private http: HttpClient,
    httpBackend: HttpBackend
  ) {
    this.httpBackendService =  new HttpClient(httpBackend)
  }

  getHttpOptions(options): object {
    if (options) {
      // Add custom options to http options
      const httpOptions = Object.assign(options, this.httpOptions)
      return httpOptions
    }
    return this.httpOptions
  }

  get<T>(url: string, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.get<T>(apiUrl, httpOptions)
  }

  getBrand<T>(payload, options?): Observable<any> {
    const apiUrl = `${this.baseApiUrl}/public/brand/registrationUrl`
    const httpOptions = this.getHttpOptions(options)
    return this.httpBackendService.post<T>(apiUrl,payload, httpOptions)
  }

  post<T>(url: string, body: any, options?): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    const httpOptions = this.getHttpOptions(options)
    return this.http.post<T>(apiUrl, body, httpOptions)
  }

  put<T>(url: string, body: any): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.put<T>(apiUrl, body)
  }

  patch<T>(url: string, body: any): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.patch<T>(apiUrl, body)
  }

  delete<T>(url: string): Observable<T> {
    const apiUrl = `${this.baseApiUrl}/${url}`
    return this.http.delete<T>(apiUrl)
  }
}
