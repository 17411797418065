import { Injectable } from '@angular/core';
import { RegistrationService } from './registration/registration.service';
import { AuthService } from './auth/auth.service';
import { CanActivate, Router } from '@angular/router';
import { AppTestService } from './app-test/app-test.service';

@Injectable()
export class AppTestPassedGuardService implements CanActivate {

  client: object;

  constructor(
    private registrationService: RegistrationService,
    private authService: AuthService,
    private router: Router,
    private appTestService: AppTestService,
  ) {
    this.client = this.authService.getAuthClient();
  }

  canActivate(): boolean {
    this.appTestService.testStatusObservable.subscribe(testStatus => {
      if (testStatus === 'APPROVED') {
        this.router.navigate(['/verify-account']);
        return false;
      } else {
        return true;
      }
    });

    return true;
  }
}
