import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private location = new BehaviorSubject([]);
  locationObservable = this.location.asObservable();

  constructor() { }

  setLocation(location): void {
    this.location.next(location);
  }

  getLocation(): object {
    return this.location.value;
  }

}
