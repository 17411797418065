import { Injectable } from '@angular/core';
// import { HttpClientService } from 'src/app/services/http-client.service';
import { Observable } from 'rxjs';
import {
  WithdrawalDepositReportResponse,
  WithdrawalDepositReportRequest,
  ReportsTimeInterval,
} from 'src/app/models/reports.model';
import { TimeIntervalEnum } from 'src/app/enums/reports.enum';
import { TranslateService } from '@ngx-translate/core';
import { NewHttpClientService } from 'src/app/services/new-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public newPrefix = 'report';

  public timeInterval: ReportsTimeInterval[] = [
    {
      id: TimeIntervalEnum.today,
      name: 'REPORTS.TIME_INTERVAL_LIST.TODAY',
    },
    {
      id: TimeIntervalEnum.lastThreeDays,
      name: 'REPORTS.TIME_INTERVAL_LIST.LAST_THREE_DAYS',
    },
    {
      id: TimeIntervalEnum.lastWeek,
      name: 'REPORTS.TIME_INTERVAL_LIST.LAST_WEEK',
    },
    {
      id: TimeIntervalEnum.lastMonth,
      name: 'REPORTS.TIME_INTERVAL_LIST.LAST_MONTH',
    },
    {
      id: TimeIntervalEnum.lastThreeMonth,
      name: 'REPORTS.TIME_INTERVAL_LIST.LAST_THREE_MONTH',
    },
    {
      id: TimeIntervalEnum.lastSixMonth,
      name: 'REPORTS.TIME_INTERVAL_LIST.LAST_SIX_MONTH'

    },
    {
      id: TimeIntervalEnum.allTime,
      name: 'REPORTS.TIME_INTERVAL_LIST.ALL_TIME',
    },
  ];

  constructor(
    private newHttp: NewHttpClientService,
    private translate: TranslateService) { }

    getWithdrawalDepositReport(options): Observable<any> {
      const url = `${this.newPrefix}/withdrawaldeposit`
      return this.newHttp.patch<any>(url, options)
    }
}
