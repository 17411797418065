export enum TimeIntervalEnum {
    allTime = 0,
    today,
    lastThreeDays,
    lastWeek,
    lastMonth,
    lastThreeMonth,
    lastSixMonth,
}

export enum SelectAccountEnum {
    allAccounts = 0
}
