import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegistrationService } from "../services/registration/registration.service";
import { TokenService } from "../services/token/token.service";
import { AuthService } from "../services/auth/auth.service";
import { LocationService } from "../services/location/location.service";
import { HttpClient } from "@angular/common/http";
import { AccountService } from "../../dashboard/services/account/account.service";
import { BrandService } from "../services/brand/brand.service";
import { GeneralService } from "../../services/general/general.service";
import { PageService } from "../../page.service";
import { NotificationService } from "../../services/notification/notification.service";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { BasicInfo } from "../../interfaces";
import { ToasterService } from "angular2-toaster";
import { Subscription } from "rxjs/internal/Subscription";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-registration-step2",
  templateUrl: "./registration-step2.component.html",
  styleUrls: ["./registration-step2.component.scss"],
})
export class RegistrationStep2Component implements OnInit, OnDestroy {
  countries: any;
  genders: Array<string> = ["Male", "Female"];
  registerForm: FormGroup;
  validAddress = true;
  validCity = true;
  validPostal = true;
  validGender = true;
  validDay = true;
  validMonth = true;
  validYear = true;
  validAdult = true;
  validNationalityCode = true;
  clientId: number;
  location: object;
  city: string;
  nationalities;
  isRedirected = false;
  years: object[];
  private locationSubscription;
  isFXGlobeEU: boolean;
  chosenBrandId: number;
  riskDisclaimerURL =
    "https://www.fxglobe.com/wp-content/uploads/2018/07/Risk_Disclosure.pdf";
  termsOfConditionsURL = "https://www.fxglobe.com/en/terms-of-use/";
  legalDocumentationURL = "https://www.fxglobe.com/en/legal-documentation/";
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  hostname: string;
  private subscription: Subscription;
  localBrandId = `${localStorage.getItem("localBrandId")}`;
  showText = false;
  isEU = false;
  isInternational = false;
  southAfrica = false;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private locationService: LocationService,
    private httpClient: HttpClient,
    private accountService: AccountService,
    private brandService: BrandService,
    private generalService: GeneralService,
    private pageService: PageService,
    private notificationService: NotificationService,
    private toasterService: ToasterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscription = this.route.params.subscribe((param) => {
      this.clientId = param["id"];
    });

    switch (+this.localBrandId) {
      case BrandIdEnum.EU:
        this.showText = true;
        this.isEU = true;
        break;
      case BrandIdEnum.International:
        this.showText = true;
        this.isInternational = true;
        break;
      case BrandIdEnum.southAfrica:
        this.showText = true;
        this.southAfrica = true;
        break;
    }

    this.registerForm = this.formBuilder.group({
      address: ["", [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
      address2: "",
      city: ["", [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
      postal: ["", [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
      gender: ["", [Validators.required]],
      day: ["", [Validators.required]],
      month: ["", [Validators.required]],
      year: ["", [Validators.required]],
      nationalityCode: ["", [Validators.required]],
    });

    this.hostname = window.location.hostname;

    // On Dropping page
    window.onbeforeunload = () => {
      const data = {
        clientId: this.clientId,
        step: 2,
      };

      // this.notificationService.clientFailedInStep(data);
    };

    this.authService.hideAllBrands();

    if (localStorage.getItem("redirected")) {
      this.isRedirected = true;
      localStorage.removeItem("redirected");
    }

    // this.pageService.changeLoadingStatus(true);
    // this.registrationService.getBasicInfo(this.clientId).subscribe((basicInfo: BasicInfo) => {
    //   if (basicInfo) {
    //     if (basicInfo.emailVerification.emailVerificationEnable && !basicInfo.emailVerification.emailIsVerified) {
    //       this.toasterService.pop('error', 'check your email');
    //       this.router.navigate(['email-verification', basicInfo.clientId]);
    //     }
    //    // this.pageService.changeLoadingStatus(false);
    //     const countryCode = basicInfo.countryCode;
    //     this.registerForm.get('nationalityCode').setValue(countryCode);

    //     this.chosenBrandId = basicInfo.brandId;

    //     // Show privacyPolicy and riskDisclosure only for EU
    //     if (this.chosenBrandId === 1) {
    //       this.isFXGlobeEU = true;
    //     }
    //   } else {
    //    // this.pageService.changeLoadingStatus(false);
    //   }
    // }, error => {
    //  // this.pageService.changeLoadingStatus(false);
    // });

    // this.locationSubscription = this.locationService.locationObservable.subscribe(location => {
    //   this.location = location;
    //   if (this.hostname !== 'portal.otb-trading.com') {
    //     if (this.location['region_name']) {
    //       this.city = this.location['region_name'];
    //     }
    //   }
    // });

    // this.generalService.getCountries().subscribe(res => {
    //   this.countries = res;
    // });

    this.getNationalitiesFromJson().subscribe((nationalities) => {
      this.nationalities = nationalities;
    });

    this.years = this.getYears();
  }

  get controls() {
    return this.registerForm.controls;
  }

  submit() {
    if (this.registerForm.valid) {
      const data = {
        address: this.registerForm.value.address,
        secondaryAddress: this.registerForm.value.address2,
        city: this.registerForm.value.city,
        postalCode: this.registerForm.value.postal,
        gender: this.registerForm.value.gender,
        birthdate: this.getDateOfBirth(),
        nationality: this.registerForm.value.nationalityCode,
      };

      this.registrationService
        .registrationAdress(this.clientId, data)
        .subscribe((result) => {
          if (result.status === 1) {
            this.toasterService.pop("success", result.message);
            this.router.navigate(["/"]);
            this.authService.setAuthClient(false);
          }
        });
    }
  }

  // submit(): void {
  //   if (this.registerForm.valid) {
  //     const dateOfBorn = this.getDateOfBirth();

  //     // Continue if 18+
  //     if (this._calculateAge(dateOfBorn) >= 18) {
  //       this.validAdult = true;
  //       this.registrationService.getClientBasicInfo(this.clientId).subscribe(existingClient => {
  //         if (existingClient.addressInfo.address.length > 0) {
  //           this.router.navigateByUrl('/home');
  //         } else {
  //           // this.pageService.changeLoadingStatus(true);
  //           this.saveAndContinue();
  //         }
  //       });
  //     } else {
  //       this.validAdult = false;
  //     }
  //   } else {
  //     this.validAddress = this.controls['address'].valid;
  //     this.validCity = this.controls['city'].valid;
  //     this.validPostal = this.controls['postal'].valid;
  //     this.validGender = this.controls['gender'].valid;
  //     this.validDay = this.controls['day'].valid;
  //     this.validMonth = this.controls['month'].valid;
  //     this.validYear = this.controls['year'].valid;
  //     this.validNationalityCode = this.controls['nationalityCode'].valid;
  //   }
  // }

  // saveAndContinue(): void {
  //   const dateOfBorn = this.getDateOfBirth();

  //   const data = {
  //     id: 0,
  //     clientId: this.clientId,
  //     address: this.registerForm.value.address,
  //     address2: this.registerForm.value.address2,
  //     zipCode: this.registerForm.value.postal,
  //     city: this.registerForm.value.city,
  //     dateOfBorn: dateOfBorn,
  //     gender: this.registerForm.value.gender,
  //     nationalityCode: this.registerForm.value.nationalityCode,
  //     accountTypeId: 0,
  //     done: true
  //   };

  //   this.registrationService.updateAddressInfo(data).subscribe(() => {
  //     this.tokenService.setAuthToken('abc123456789');

  //     this.registrationService.getBasicInfo(this.clientId).subscribe((basicInfo: BasicInfo) => {
  //       const brandId = basicInfo.brandId;

  //       this.accountService.getCompanyLegalName(brandId).subscribe(result => {
  //         const mainInfo = {
  //           brandId: brandId,
  //           legalName: result['legalName'],
  //         };

  //         this.registrationService.setMainInfo(mainInfo);

  //         const currentStep = 1;
  //         localStorage.setItem('registrationStep', currentStep.toString());

  //         this.registrationService.redirectToNextRegistrationStep();
  //       });
  //     });
  //   });
  // }

  changeGenderSelect(): void {
    this.validGender = true;
  }

  changeDaySelect(): void {
    this.validDay = true;
  }

  changeMonthSelect(): void {
    this.validMonth = true;
  }

  changeYearSelect(): void {
    this.validYear = true;
  }

  changeNationalityCodeSelect(): void {
    this.validNationalityCode = true;
  }

  getNationalitiesFromJson() {
    return this.httpClient.get("./assets/mockups/nationalities.json");
  }

  // TODO: move this function to helpers
  getYears() {
    let currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    const years = [];

    while (startYear <= currentYear) {
      if (new Date().getFullYear() - currentYear >= 18) {
        years.push(currentYear);
      }
      currentYear--;
    }

    return years;
  }

  _calculateAge(birthday) {
    // birthday is a date
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getDateOfBirth() {
    const day = this.registerForm.value.day;
    const month = this.registerForm.value.month;
    const year = this.registerForm.value.year;

    const dateOfBorn = year + "-" + month + "-" + day;

    return dateOfBorn;
  }

  ngOnDestroy() {
    // this.locationSubscription.unsubscribe();
    // this.locationSubscription.complete();
  }
}
