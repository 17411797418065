import { Component, OnInit } from '@angular/core';
import {
  faCheckCircle,
  faTimes,
  faFileAlt,
  faDownload,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';
import { UploadEvent, UploadFile } from 'ngx-file-drop';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { RegistrationService } from '../../../auth/services/registration/registration.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { HttpClient, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ViewDocumentComponent } from './modals/view-document/view-document.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageService } from '../../../page.service';
// import { HttpClientService } from '../../../services/http-client.service';
import { Document } from '../../../interfaces';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { CommentPopupComponent } from './modals/comment-popup/comment-popup.component';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {
  documentId
  faCheckCircle = faCheckCircle;
  faCloudUploadAlt = faCloudUploadAlt;
  faDownload = faDownload
  faTimes = faTimes;
  faFileAlt = faFileAlt;
  uploadForm: FormGroup;
  files: UploadFile[] = [];
  filesArray = [];
  commentContentIsClicked = false;
  uploadContentIsClicked = false;
  isHiddenBackground = false;
  filesToUpload;
  documents: Document[];
  openDocument: Document;
  showDocument = false;
  clientId: number;
  currentDocument: Document;
  currentDocName: string;
  apiUrl = environment.apiURL;
  progressBarValue = 0;
  showProgressBar = false;
  stripedProgressBar = true;
  acceptedExtensions = ['.jpg', '.png', '.bnp', '.pdf'];
  documentType: string;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private pageService: PageService,
    // private httpClientService: HttpClientService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      upload_file: ['', [Validators.required]],
    });

    this.clientId = this.authService.getAuthClientId();

    this.getDocuments();
  }

  getDocuments() {
    const vm = this;
    vm.registrationService.getClientReqDocs(this.clientId).subscribe((documents) => {
      vm.documents = documents.data;
      vm.progressBarValue  = 100;
      setTimeout(() => {
        vm.stripedProgressBar = false;
      }, 500);
      setTimeout(() => {
        vm.showProgressBar = false;
      }, 2000);
    });
  }

  getDocument(documentId: number) {
    this.pageService.changeLoadingStatus(true);
    this.registrationService.getDocumentById(this.clientId,documentId).subscribe((res) => {

      if(res.data){
        this.newOpenModal(res.data)
      }
    });
  }

  openComments(document: Document) {
    this.openDocument = document;
    this.commentContentIsClicked = true;
    this.isHiddenBackground = true;
  }

  closeComments() {
    this.commentContentIsClicked = false;
    this.isHiddenBackground = false;
  }

  openUpload(document: Document) {
    this.documentId = document.id
    this.showProgressBar = false;
    this.stripedProgressBar = true;
    this.progressBarValue  = 0;
    this.uploadContentIsClicked = true;
    this.isHiddenBackground = true;

    this.currentDocument = document;
    this.currentDocName = document.name;
  }

  closeUpload() {
    this.uploadContentIsClicked = false;
    this.isHiddenBackground = false;
    this.filesArray = [];
  }

  closeOpenedMessage() {
    this.closeComments();
    this.closeUpload();
  }

  public dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        // this.showProgressBar = true;
        // this.progressBarValue  = 0;
        // this.stripedProgressBar = true;
        const fileEntry = droppedFile.fileEntry;
        fileEntry['file']((file: File) => {
          this.onFileChange(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry;
      }
    }
  }

 downloadTamplate(tamplateId){
  this.registrationService.getBrandRegistrationDocumentById(tamplateId).subscribe((res) => {
    if(res.data){
      this.download(res.data);
    }
  });
 }

 download(data){
  if (data.documentExtension === '.pdf') {
    this.fileDownload(data.documentContent, 'pdf', 'application', data.documentName)
  } else {
    const extension = data.documentExtension.split('.').pop()
    this.fileDownload(data.documentContent, extension, 'image', data.documentName)
  }
}


  openPopUp(comment){
    if(comment){
      const modalRef = this.modalService.open(CommentPopupComponent, { size: 'sm', centered:true });
      modalRef.componentInstance.comment = comment;
    }
  }

 fileDownload(b64Data, format, type, name) {
  const uploadedFile = b64Data
  const fileFormat = format
  const linkSource = `data:${type}/${fileFormat};base64,${uploadedFile}`
  const downloadLink = document.createElement('a')
  const fileName = name
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

  fileToBase64(file) {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = () => {
          const result = reader.result
          resolve(result)
        }
        reader.onerror = e => {
          reject(e)
        }
      })
    }
  }

  onFileChange(file) {

    const fileToUpload = file
    const fileName = file.name
    this.fileToBase64(fileToUpload).then(base64 => {
      const payload = {
        documentName:fileName,
        documentContent: btoa(`${base64}`),
      }
      console.log(payload)
      this.uploadFileDocument(payload)
    })

  }

  uploadFileDocument(payload){
    this.registrationService.addDocumentById(this.clientId,this.documentId,payload)
    .subscribe(res=>{
      this.getDocuments();
      this.closeUpload();

    })
  }

  saveFile(file) {
    const fileExtension = file.name.split('.').pop().toLowerCase();

    // If file extension is accepted
    if (this.acceptedExtensions.includes('.' + fileExtension)) {
      this.filesArray.push(file);

      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      const params = new HttpParams();

      const options = {
        params: params,
        reportProgress: true,
        headers: new HttpHeaders({
          'Authorization': ''
        })
      };

      const req = new HttpRequest('POST', this.apiUrl + 'Registration/UploadFile?clientId=' +
        this.clientId + '&documentType=' + this.currentDocument.documentTypeId, formData, options);
      this.progressBarValue  = 20;

      this.http.request(req).subscribe(res => {
        if (res.type === 4) {
          this.progressBarValue  = 60;
          this.getDocuments();
        }
      });
    } else {
      this.showProgressBar = false;
      let errorTitle = '';
      let errorBody = '';

      this.translateService.get('MESSAGES.DECLINE.ERROR').subscribe(text => {
        errorTitle = text;
      });

      this.translateService.get('MESSAGES.DECLINE.UNACCEPTED_FILE_EXTENSION').subscribe(text => {
        errorBody = text + ' ' + this.acceptedExtensions;
      });

      this.toasterService.pop('error', errorTitle, errorBody);
    }
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  openDocumentPage(document: Document) {
    this.showDocument = true;

    this.currentDocument = document;
  }

  closeDocument() {
    this.showDocument = false;
    this.closeUpload();
  }

  displayImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.openModal(reader.result);
      this.pageService.changeLoadingStatus(false);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  openModal(image: string | ArrayBuffer) {
    const modalRef = this.modalService.open(ViewDocumentComponent, { size: 'lg' });
    modalRef.componentInstance.image = image;
    modalRef.componentInstance.documentType = this.documentType;
  }

  b64toBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return  URL.createObjectURL(blob);
  }

  newOpenModal(image:any[]) {
    // console.log(image)
    const modalRef = this.modalService.open(ViewDocumentComponent, { size: 'lg' });
    modalRef.componentInstance.image = image;
    // modalRef.componentInstance.documentType = this.documentType;
  }

  decodeComment(comment) {
    if(comment){
      return atob(comment)
    }
  }

}
