import { Injectable } from '@angular/core';
// import { HttpClientService } from '../http-client.service';
import { Observable } from 'rxjs';
import { Country } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  prefix = 'General';

  constructor(
    // private http: HttpClientService,
  ) { }

  // public getCountries(): Observable<Country[]> {
  //   return this.http.get<Country[]>(this.prefix + '/GetCountries');
  // }
}
