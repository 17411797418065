import { Injectable } from '@angular/core';
import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public minMaxNumbers(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && (Number(control.value) < min || Number(control.value) > max)) {
        return { 'notValid': true };
      }
      return null;
    };
  }

  email(): object {
    return [Validators.required, Validators.email, Validators.pattern(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)];
  }

  password() {
	return [
		Validators.required, Validators.minLength(5), Validators.maxLength(15), 
		Validators.pattern('^(?=.*?[A-Z]).{5,}$'), Validators.pattern('.*[0-9].*')
	];
  }

  public checkLength(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && (String(control.value).length < min || String(control.value).length > max)) {
        return { 'notValid': true };
      }
      return null;
    };
  }
}
