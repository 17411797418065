import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-deposit-result',
  templateUrl: './deposit-result.component.html',
  styleUrls: ['./deposit-result.component.scss']
})
export class DepositResultComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  title: string;
  message: string;
  ngOnInit() {
    if (this.route.snapshot.params.status === 'success') {
      this.title = 'Thanks for your deposit!';
      this.message = 'Your payment has been successful.';
    } else {
      this.title = 'Oops, something went wrong.';
      this.message = 'Unfortunately your payment has failed. Please try again.';
    }
  }
}
