import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '../../../page.service';
import { AppropriatenessTestService } from './sevices/appropriateness-test.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPopupComponent } from '../../become-partner/success-popup/success-popup.component';
import { ResultMessageComponent } from './modals/result-message/result-message.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appropriateness-test',
  templateUrl: './appropriateness-test.component.html',
  styleUrls: ['./appropriateness-test.component.scss']
})
export class AppropriatenessTestComponent implements OnInit, OnDestroy {

  activePage: 'Financial Portrait'| 'Level of Knowledge' | 'Level of Experience';
  clientId: number;
  validForm = true;

  financialPortraitData = {};
  levelOfKnowledgeData = {};
  levelOfExperienceData = {};

  radioAndFreeText = {};
  check = false;
  countryShortList;
  financialPortraitForm: FormGroup;

  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  constructor(
    private pageService: PageService,
    public router: Router,
    private modalService: NgbModal,
    private appropriatenessTestService: AppropriatenessTestService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private fb: FormBuilder,

  ) {
    this.financialPortraitForm = this.fb.group({});
  }

  ngOnInit() {
    const that = this;

    // this.appropriatenessTestService.getTestsFromJson().subscribe(tests => {
    //   this.appropriatenessTestService.setTests(tests);
    // });

    // this.appropriatenessTestService.getAppropriatnesstTest(1).subscribe(tests => {

    //   console.log(tests);
    //   this.appropriatenessTestService.setTests(tests);
    //   return tests
    // })


    /// REBUILD


    // this.pageService.activePage.subscribe(pageName => setTimeout(function() {
    //   that.activePage = pageName;
    // }, 0));

    this.activePage = 'Financial Portrait';

    this.clientId = this.authService.getAuthClientId();

    // On Dropping page
    window.onbeforeunload = () => {
      const data = {
        clientId: this.clientId,
        step: 2,
      };

      // this.notificationService.appropriatenessTestDropped(data);
    };

      this.appropriatenessTestService.appTestDataObsevable
      .subscribe((result) => {
        if (result.length) {
          this.financialPortraitData = result.find(
            category => category.id === 1
          );
          this.levelOfKnowledgeData = result.find(
            category => category.id === 2
          );
          this.levelOfExperienceData = result.find(
            category => category.id === 3
          );
          this.initForm(this.financialPortraitData);
          this.initForm(this.levelOfKnowledgeData);
          this.initForm(this.levelOfExperienceData);

        }

      });






    this.appropriatenessTestService.getCountryShortList()
    .subscribe((result) => {
      if (result.status === 1) {
        this.countryShortList = result.data;
      }

    });
  }

  initForm(data) {
    data.questions.forEach(question => {
      this.financialPortraitForm.setControl(question.id, new FormControl (null, [Validators.required]));
    });

  }

  changeActivePage(pageName) {
    this.activePage = pageName;
  }


  onCheck(status) {
    this.check = status;
  }

  textChange(text, question, answer) {
    // console.log(text, question, answer)
    this.financialPortraitForm.get(`${question}`).setValue({
      answer,
      text
    });
  }

  submit() {
    const payload = [];
    for (const [key, value] of Object.entries(this.financialPortraitForm.value)) {
      if (! (value instanceof Object)) {
        const cell = {
          questionId : +key,
          answerId : value,
          customText : null
        };
        payload.push(cell);
      } else {
        const cell = {
          questionId : +key,
          answerId : value['answer'],
          customText : value['text']
        };
        payload.push(cell);
      }
    }
    this.appropriatenessTestService.setFinancialPortraitFormObservable(this.financialPortraitForm);
    this.validForm = this.financialPortraitForm.valid;
    if (this.financialPortraitForm.valid) {
        this.appropriatenessTestService.setAppTest(this.clientId, payload)
        .subscribe(res => {
          if (res.status === 1) {
            if (res.data) {
              this.openModal(res.data);
            }
            // else{
            //   this.router.navigate(['/verify-account']);
            // }
          }
        });
     }

  }


  openModal(data) {
    const modalRef = this.modalService.open(ResultMessageComponent, { size: 'lg' });
    modalRef.componentInstance.data = data;
  }

  ngOnDestroy() {
    this.appropriatenessTestService.clearTests();
    this.appropriatenessTestService.clearTestAnswers();
  }
}
