import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalAccountService {

  private chosenApplications = new BehaviorSubject([]);
  chosenApplicationsObservable = this.chosenApplications.asObservable();

  constructor() { }

  setChosenApplications(chosenApplications): void {
    this.chosenApplications.next(chosenApplications);
  }

  getChosenApplications(): object {
    return this.chosenApplications.value;
  }

}
