import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth/auth.service';
import { TokenService } from '../services/token/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../../dashboard/services/account/account.service';
import { RegistrationService } from '../services/registration/registration.service';
import { ValidationService } from '../../services/validation/validation.service';
import { BrandService } from '../services/brand/brand.service';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from '../../services/form.service';
import { Login, CompanyLegalName } from '../../interfaces';
import * as CryptoJS from 'crypto-js';
import { BrandIdEnum } from 'src/app/brand.enum';
import { PageService } from 'src/app/page.service';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  form: FormGroup;
  loginError = false;
  // currentWebsitesBrandId: number;
  emailverifyError: boolean = false;
  clientId: number;
  errorMessage = '';
  public tradeCenter: boolean;
  public tradeMarkets: boolean;
  public isRevolution: boolean;
  public isPhenoFx: boolean;
  public micMarket: boolean;
  public sefx: boolean;

  passwordShowObject = {
    inputType: "password",
    icon: faEye,
  };
  passwordHideObject = {
    inputType: "text",
    icon: faEyeSlash,
  };
  passwordShowHideObject = this.passwordShowObject;


  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private validationService: ValidationService,
    private brandService: BrandService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private formService: FormService,
    public pageService: PageService,



  ) { }

  ngOnInit() {

    let successMessage = '';
    let sessionExpired = '';

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
        break;
      case BrandIdEnum.tradeCenter:
        this.tradeCenter = true;
        break;
      case BrandIdEnum.tradeMarkets:
        this.tradeMarkets = true;
        break;
      case BrandIdEnum.phenoFx:
        this.isPhenoFx = true;
        break;
      case BrandIdEnum.micMarket:
        this.micMarket = true;
        break;
        case BrandIdEnum.sefx:
          this.sefx = true;
          break;
      default:
        break;
    }


    this.translateService.get('MESSAGES.DECLINE.LOGIN_AGAIN').subscribe(text => {
      successMessage = text;
    });

    this.translateService.get('MESSAGES.DECLINE.SESSION_EXPIRED').subscribe(text => {
      sessionExpired = text;
    });

    this.form = this.formBuilder.group({
      email: ['', this.validationService.email()],
      password: ['', this.validationService.checkLength(6,24)],
    });

    // this.currentWebsitesBrandId = this.brandService.getCurrentWebsitesBrandId();

    if (localStorage.getItem('session_expired')) {
      this.toasterService.pop('error', sessionExpired, successMessage);
      localStorage.removeItem('session_expired');
    }

    this.route.queryParams.subscribe(params => {
      if (params['autoLoginToken']) {
        this.registrationService.autoLogIn(params).subscribe(res=>{
          if(res.status===1){
            this.userStatus(res.data)
            this.router.navigateByUrl('/deposit-type');
          }
        })
      }
    });

    // if (+localStorage.getItem('localBrandId') == BrandIdEnum.RevolutionFX) {

    //   this.isRevolution = true;
    // }



  }

  customDecrypt(encryptedText) {
    const encbuf = atob(encryptedText);
    const encarr = Array.prototype.slice.call(encbuf, 0);
    const decarr = new Array();
    encarr.forEach(e => { decarr.push(String.fromCharCode((e.charCodeAt(0) > 7) ? e.charCodeAt(0) - 7 : e.charCodeAt(0) + 248)); });
    const decryptedText = decarr.join('');
    return JSON.parse(decryptedText);
  }

  submit(): void {
    this.errorMessage='';
    if (this.form.valid) {
      const data = {
        emailAddress: this.form.value.email,
        password: this.form.value.password,
      };

      this.registrationService.logIn(data) .subscribe(result => {
        if (result.status === 1) {
          if(result.data.token){
            this.userStatus(result.data)
            if (+this.brandService.getCurrentWebsitesBrandId() == BrandIdEnum.tradeCenter || BrandIdEnum.tradeMarkets){
            this.router.navigateByUrl('/accounts');
            }else
            this.router.navigateByUrl('/verify-account');


          }else{
            this.statusCheck()
          }
        }else{
          this.loginError = true;
          this.errorMessage = result.message;
        }
      })
    } else {
      this.formService.validateAllFormFields(this.form);
    }
  }

  statusCheck(){
    this.registrationService.loginNotAllowedInfo().subscribe(res=>{
      if(res.status===1){
        this.toasterService.pop('error', res.data.title, res.data.text);
      }
      this.authService.logout()
    })
  }

  userStatus(res){
    this.clientId = res['clientId']
    this.emailverifyError = false;
    if ('undefined' !== typeof res['stepTwoNotPassed'] && res['stepTwoNotPassed'] === true) {
      const client = {id: res.clientId};
      this.authService.setAuthClient(client);
      this.router.navigateByUrl('/register-step-2');
    } else if (res.clientId && res.status === 0) {
      this.tokenService.setAuthToken(res['token']);
      const client = {id: res.clientId};
      this.authService.setAuthClient(client);

      this.pageService.brandShortlist.next(res.clientBrandId)
      localStorage.setItem('localBrandId',res.clientBrandId)
      // this.accountService.getCompanyLegalName(brandId).subscribe((result: CompanyLegalName) => {
      //   const mainInfo = {
      //     brandId: brandId,
      //     legalName: result.legalName,
      //   };

      //   this.registrationService.setMainInfo(mainInfo);

      //   this.router.navigateByUrl('/home');
      // });
    } else if (res.status === 7) {
      this.emailverifyError = true;
    } else {
      this.loginError = true;
    }

  }

  hasError(name: string, touched?: boolean): boolean {
    return FormService.hasError(name, this.form, (touched ? touched : null));
  }

  sendVerifyEmail() {
    // this.registrationService.sendVerifyEmail(this.clientId).subscribe(res => {
    //   if (res) {
    //     this.toasterService.pop('success', 'The verify email is sent');
    //   } else {
    //     this.toasterService.pop('error', 'something went wrong');
    //   }
    // }, error => {
    //   this.toasterService.pop('error', 'something went wrong');
    // });
  }

  showHidePassword() {
    if (this.passwordShowHideObject.inputType === "text") {
      this.passwordShowHideObject = this.passwordShowObject;
    } else {
      this.passwordShowHideObject = this.passwordHideObject;
    }
  }

}
