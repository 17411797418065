import { first, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { AlertItem } from '../models';
// import { HttpClientService } from './http-client.service';
import { environment } from '../../environments/environment';
import { PageService } from '../page.service';
import { TranslateService } from '@ngx-translate/core';
import { BrandService } from '../auth/services/brand/brand.service';
import { TokenService } from '../auth/services/token/token.service';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { skipWhile } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth/auth.service';

@Injectable()
export class HttpClientInterceptorService implements HttpInterceptor {

  private BASE_URL = environment.apiURL;
  // aaa: Observable<any>
  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private pageService: PageService,
    private brandService: BrandService,
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,


  ) {
    // this.aaa = this.pageService.getData()
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      return this.pageService.brandShortlistSubject.pipe( skipWhile(state => {
        if(state){
          return false
        }
        else{
          return true
        }
        }),first()).pipe(
        mergeMap(configData => {
          let authReq = req;
          this.pageService.changeLoadingStatus(true);

            // Clone the request to add the new header.
            authReq = req.clone({
              headers: new HttpHeaders({
                'X-BrandId': `${configData}`,
                'X-Authorization': `${this.tokenService.getAuthToken()}`,
                'Accept-Language': `${(this.translateService.currentLang==='CZ')? 'CS':
                                    (this.translateService.currentLang==='CN') ? 'ZH' :
                                    this.translateService.currentLang}`,
              })
            });
          // console.log(configData);

    return next.handle(authReq)
    .pipe(

      catchError((error, caught) => {
        this.pageService.changeLoadingStatus(false)
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // redirect to the login route
            this.authService.logout();
            this.router.navigate(['/']);
          } else {
            if (this.alertService.showFullError) {
              const err = `message: ${error.message} StatusText:${error.statusText}` +
                ` Error Code: ${error.status} Url:${error.url}`;
              this.alertService.alerts.next(new AlertItem('Error', err));
            } else {
              const err = `${error.statusText}. Error Code: ${error.status}`;
              this.alertService.alerts.next(new AlertItem('Error', err));
            }
            return Observable.throw(error);
          }
        } else {
          this.alertService.alerts.next(new AlertItem('General Error', error));
          return Observable.throw(error);
        }
      }))
      .pipe(finalize(() =>  this.pageService.changeLoadingStatus(false)));
    })

    )

  }

}
