import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bank } from '../../../interfaces';
// import { HttpClientService } from '../../../services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(
    private httpClient: HttpClient,
    // private http: HttpClientService,
  ) { }

  // getBanks(brandId, currencyId): Observable<Bank[]> {
  //   return this.http.post<Bank[]>(`Accounts/GetBankDetails?brandId=${brandId}&currencyId=${currencyId}`);
  // }
}
