import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RegistrationService } from "../../../../auth/services/registration/registration.service";
import { AccountService } from "../../../services/account/account.service";
import { AbstractControl } from "@angular/forms";
import { NewHttpClientService } from "src/app/services/new-http-client.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DepositTypeService {
  private currencies = new BehaviorSubject([]);
  private wireData = new BehaviorSubject({});
  private depositInfo = new BehaviorSubject({});
  currenciesObservable = this.currencies.asObservable();
  wireDataObservable = this.currencies.asObservable();
  depositInfoObservable = this.currencies.asObservable();

  newPrefix = "deposit/method";
  constructor(
    private newHttp: NewHttpClientService,
    private registrationService: RegistrationService,
    private accountService: AccountService
  ) {
    // const mainInfo = this.registrationService.getMainInfo();
    // const brandId = mainInfo['brandId'];
    // this.accountService.getCurrencies(brandId).subscribe(currencies => {
    //   this.currencies.next(currencies);
    // });
  }

  depositMethodeShortList(): Observable<any> {
    const url = `${this.newPrefix}/shortlist`;
    return this.newHttp.get<any>(url);
  }

  depositMethodeSubtypeShortList(id): Observable<any> {
    const url = `${this.newPrefix}/${id}/subtype/shortlist`;
    return this.newHttp.get<any>(url);
  }

  // public getDepositAmount(clientAccountId): Observable<any> {
  //   const url = `deposit/ftdmindepositamount/${clientAccountId}`;
  //   return this.newHttp.get<any>(url);
  // }

  public getMinDepositAmountByCurrency(payload): Observable<any> {
    const url = `deposit/ftdMinDepositAmountByCurrency`;
    return this.newHttp.patch<any>(url, payload);
  }

  submitDeposit(payload): Observable<any> {
    const url = `transaction/createDeposit`;
    return this.newHttp.post<any>(url, payload);
  }

  getPspById(id): Observable<any> {
    const url = `psp/${id}/field`;
    return this.newHttp.get<any>(url);
  }

  getSorinXEById(id): Observable<any> {
    const url = `psp/${id}/field/SorinXE`;
    return this.newHttp.get<any>(url);
  }

  getPayNowById(id): Observable<any> {
    const url = `psp/${id}/field/PayNow`;
    return this.newHttp.get<any>(url);
  }

  getSolutionSupremCurrencyShortList(solutionId, id) {
    const url = `${this.newPrefix}/${id}/solution/${solutionId}/currency/shortlist`;

    return this.newHttp.get<any>(url);
  }

  getSolutionCurrencyShortList(solutionId) {
    const url = `${this.newPrefix}/solution/${solutionId}/currency/shortlist`;

    return this.newHttp.get<any>(url);
  }

  getSupremCashPixStatusCheck(depositId): Observable<any> {
    const url = `CallBack/SupremCashPixStatusCheck/${depositId}`;
    return this.newHttp.get<any>(url);
  }

  ////////////////////////////////////////////
  setWireData(data: object): void {
    this.wireData.next(data);
  }

  getWireData(): object {
    return this.wireData.value;
  }

  setDepositInfo(data: object): void {
    this.depositInfo.next(data);
  }

  getDepositInfo(): object {
    return this.depositInfo.value;
  }

  changeAmount(depositAmount: string, control: AbstractControl): void {
    if (/\D/g.test(depositAmount)) {
      depositAmount = depositAmount.replace(/\D/g, "");
      control.setValue(depositAmount);
    }
  }

  stateAbbriviationIdList(): Observable<any> {
    const url = `deposit/brazilstatesabbriviations`;

    return this.newHttp.get<any>(url);
  }

  getBoletoPredefineList(): Observable<any> {
    const url = `psp/predefinedList`;
    return this.newHttp.get<any>(url);
  }
}
