import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RegistrationService } from '../../auth/services/registration/registration.service';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  subscriptionForm: FormGroup;
  private validForm = true;
  showMessage = false;
  legalName: string;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
    this.subscriptionForm = this.formBuilder.group({
      daily: ['', [Validators.required]],
      subscription: ['', [Validators.required]]
    });

    // const mainInfo = this.registrationService.getMainInfo();
    // this.legalName = mainInfo['legalName'];
  }

  get controls() { return this.subscriptionForm.controls; }

  changeSubscription() {
    if (!this.subscriptionForm.value.daily || !this.subscriptionForm.value.subscription) {
      this.showMessage = true;
    } else {
      this.showMessage = false;
    }
  }

  submit() {
    // TODO: call to API
  }

}
