import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DepositTypeService } from '../services/deposit-type.service';
import { BrandService } from 'src/app/auth/services/brand/brand.service';
import { BrandIdEnum } from 'src/app/brand.enum';


@Component({
  selector: 'app-sorin-xe',
  templateUrl: './sorin-xe.component.html',
  styleUrls: ['./sorin-xe.component.scss']
})
export class SorinXeComponent implements OnInit {

  constructor(private depositTypeService: DepositTypeService, private brandService: BrandService, private router: Router) {}
  sorinDescription = true;
  hash: string;
  hostName: string;
  pspId = 13;
  displayName: string;
   public isOtb: boolean;
      public isRevolution: boolean;


  ngOnInit() {
    this.hostName = window.location.hostname;
      switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
           break;
      case BrandIdEnum.International:
      case BrandIdEnum.southAfrica:
        break;
      case BrandIdEnum.OTB:
        this.isOtb = true;
        break;
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
         break;
      default:
        break;
    }
    this.getSorinXEById();
  }

  getSorinXEById() {
    this.depositTypeService
      .getSorinXEById(this.pspId)
      .subscribe(result => {
        if (result.data) {
          this.hash = result.data.link;
          this.displayName = result.data.displayName;
        }
      });
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val['innerText'];
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  navigateTo() {
    // this.router.navigate([`${this.hash}`]);
    // console.log(this.hash);
    window.open('//' + this.hash, '_blank');
  }
}
