import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RegistrationService } from '../services/registration/registration.service';
import { ValidationService } from '../../services/validation/validation.service';
import { Router } from '@angular/router';
import { BrandService } from '../services/brand/brand.service';
import { ToasterService } from 'angular2-toaster/src/toaster.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  validEmail = true;
  emailNotFount = false;
  // currentWebsitesBrandId: number;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private validationService: ValidationService,
    private router: Router,
    private brandService: BrandService,
    private toasterService: ToasterService,

  ) { }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', this.validationService.email()],
    });

    // this.currentWebsitesBrandId = this.brandService.getCurrentWebsitesBrandId();
  }

  submit(): void {
    if (this.resetPasswordForm.valid) {
      const email = this.resetPasswordForm.value.email;
      const data = {
        emailAddress: email
      };

      localStorage.setItem('email', email);

      this.registrationService.resetPass(data).subscribe(res => {
        if (!res.data) {
          this.validEmail = false;
          this.emailNotFount = true;
        } else {
          this.toasterService.pop('success', res.message);
          this.router.navigate(['verify-reset-password', res.data]);
        }
      }, () => {
        this.validEmail = false;
        this.emailNotFount = true;
      });
    } else {
      this.validEmail = this.controls['email'].valid;
    }
  }

  get controls() { return this.resetPasswordForm.controls; }

  changeEmail(): void {
    this.validEmail = true;
    this.emailNotFount = false;
  }

}
