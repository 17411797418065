import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { GeneralService } from '../../../../services/general/general.service';
import { PageService } from '../../../../page.service';
import {Currency, Account, BasicInfo} from '../../../../interfaces';
import { AppropriatenessTestService } from '../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToasterService } from 'angular2-toaster';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],

})
export class CreditCardComponent implements OnInit, OnDestroy {



  creditCardForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validFullName = true;
  validIssuingCountry = true;
  validVisaNumber = true;
  validExpiryMonth = true;
  validExpiryYear = true;
  validCVV = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  activeAccount: object;
  fullName: string;
  countries: object;
  currentType: object;
  currentMinDeposit: number
  currencies: Currency[];
  newAccount: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  account: any;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount = false;
  isChina = false;
  id='9'
  modal= false

  constructor(
    private langService: LanguageService,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private depositTypeService: DepositTypeService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private generalService: GeneralService,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
    private toasterService:ToasterService
  ) { }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.fireFormValidations();
    this.clientId = this.authService.getAuthClientId();
    // this.registrationService.getBasicInfo(this.clientId).subscribe(basicInfo => {
    //   this.fullName = basicInfo.firstName + ' ' + basicInfo.lastName;
    //   const issuingCountry = basicInfo.countryCode;
    //   this.creditCardForm.get('issuing_country').setValue(issuingCountry);
    //   this.isActive = basicInfo.isActive;
    //   this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
    //     this.currentType = currentType;
    //     if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
    //       if(this.isActive) {
    //         this.currentMinDeposit = 0;
    //       }
    //       else {
    //         this.currentMinDeposit = this.currentType['minDeposit'];
    //       }

    //       this.updateValidation();
    //     } else { // TODO: remove this in future
    //       this.currentMinDeposit = 50;
    //       this.updateValidation();
    //     }
    //   });
    // });
    // this.registrationService
    //   .getAppropriatenessTestLevel(this.clientId)
    //   .subscribe(res => {
    //     if(res["status"] == 4) {
    //       this.testTermsAccepted = true;
    //     } else if(res["status"] == 2 || res["status"] == 3) {
    //       // this.appropriatenessTestService.getTestTerms(this.clientId).subscribe(testTerms => {
    //       //   if(testTerms['testTerms'] == true) {
    //       //     this.testTermsAccepted = true;
    //       //   } else {
    //       //     this.testTermsAccepted = false;
    //       //   }
    //       // })
    //     } else {
    //       this.testTermsAccepted = false;
    //     }
    //   });

    // this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe((currencies: Currency[]) => {
    //   this.currencies = currencies;

    //   this.changeAccount(this.activeAccountNumber);
    // });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
      if (accounts.length > 0) {
        this.accounts = accounts;
        this.openNewAccount = false;
      } else {
        const value = 'Open a new account';
        this.creditCardForm.get('account').setValue(value);
        this.openNewAccount = true;
      }
    });

    this.accountService.getPspCurrency(this.id).subscribe(currencies => {
      this.currencies = currencies.data;
    });

    // this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
    //   if (activeAccount) {
    //     this.activeAccountNumber = activeAccount['account'];
    //     if (localStorage.getItem('tmp_account')) {
    //       this.creditCardForm.get('account').setValue(localStorage.getItem('tmp_account'));
    //       localStorage.removeItem(('tmp_account'));
    //     } else {
    //       this.creditCardForm.get('account').setValue(this.activeAccountNumber);
    //     }

    //     this.changeAccount(this.activeAccountNumber);
    //   }
    // });


    // this.generalService.getCountries().subscribe(countries => {
    //   this.countries = countries;
    // });

    this.getDepositMethodeSubtypeShortList()
  }

  getDepositMethodeSubtypeShortList(){
    this.depositTypeService.depositMethodeSubtypeShortList(this.id)
    .subscribe((res) => {
      if (res.status===1) {
        // this.depositMethodeSubtypeShortList = res.data
        console.log(res.data);

      }
    });
  }

  openModal(data){
    this.modal = true
    const  extraParams  = JSON.parse(data.extraParams)
    let script = document.createElement("script")
    script.type = "text/javascript";
    script.src = "https://embed-sandbox.bridgerpay.com/cashier";
    script.setAttribute('data-cashier-key', extraParams['Cashier_Key']);
    script.setAttribute('data-cashier-token', extraParams['Cashier_token']);
    script.setAttribute('data-theme', "transparent");
    this.elementRef.nativeElement.appendChild(script)

  }

  submit(): void {
    if (this.creditCardForm.valid) {
      this.submitDeposit();

      // this.pageService.changeLoadingStatus(true);
      // if (this.creditCardForm.controls.account.value === 'Open a new account') {
      //   // TODO: get leverage
      //   const accountData = {
      //     clientId: this.clientId,
      //     currencyId: this.creditCardForm.value.currency,
      //     accountType: this.currentType['id'],
      //     leverage: 30
      //   };

      //   // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
      //   //   this.newAccount = res.account;
      //   //   this.accountService.updateAccounts(res.account);
      //   //   this.submitDeposit();
      //   // });
      // } else {
      // }
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validFullName = this.controls['full_name'].valid;
      this.validIssuingCountry = this.controls['issuing_country'].valid;
      this.validVisaNumber = this.controls['card_number'].valid;
      this.validExpiryMonth = this.controls['expiry_month'].valid;
      this.validExpiryYear = this.controls['expiry_year'].valid;
      this.validCVV = this.controls['CVV'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
    }
  }

  get controls() { return this.creditCardForm.controls; }

  submitDeposit() {
    let account = this.creditCardForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      clientId: this.clientId,
      depositMethodId: '9',
      depositMethodSubtypeId: null,
      amount: Number(this.creditCardForm.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.creditCardForm.value.currency,
      // id: 0,
      // clientId: this.clientId,
      // depositMethod: 0,
      // account: account,
      // currencyId: this.creditCardForm.value.currency,
      // amount: Number(this.creditCardForm.value.deposit_amount),
      // creditCard: {
      //   id: 0,
      //   registrationDepositId: 0,
      //   issuingCountryCode: this.creditCardForm.value.issuing_country,
      //   nameOnCard: this.creditCardForm.value.full_name,
      //   cardNumber: this.creditCardForm.value.card_number,
      //   expiryMonth: parseInt(this.creditCardForm.value.expiry_month, 0),
      //   expiryYear: parseInt(this.creditCardForm.value.expiry_year, 0),
      //   cvv2: this.creditCardForm.value.CVV
      // },
      // bridgerpay: { active: true },
      // depositStatus: 0,
      // done: true
    };

    this.depositTypeService.submitDeposit(data)
    .subscribe((res) => {
      if (res.status===1) {
        this.openModal(res.data)

      } else {
        this.toasterService.pop('error', res.message);
      }
    });

    // this.accountService.submitDepositInfo(data).subscribe(res => {
    //   this.pageService.changeLoadingStatus(false);

    //   let messageName;

    //   switch (res['status']) {
    //     case 2:
    //       messageName = 'review';
    //       break;
    //     case 3:
    //       messageName = 'success';
    //       break;
    //     case 4:
    //       messageName = 'decline';
    //       break;
    //   }

    //   const selectedCurrency = this.currencies.find(currency => {
    //     return Number(this.creditCardForm.value.currency) === currency.id;
    //   });

    //   const depositData = {
    //     depositMethod: 'Credit card',
    //     account: account,
    //     clientName: this.fullName,
    //     transactionDate: '',
    //     requestedAmount: this.creditCardForm.value.deposit_amount,
    //     currency: selectedCurrency.currencySymbol,
    //     status: messageName,
    //   };

    //   // TODO: change the way of doing this. Pass object to next component ?
    //   this.depositTypeService.setDepositInfo(depositData);
    //   this.accountService.updateAccounts();

    //   this.router.navigate(['message', messageName]);
    // });
  }

  fireFormValidations(): void {
    this.creditCardForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      // full_name: ['', [Validators.required]],
      // issuing_country: ['', [Validators.required]],
      // card_number: ['', [Validators.required]],
      // expiry_month: ['', [Validators.required]],
      // expiry_year: ['', [Validators.required]],
      // CVV: ['', [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
      deposit_amount: ['', [Validators.required, Validators.min(this.currentMinDeposit)]],
    });
  }


  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(depositAmount, this.creditCardForm.get('deposit_amount'));
    this.validDepositAmount = true;
  }

  ngOnDestroy() {

  }

  updateValidation() {
    this.creditCardForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
    this.creditCardForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAccount(value) {
    // this.accountTypeService.getAccountType(value);
    // this.setActiveAccountCurrency(value);

  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {

      const selectedAccount = this.accounts.find(account => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });

        this.creditCardForm.get('currency').setValue(activeAccountCurrency.id);
      }
    }
  }

}
