import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VerifyResetPasswordGuardService implements CanActivate {

  constructor(
    private router: Router,
  ) { }

  canActivate(): boolean {
    if (!localStorage.getItem('email') || !localStorage.getItem('code')) {
      this.router.navigate(['verify-reset-password']);
      return false;
    }
    return true;
  }

}
