import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountTypeService } from './services/account-type.service';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../auth/services/registration/registration.service';
import { AccountService } from '../../services/account/account.service';
import { LanguageService } from '../../../languages/services/language/language.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { AccountType } from '../../../interfaces';

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent implements OnInit, OnDestroy {

  type: string;
  accountTypes: object;
  accountTypesOriginal: object;
  currentLanguage: string;
  defaultText: string;
  clientId: number;
  private chosenLanguageSubscription;

  constructor(
    private accountTypeService: AccountTypeService,
    private router: Router,
    private registrationService: RegistrationService,
    private accountService: AccountService,
    private authService: AuthService,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.accountTypeService.isAccountTypeChosen.next(false);
    this.clientId = this.authService.getAuthClientId();
    this.currentLanguage = this.languageService.getLanguage();

    this.accountService.getBrandAccountTypes().subscribe((accountTypes) => {
if(accountTypes.status===1){


      accountTypes.data.sort((a, b) => {
        if (a['minDeposit'] < b['minDeposit']) {
          return -1;
        }

        if (a['minDeposit'] > b['minDeposit']) {
          return 1;
        }

        return 0;
      });

      this.accountTypes = accountTypes.data;

      // this.chosenLanguageSubscription = this.languageService
      // .chosenLanguageObservable.subscribe(language => {
      //   const accountTypesClone = JSON.parse(JSON.stringify(this.accountTypesOriginal));

      //   accountTypesClone.forEach((accountType: AccountType, i) => {
      //     if (accountType.comments.length > 0) {
      //       accountType.comments.forEach((comment: [], j) => {
      //         if ('string' !== typeof accountTypesClone[i].comments) {
      //           let text;
      //           if (accountTypesClone[i].comments[j]['code'] === language) {
      //             text = accountTypesClone[i].comments[j]['text'].toString();
      //           } else if (accountTypesClone[i].comments[j]['code'] === 'EN') {
      //             this.defaultText = accountTypesClone[i].comments[j]['text'].toString();
      //           }

      //           if ('undefined' !== typeof text) {
      //             accountTypesClone[i].comments = text;
      //           } else if (accountTypesClone[i].comments.length === Number(j) + 1) {
      //             if ('undefined' !== typeof this.defaultText) {
      //               accountTypesClone[i].comments = this.defaultText;
      //             } else {
      //               accountTypesClone[i].comments = '';
      //             }
      //             this.defaultText = '';
      //           }
      //         }
      //       });
      //     } else {
      //       accountTypesClone[i].comments = '';
      //     }
      //   });

      //   this.accountTypes = accountTypesClone;
      // });
    }
    });
  }

  selectType(selectedType) {
    this.accountTypeService.changeType(selectedType);
    this.router.navigate(['deposit-type']);
  }

  ngOnDestroy() {
    this.accountTypeService.isAccountTypeChosen.next(true);

    // this.chosenLanguageSubscription.unsubscribe();
    // this.chosenLanguageSubscription.complete();
  }

}
