import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/dashboard/services/account/account.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { DepositTypeService } from '../services/deposit-type.service';
import { Currency, Account } from 'src/app/interfaces';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { RegistrationService } from 'src/app/auth/services/registration/registration.service';
import { AppropriatenessTestService } from 'src/app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service';
import { PageService } from 'src/app/page.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';

@Component({
  selector: 'app-interkassa',
  templateUrl: './interkassa.component.html',
  styleUrls: ['./interkassa.component.scss']
})
export class InterkassaComponent implements OnInit {
  public interKassaForm: FormGroup;
  public  activeAccountNumber: number;
  public accounts: Account[];
  private accountsSubscription;
  private currenciesSubscription;
  private activeAccountSubscription;
  private currentTypeSubscription;
  public activeAccountCurrency: number;
  public currentType: object;
  public newAccount: number;
  public clientId: number;
  public testTermsAccepted: boolean = false;
  public validAccount: boolean = true;
  public validCurrency: boolean = true;
  public validAmount: boolean = true;
  public validPaymentMethod: boolean = true;
  public openNewAccount: boolean = false;
  public isActive: boolean;
  public currentMinDeposit: number;
  public paymentMethodList: string[];
  public rubCurrency: Currency;
  public fullName: string;

  id = '8'
  currencies: Currency[];
  depositMethodeSubtypeShortList=[]


  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private appropriatenessTestService: AppropriatenessTestService,
    private pageService: PageService,
    private toasterService: ToasterService,
    private router: Router
    ) { }

  ngOnInit() {
    this.formValidations();
    this.clientId = this.authService.getAuthClientId();

    this.accountService.getPspCurrency(this.id).subscribe(currencies => {
      this.currencies = currencies.data;
    });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe(
      (accounts: Account[]) => {
        if (accounts.length > 0) {
          this.accounts = accounts;
          this.openNewAccount = false;
        } else {
          const value = 'Open a new account';
          this.interKassaForm.get('account').setValue(value);
          this.openNewAccount = true;
        }
      }
    );

    this.getDepositMethodeSubtypeShortList()

  }

  getDepositMethodeSubtypeShortList(){
    this.depositTypeService.depositMethodeSubtypeShortList(this.id)
    .subscribe((res) => {
      if (res.status===1) {
        this.depositMethodeSubtypeShortList = res.data
      }
    });
  }
  public initData = () => {
    // this.registrationService
    //   .getAppropriatenessTestLevel(this.clientId)
    //   .subscribe(res => {
    //     if (res['status'] == 4) {
    //       this.testTermsAccepted = true;
    //     } else if (res['status'] == 2 || res['status'] == 3) {
    //       this.appropriatenessTestService
    //         .getTestTerms(this.clientId)
    //         .subscribe(testTerms => {
    //           if (testTerms['testTerms'] == true) {
    //             this.testTermsAccepted = true;
    //           } else {
    //             this.testTermsAccepted = false;
    //           }
    //         });
    //     } else {
    //       this.testTermsAccepted = false;
    //     }
    //   });

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe(
      (currencies: Currency[]) => {
        this.rubCurrency = currencies.find((currency) => currency.name == 'RUB');
        this.changeAccount(this.activeAccountNumber);
      }
    );

    this.accountsSubscription = this.accountService.accountsObservable.subscribe(
      (accounts: Account[]) => {
        if (accounts.length > 0) {
          this.accounts = accounts;
          this.openNewAccount = false;
        } else {
          const value = 'Open a new account';
          this.interKassaForm.get('account').setValue(value);
          this.openNewAccount = true;
        }
      }
    );

    // this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe(
    //   (activeAccount: Account) => {
    //     if (activeAccount) {
    //       this.activeAccountNumber = activeAccount['account'];
    //       if (localStorage.getItem('tmp_account')) {
    //         this.interKassaForm
    //           .get('account')
    //           .setValue(localStorage.getItem('tmp_account'));
    //         localStorage.removeItem('tmp_account');
    //       } else {
    //         this.interKassaForm.get('account').setValue(this.activeAccountNumber);
    //       }

    //       this.changeAccount(this.activeAccountNumber);
    //     }
    //   }
    // );

    // this.registrationService.getBasicInfo(this.clientId).subscribe(basicInfo => {
    //   this.fullName = basicInfo.firstName + ' ' + basicInfo.lastName;
    //   this.isActive = basicInfo.isActive;
    //   this.getInterkassaPaymentMethods(basicInfo.brandId);
    //   this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
    //     this.currentType = currentType;
    //     if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
    //       if (this.isActive) {
    //         this.currentMinDeposit = 0;
    //       } else {
    //         this.currentMinDeposit = this.currentType['minDeposit'];
    //       }
    //       this.updateValidation();
    //     } else { // TODO: remove this in future
    //       this.currentMinDeposit = 50;
    //       this.updateValidation();
    //     }
    //   });
    // });
  }

  updateValidation() {
    this.interKassaForm.controls.amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit)
    ]);
    this.interKassaForm.controls.amount.updateValueAndValidity();
  }

  onConfirmChange(value) {
    this.interKassaForm.get('confirmed').setValue(value);
  }


  get controls() {
    return this.interKassaForm.controls;
  }

  private formValidations = (): void => {
    this.interKassaForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      confirmed: [false]
    });
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    // this.setActiveAccountCurrency(value);
  }

  // setActiveAccountCurrency(value) {
  //   if (undefined !== value && this.currencies.length > 0) {

  //     const selectedAccount = this.accounts.find((account) => {
  //       return account.accountId === Number(value);
  //     });

  //     if (selectedAccount) {
  //       const activeAccountCurrency = this.currencies.find((currency: Currency) => {
  //         return currency['name'] === selectedAccount['currency'];
  //       });
  //       this.interKassaForm.get('currency').setValue(activeAccountCurrency['id']);
  //     }
  //   }
  // }

  public submit = () => {
    if (this.interKassaForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      this.submitForm();

    } else {
      this.validAccount = this.controls['account'].valid;
      this.validAmount = this.controls['amount'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validPaymentMethod = this.controls['paymentMethod'].valid;
    }
  }

  submitForm() {
    let account = this.interKassaForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }
    const data = {
      depositMethodId: '7',
      depositMethodSubtypeId: this.interKassaForm.value.paymentMethod,
      amount: Number(this.interKassaForm.value.amount),
      accountId: account,
      currencyISOCode: this.interKassaForm.value.currency,
    };

    this.depositTypeService.submitDeposit(data)
    .subscribe((res) => {
      if (res.status===1) {
        console.log(res);
      }
    });
    // this.accountService.submitDepositInfo(data).subscribe(res => {
    //   this.pageService.changeLoadingStatus(false);

    //   let messageName;

    //   switch (res['status']) {
    //     case 2:
    //       messageName = 'review';
    //       break;
    //     case 3:
    //       messageName = 'success';
    //       break;
    //     case 4:
    //       messageName = 'decline';
    //       break;
    //   }


    //   const depositData = {
    //     depositMethod: 'Credit Card',
    //     account: account,
    //     clientName: this.fullName,
    //     transactionDate: '',
    //     requestedAmount: this.interKassaForm.value.amount,
    //     currency: this.rubCurrency.currencySymbol,
    //     status: messageName,
    //   };

    //   // TODO: change the way of doing this. Pass object to next component ?
    //   this.depositTypeService.setDepositInfo(depositData);
    //   this.accountService.updateAccounts();
    //   if (messageName==='success'){
    //     window.location.href = res['response'].redirectUrl;
    //   } else{
    //     this.router.navigate(['message', messageName]);
    //   }
    // }, error => {
    //     this.pageService.changeLoadingStatus(false);
    //     this.toasterService.pop('error', 'Error', error['error']['response']);
    // });
  }

  // getInterkassaPaymentMethods(brandId: number) {
    // this.accountService.getInterkassaPaymentMethods(brandId).subscribe(result => {
    //   this.paymentMethodList = result;
    // });
  // }

  // generatePaymentMethodName(method: string) {
  //   return method.split('_')[0].toUpperCase();
  // }

  subTypeChange(subTypeId){
    switch (subTypeId){
      case '1':
        console.log(1);
        break;
      case '2':
        console.log(2);
        break;
      case '3':
        console.log(3);
        break;
    }
  }

  ngOndestroy() {


  }
}
