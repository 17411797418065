import { NewHttpClientService } from './../../../services/new-http-client.service';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { HttpClientService } from '../../../services/http-client.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import {
  Currency,
  AccountType,
  Account,
  AdditionalLiveAccount,
  CompanyLegalName,
  AccountBalance,
  Withdrawal,
  DepositInfo,
  AdditionalLiveAccountResponse,
  Balance,
  CountryStates,
} from '../../../interfaces';
import { ZotaPayRatesAndLimits } from 'src/app/interfaces/zotapay-rates-and-limits';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  prefix = 'Accounts';
  newPrefix = 'accountPortal'

  private accounts = new BehaviorSubject([]);
  private activeAccount = new BehaviorSubject(null);
  private activeAccountBalanceChanged = new BehaviorSubject(false);
  accountsObservable = this.accounts.asObservable();
  activeAccountObservable = this.activeAccount.asObservable();
  activeAccountBalanceChangedObservable = this.activeAccountBalanceChanged.asObservable();

  constructor(
    // private http: HttpClientService,
    private newHttp: NewHttpClientService,
    private authService: AuthService,
  ) {}

  setAccounts(accounts: Account[]): void {
    this.accounts.next(accounts);
  }

  getAccounts(): Account[] {
    return this.accounts.value;
  }

  setActiveAccount(account: Account): void {
    if (account) {
      localStorage.setItem('activeAccount', `${JSON.stringify(account)}`);
    }
    this.activeAccount.next(account);
  }

  getActiveAccount(): Account {
    return this.activeAccount.value;
  }

  setActiveAccountBalanceChanged(status: boolean): void {
    this.activeAccountBalanceChanged.next(status);
  }

  updateAccounts(id: number, isDemoAccount: boolean) {
    if ('undefined' === typeof id) {
      id = this.activeAccount.value['account'];
    }

    const clientId = this.authService.getAuthClientId();

    this.getClientAccounts(clientId).subscribe((res) => {
      if(res.status===1){
      this.setAccounts(res.data);
      if (res.data) {
        const activeAccount = res.data.find((account) => {
          return isDemoAccount ? (account.id === id && account.isDemoAccount) : account.id === id;
        });
        this.setActiveAccount(activeAccount);
      }
    }
    });
  }
/////
  public getClientAccounts(clientId: number): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/client/${clientId}/account/all`);
  }

  public getWalletAccounts(): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/accountsfortransactions`);
  }

  public getClientAccountById(clientId: number, accountId: number, isDemoAccount: boolean): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/client/${clientId}/account/${accountId}/${isDemoAccount}`);
  }

  public getLeverageShortList(accountTypeId): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/${accountTypeId}/leverage/shortList`);
  }
  // public getTypeShortList(): Observable<any> {
  //   return this.newHttp.get<any>(`${this.newPrefix}/type/shortList`);
  // }

  public getTypeShortList(platformId): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/type/platform/${platformId}/shortList`);
  }

  public getAccountTypeCategoryShortList(): Observable<any>{
    return this.newHttp.get<any>(`${this.newPrefix}/accounttype/category/shortlist`);
  }

  public getTradingPlatformShortList(): Observable<any>{
    return this.newHttp.get<any>(`TradingAccountTypes/platformtype`);
  }
  public getCurrencyShortList(accountTypeId): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/${accountTypeId}/currency/shortList`);
  }

  public getPspCurrency(pspId): Observable<any> {
    return this.newHttp.get<any>(`PSP/${pspId}/pspCurrenciesByBrand`);
  }

  public getPspBankList(pspId, currencyIsoCode): Observable<any> {
    return this.newHttp.get<any>(`PSP/${pspId}/currency/${currencyIsoCode}`);
  }

  public getPspBankInformation(pspId, recordId): Observable<any> {
    return this.newHttp.get<any>(`PSP/${pspId}/record/${recordId}`);
  }


  public createNewLiveAccount(clientId: number, payload): Observable<any> {
    return this.newHttp.post<any>(`${this.newPrefix}/client/${clientId}`, payload);
  }

  public createNewLiveMT5Account(clientId: number, payload): Observable<any> {
    return this.newHttp.post<any>(`${this.newPrefix}/client/${clientId}/mt5`, payload);
  }

  public getBrandAccountTypes(): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/brand/AccountType`);
  }

  getMaximumWithdrawalAmount(accountNumber): Observable<any> {
    return this.newHttp.get<any>(`withdrawal/${accountNumber}/maxAmount`);
  }

  addWithdrawal(payload): Observable<any> {
    return this.newHttp.post<any>(`withdrawal/withdrawalPortal`, payload);
  }

  getWithdrawalDeposits(withdrawalId: number, params): Observable<any> {
    return this.newHttp.patch<any>(`withdrawal/${withdrawalId}/deposits`, params);
  }

  getPspShortList(): Observable<any> {
    return this.newHttp.get<any>(`psp/ShortList`);
  }

  getPspShortListAlternative(): Observable<any> {
    return this.newHttp.get<any>(`psp/ShortList/alternativ`);
  }

  getSavedAlternativeMethod(
    withdrawalId: number,
    accountNumber: number
  ): Observable<any> {
    return this.newHttp.get<any>(`withdrawal/${withdrawalId}/savedAlternative/${accountNumber}`);
  }

  getProcessingAlternative(
    withdrawalId: number
  ): Observable<any> {
    return this.newHttp.get<any>(`withdrawal/${withdrawalId}/alternativeMethod`)
  }

  updateWithdrawal(
    withdrawalId: number,
    payload
  ): Observable<any> {
    return this.newHttp.put<any>(`withdrawal/${withdrawalId}`, payload);
  }

  public manageFunds( payload): Observable<any> {
    return this.newHttp.post<any>(`${this.newPrefix}/internalTransfer`, payload);
  }

  getCurrencyRate(currencyIsoCode: string, clientAccountId): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/currencyrate/${currencyIsoCode}/toaccountcurrency/${clientAccountId}`);
  }


  getCurrencyRateByAccounts(fromAccount, toAccount): Observable<any> {
    return this.newHttp.get<any>(`${this.newPrefix}/convertedrate/${fromAccount}/${toAccount}`)
  }

  getUICurrencyShortList(): Observable<any> {
    return this.newHttp.get<any>(`currency/ShortList`)
  }

  getClientAccountByAccountType(accountType): Observable<any> {
    return  this.newHttp.get<any>(`${this.newPrefix}/client/accounts/${accountType}`);
  }

  // public updatePassword( payload): Observable<any> {
  //   return this.newHttp.post<any>(`User/UpdatePassword`, payload);
  // }
/////
  // public getCurrencies(brandId): Observable<Currency[]> {
  //   return this.http.get<Currency[]>(this.prefix + '/GetCurrencies?brandId=' + brandId);
  // }

  // public addAdditionalLiveAccount(data: AdditionalLiveAccount): Observable<AdditionalLiveAccountResponse> {
  //   return this.http.post<AdditionalLiveAccountResponse>(this.prefix + '/AddAdditionalLiveAccount', data);
  // }

  // public addAdditionalDemoAccount(data) {
  //   return this.http.post(this.prefix + '/AddAdditionalDemoAccount', data);
  // }

  // public getCompanyLegalName(brandId: number): Observable<CompanyLegalName> {
  //   return this.http.get<CompanyLegalName>(this.prefix + '/GetCompanyLegalName?brandId=' + brandId);
  // }

  // public getAccountTypes(clientId: number): Observable<AccountType[]> {
  //   return this.http.get<AccountType[]>(this.prefix + '/GetAccountTypes?clientId=' + clientId);
  // }

  // public getAccountBalance(account: number): Observable<AccountBalance> {
  //   return this.http.get<AccountBalance>(this.prefix + '/GetAccountBalance?account=' + account);
  // }

  // public getBalance(clientId: number, accountId: number): Observable<Balance> {
  //   return this.http.get<Balance>(this.prefix + '/GetBalance?clientId=' + clientId + '&accountId=' + accountId);
  // }

  // public submitWithdrawal(data: Withdrawal) {
  //   return this.http.post(this.prefix + '/SubmitWithdrawal', data);
  // }

  // // TODO: add response interface
  // public submitDepositInfo(data) {
  //   return this.http.post(this.prefix + '/SubmitDepositInfo', data);
  //   // return this.http.post(this.prefix + '/Deposit', data);
  // }

  // public changeAccountPassword(data) {
  //   return this.http.post(this.prefix + '/ChangeAccountPassword', data);
  // }

  // public changeAccountInvestorPassword(data) {
  //   return this.http.post(this.prefix + '/ChangeAccountInvestorPassword', data);
  // }

  // public fund(data) {
  //   return this.http.post(this.prefix + '/Fund?clientId=' + data['clientId'] + '&fromAccountId=' + data['fromAccountId'] + '&toAccountId=' + data['toAccountId'] + '&amount=' + data['amount']);
	// }

	// public getZotaPayCurrencyRateaAndLimits(account: number): Observable<ZotaPayRatesAndLimits[]> {
	// 	const url = `${this.prefix}/GetZotaPayCurrencyRateaAndLimits?Account=${account}`;
	// 	return this.http.get<ZotaPayRatesAndLimits[]>(url);
  // }

  // public getCountryStates(countryId: number): Observable<CountryStates[]> {
  //   const url = `${this.prefix}/GetCountryStates?CountryId=${countryId}`;
  //   return this.http.get<CountryStates[]>(url);
  // }

  // public getInterkassaPaymentMethods(brandId: number): Observable<string[]> {
  //   const url = `${this.prefix}//GetAvailablePaymentMethodsForInterKassa?BrandId=${brandId}`;
  //   return this.http.get<string[]>(url);
  // }
}
