import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-withdrawal-response',
  templateUrl: './withdrawal-response.component.html',
  styleUrls: ['./withdrawal-response.component.scss']
})
export class WithdrawalResponseComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  submit(){
    this.router.navigate(['home'])
  }

}
