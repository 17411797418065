import { Injectable } from "@angular/core";
// import { HttpClientService } from "../../../services/http-client.service";
import { Observable } from "rxjs";
import { ResultModel } from "../../../interfaces";

@Injectable({
  providedIn: "root"
})
export class IpAccessService {
  prefix = "Deposit";
  clientId: number;

  constructor(
    // private http: HttpClientService
    ) {}

  // getIpAccess(): Observable<ResultModel<boolean>> {
  //   return this.http.get<ResultModel<boolean>>(this.prefix + "/IsEFTAvailable");
  // }
}
