import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/services/auth/auth.service';
import { RegistrationService } from '../../auth/services/registration/registration.service';
import { AccountService } from '../services/account/account.service';
import { Router } from '@angular/router';
import { Currency } from '../../interfaces';

@Component({
  selector: 'app-open-demo-account',
  templateUrl: './open-demo-account.component.html',
  styleUrls: ['./open-demo-account.component.scss']
})
export class OpenDemoAccountComponent implements OnInit {

  leverages: Array<any> = [
    {
      'name': '1:1',
      'value': 1
    },
    {
      'name': '1:2',
      'value': 2
    },
    {
      'name': '1:3',
      'value': 3
    },
    {
      'name': '1:5',
      'value': 5
    },
    {
      'name': '1:10',
      'value': 10
    },
    {
      'name': '1:15',
      'value': 15
    },
    {
      'name': '1:20',
      'value': 20
    },
    {
      'name': '1:25',
      'value': 25
    },
    {
      'name': '1:30',
      'value': 30
    },
    {
      'name': '1:50',
      'value': 30
    },
    {
      'name': '1:100',
      'value': 100
    },
    {
      'name': '1:200',
      'value': 200
    },
    {
      'name': '1:300',
      'value': 300
    },
    {
      'name': '1:500',
      'value': 500
    }
  ];

  demoAccountForm: FormGroup;
  validAccountCurrency = true;
  validLeverage = true;
  validDepositAmount = true;
  clientId: number;
  currencies: Currency[];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private accountService: AccountService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.demoAccountForm = this.formBuilder.group({
      currency: ['', [Validators.required]],
      leverage: ['', [Validators.required]],
      deposit_amount: ['', [Validators.required]],
    });

    this.clientId = this.authService.getAuthClientId();

    // const mainInfo = this.registrationService.getMainInfo();
    // const brandId = mainInfo['brandId'];

    // this.accountService.getCurrencies(brandId).subscribe(currencies => {
    //   this.currencies = currencies;
    // });
  }

  submit(): void {
    if (this.demoAccountForm.valid) {
      const clientId = this.clientId;
      // TODO: change parameters and call to API
      const data = {
        clientId: clientId,
        currencyId: this.demoAccountForm.value.currency,
        leverage: this.demoAccountForm.value.leverage,
        deposit: this.demoAccountForm.value.deposit_amount,
      };

      // this.accountService.addAdditionalDemoAccount(data).subscribe(res => {
      //   this.accountService.updateAccounts(res['account']);
      //   this.router.navigate(['/']);
      // });
    } else {
      this.validAccountCurrency = this.controls['currency'].valid;
      this.validLeverage = this.controls['leverage'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
    }
  }

  get controls() { return this.demoAccountForm.controls; }

  changeCurrencySelect(): void {
    this.validAccountCurrency = true;
  }

  changeLeverageSelect(): void {
    this.validLeverage = true;
  }

}
