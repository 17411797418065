import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readMore'
})
export class ReadMorePipe implements PipeTransform {

  transform(value: string, limit:number): string {
    console.log(value);
    if (value) {
      return value.length>limit?`${value.substr(0, limit)}...`: value;
    }
    else{
      return 'No comment'
    }
  }

}
