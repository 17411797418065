import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../../../auth/services/registration/registration.service';

@Component({
  selector: 'app-notice-upon-making-a-deposit',
  templateUrl: './notice-upon-making-a-deposit.component.html',
  styleUrls: ['./notice-upon-making-a-deposit.component.scss']
})
export class NoticeUponMakingADepositComponent implements OnInit {

  firstTime = false;
  message = '';
  clientId: number;

  constructor(
      public activeModal: NgbActiveModal,
      private router: Router,
      private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
  }

  accept() {
    const data = {'clientId': this.clientId};
    // this.registrationService.acceptTermsAndCond(data).subscribe( () => {
    //   // this.registrationService.getTermsAndCondFlag(this.clientId).subscribe( result => {
    //   //   this.firstTime = !result.termsAndCondFlag;
    //   //   this.activeModal.close(this.firstTime);
    //   //   this.router.navigate(['/deposit-type']);
    //   // });
    // });
  }

}
