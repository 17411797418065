import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../../../../../auth/services/registration/registration.service';

@Component({
  selector: 'app-thank-you-message',
  templateUrl: './thank-you-message.component.html',
  styleUrls: ['./thank-you-message.component.scss']
})
export class ThankYouMessageComponent implements OnInit {

  legalName: string;

  constructor(
    private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
    // const mainInfo = this.registrationService.getMainInfo();
    // this.legalName = mainInfo['legalName'];
  }

  submit() {
    this.registrationService.redirectToNextRegistrationStep();
  }

}
