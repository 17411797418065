import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PageService } from '../../../page.service';
import { ProfessionalAccountService } from '../services/professional-account/professional-account.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { AccountService } from '../../services/account/account.service';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../auth/services/registration/registration.service';
import { PdfService } from '../../services/pdf/pdf.service';
import {NotificationService} from '../../../services/notification/notification.service';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import {BasicInfo} from '../../../interfaces';

@Component({
  selector: 'app-professional-account',
  templateUrl: './professional-account.component.html',
  styleUrls: ['./professional-account.component.scss']
})
export class ProfessionalAccountComponent implements OnInit {

  professionalAccountForm: FormGroup;
  validTradesCount = true;
  validInvestedAmount = true;
  validFinancialEuro = true;
  validTradedBroker = true;
  validDerivative = true;
  validTradedProduct = true;
  validDebtInstrument = true;
  validSavings = true;
  validFinancialSector = true;
  validRole = true;
  validFinancialFirm = true;
  validAgree = true;
  chosenApplications: object;
  clientId: number;
  accounts: object[];
  accountsListArray = [];
  accountsList: string;
  lists = {
    activity: ['40-50', '51-60', 'more than 60'],
    amount: ['$6,000-$8,000', '$8,000-$12,000', 'Above $12,000'],
    portf: ['$0-$50,000', '$50,000-$100,000', '$100,000-$200,000', '$200,000-$300,000', '$300,000-$400,000',
      '$400,000-$500,000', '$500,000-$1,000,000', '$1,000,000-$2,000,000', '$2,000,000 +'],
    sector: ['Asset management', 'banking', 'brokerage services', 'capital markets', 'hedge funds', 'investment advisory'],
    role: ['portfolio manager', 'analyst, research', 'Asset manager', 'broker\\trader', 'dealing desk', 'adviser', 'other']
  };
  clientFullName: string;
  legalName: string;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;

 constructor(
   private formBuilder: FormBuilder,
   private pageService: PageService,
   private professionalAccountService: ProfessionalAccountService,
   private authService: AuthService,
   private accountService: AccountService,
   private router: Router,
   private registrationService: RegistrationService,
   private pdfService: PdfService,
   private notificationService: NotificationService,
 ) { }

  ngOnInit() {
    this.professionalAccountForm = this.formBuilder.group({
      agree: [false, Validators.pattern('true')],
    });

    this.pageService.changePageName('Account');

    this.chosenApplications = this.professionalAccountService.getChosenApplications();

    if (this.chosenApplications['first_option'] === true) {
      this.professionalAccountForm.addControl('trade_count', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('invested_amount', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('traded_broker', new FormControl('', Validators.required));
    }

    if (this.chosenApplications['second_option'] === true) {
      this.professionalAccountForm.addControl('financial_euro', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('traded_product', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('derivative', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('debt_instrument', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('savings', new FormControl('', Validators.required));
    }

    if (this.chosenApplications['third_option'] === true) {
      this.professionalAccountForm.addControl('financial_sector', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('role', new FormControl('', Validators.required));
      this.professionalAccountForm.addControl('financial_firm', new FormControl('', Validators.required));
    }

    this.clientId = this.authService.getAuthClientId();
    this.accounts = this.accountService.getAccounts();
    this.accounts.forEach(account => {
      this.accountsListArray.push(account['account']);
      this.accountsList = this.accountsListArray.join(', ');
    });

    // this.registrationService.getBasicInfo(this.clientId).subscribe((basicInfo: BasicInfo) => {
    //   this.clientFullName = basicInfo.firstName + ' ' + basicInfo.lastName;
    // });

    // const mainInfo = this.registrationService.getMainInfo();
    // this.legalName = mainInfo['legalName'];

    // On Dropping page
    window.onbeforeunload = () => {
      const data = {
        clientId: this.clientId,
        step: 2,
      };

      // this.notificationService.appropriatenessTestDropped(data);
    };
  }

  submit(): void {
    if (this.professionalAccountForm.valid) {
      const documentName = 'professional account application ' + this.clientId;
      this.pdfService.generatePDF(documentName);

      // TODO: Send PDF to API

      this.router.navigate(['home']);
    } else {
      if (this.chosenApplications['first_option'] === true) {
        this.validTradesCount = this.controls['trade_count'].valid;
        this.validInvestedAmount = this.controls['invested_amount'].valid;
        this.validTradedBroker = this.controls['traded_broker'].valid;
      }

      if (this.chosenApplications['second_option'] === true) {
        this.validFinancialEuro = this.controls['financial_euro'].valid;
        this.validTradedProduct = this.controls['traded_product'].valid;
        this.validDerivative = this.controls['derivative'].valid;
        this.validDebtInstrument = this.controls['debt_instrument'].valid;
        this.validSavings = this.controls['savings'].valid;
      }

      if (this.chosenApplications['third_option'] === true) {
        this.validFinancialSector = this.controls['financial_sector'].valid;
        this.validRole = this.controls['role'].valid;
        this.validFinancialFirm = this.controls['financial_firm'].valid;
      }

      this.validAgree = this.controls['agree'].valid;
    }
  }

  get controls() { return this.professionalAccountForm.controls; }

  changeTradeCountSelect(): void {
    this.validTradesCount = true;
  }

  changeInvestedAmountSelect(): void {
    this.validInvestedAmount = true;
  }

  changeFinancialSelect(): void {
    this.validFinancialEuro = true;
  }

  changeDerivativeSelect(): void {
    this.validDerivative = true;
  }

  changeTradedProductSelect(): void {
    this.validTradedProduct = true;
  }

  changeDebtInstrumentSelect(): void {
    this.validDebtInstrument = true;
  }

  changeSavingsSelect(): void {
    this.validSavings = true;
  }

  changeFinancialSectorSelect(): void {
    this.validFinancialSector = true;
  }

  changeRoleSelect(): void {
    this.validRole = true;
  }

}
