import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { ToasterService } from 'angular2-toaster/src/toaster.service';
import { Subscription } from 'rxjs';
import { RegistrationService } from '../services/registration/registration.service';

@Component({
  selector: 'app-verify-reset-password',
  templateUrl: './verify-reset-password.component.html',
  styleUrls: ['./verify-reset-password.component.scss']
})
export class VerifyResetPasswordComponent implements OnInit {

  verifyResetPasswordForm: FormGroup;
  validCode = true;
  // email: string;
  private subscription: Subscription;
  public clientId: number;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private toasterService: ToasterService,

  ) { }

  ngOnInit() {

    this.subscription = this.route.params.subscribe(param => {
      this.clientId = param['id'];
    });

    this.verifyResetPasswordForm = this.formBuilder.group({
      code: ['', [Validators.required]],
    });

    // this.email = localStorage.getItem('email');
  }

  submit(): void {
    if (this.verifyResetPasswordForm.valid) {
      const code = this.verifyResetPasswordForm.value.code;
      const data = {
        code: code,
      };

      this.registrationService.verifyResetPass(this.clientId,data).subscribe(res => {
        if (res.data) {
          this.toasterService.pop('success', res.message);

          localStorage.setItem('code', code);
          this.router.navigate(['/']);
        } else {
          this.validCode = false;
        }
      });
    } else {
      this.validCode = this.controls['code'].valid;
    }
  }

  get controls() { return this.verifyResetPasswordForm.controls; }

}
