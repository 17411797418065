import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import {
  faPlus,
  faMinus,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { RegistrationService } from '../../../auth/services/registration/registration.service';
import { IpStackService } from '../../../services/ipstack/ipstack.service';
import { GeneralService } from '../../../services/general/general.service';

@Component({
  selector: 'app-tax-id',
  templateUrl: './tax-id.component.html',
  styleUrls: ['./tax-id.component.scss']
})
export class TaxIdComponent implements OnInit {

  faPlus = faPlus;
  faMinus = faMinus;
  taxIdForm: FormGroup;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  havingTaxId = false;
  validHavingTaxId = true;
  taxIds = [
    {
      countryCode: '',
      taxId: '',
    }
  ];
  validTaxIds = [
    {
      countryCode: true,
      taxId: true,
    }
  ];
  maxTaxIds = 3;
  limitError = false;
  countries;
  ipStack;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private ipStackService: IpStackService,
    private generalService: GeneralService,
  ) { }

  ngOnInit() {
    this.taxIdForm = this.formBuilder.group({
      taxIds: this.formBuilder.array([
        this.formBuilder.group({
          countryCode: ['', Validators.required],
          taxIdentifier: ['', Validators.required]
        })
      ]),
      having_tax_id: ['', [Validators.required]],
    });

    // this.ipStackService.get().subscribe(res => {
    //   this.ipStack = res;
    // });

    // this.generalService.getCountries().subscribe(res => {
    //   this.countries = res;
    // });
  }

  submit(): void {
    // TODO: understand how it should work
    if (!this.havingTaxId) {
        // TODO: redirect to some page?
        console.log('success');
    } else if (this.taxIdForm.valid) {
      const client = this.authService.getAuthClient();

      const data = this.taxIdForm.value.taxIds;
      data.forEach(taxId => {
        taxId.id = 0;
        taxId.clientId = client.id;
      });
      console.log(data);

      // this.registrationService.setTaxId(data).subscribe(res => {
      //   // TODO: redirect to some page?
      //   console.log('success');

      //   this.registrationService.redirectToNextRegistrationStep();
      // });
    } else {
      const validTaxIds = this.validTaxIds;

      for (let i = 0; i < validTaxIds.length; i++) {
        const taxId = this.getTaxIds.controls[i];

        validTaxIds[i].countryCode = taxId.get('countryCode').valid;
        validTaxIds[i].taxId = taxId.get('taxIdentifier').valid;
      }

      this.validHavingTaxId = this.controls['having_tax_id'].valid;
    }
  }

  get controls() { return this.taxIdForm.controls; }

  changeCountryCodeSelect(i: number): void {
    this.validTaxIds[i].countryCode = true;
  }

  changeHavingTaxId(): void {
    if (this.taxIdForm.value.having_tax_id === 'yes') {
      this.havingTaxId = true;
    } else {
      this.havingTaxId = false;
    }
  }

  addTaxId(): void {
    if (this.validTaxIds.length !== this.maxTaxIds) {
      (this.taxIdForm.controls['taxIds'] as FormArray).push(this.formBuilder.group({
        countryCode: ['', Validators.required],
        taxIdentifier: ['', Validators.required]
      }));

      this.validTaxIds.push({
        countryCode: true,
        taxId: true,
      });
    } else {
      this.limitError = true;
    }
  }

  removeTaxId(index: number): void {
    (this.taxIdForm.controls['taxIds'] as FormArray).removeAt(index);
    this.validTaxIds.splice(index, 1);
    this.limitError = false;
  }

  get getTaxIds() { return <FormArray>this.taxIdForm.get('taxIds'); }

}
