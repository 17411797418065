import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { Router } from '@angular/router';
import { BankService } from '../../../services/bank/bank.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { PageService } from '../../../../page.service';
import { Currency, Account, Bank } from '../../../../interfaces';
import { AppropriatenessTestService } from '../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: 'app-wire-transfer',
  templateUrl: './wire-transfer.component.html',
  styleUrls: ['./wire-transfer.component.scss']
})
export class WireTransferComponent implements OnInit, OnDestroy {

  transferForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validBankBrand = true;
  validBankName = true;
  validAccountNumber = true;
  validDepositAmount = true;
  validReference = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currentType: object;
  newAccount: number;
  banks: Bank[];
  currentMinDeposit: number;
  selectedCurrency: any;
  selectedBank: Bank;
  showCurrencyMsg = false;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  mainInfo: any;
  brandId: number;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount: boolean = false;
  isChina = false;

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,
    private router: Router,
    private bankService: BankService,
    private registrationService: RegistrationService,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService
  ) { }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.fireFormValidations();

    // this.mainInfo = this.registrationService.getMainInfo();
    // this.brandId = this.mainInfo.brandId;
    this.clientId = this.authService.getAuthClientId();

    // this.registrationService
    //   .getAppropriatenessTestLevel(this.clientId)
    //   .subscribe(res => {
    //     if(res["status"] == 4) {
    //       this.testTermsAccepted = true;
    //     } else if(res["status"] == 2 || res["status"] == 3) {
    //       this.appropriatenessTestService.getTestTerms(this.clientId).subscribe(testTerms => {
    //         if(testTerms['testTerms'] == true) {
    //           this.testTermsAccepted = true;
    //         } else {
    //           this.testTermsAccepted = false;
    //         }
    //       })
    //     } else {
    //       this.testTermsAccepted = false;
    //     }
    //   });

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe((currencies: Currency[]) => {
      this.currencies = currencies;

      this.changeAccount(this.activeAccountNumber);
    });

    // this.registrationService.getBasicInfo(this.clientId).subscribe(basicInfo => {
    //   this.isActive = basicInfo.isActive;
    //   this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
    //     this.currentType = currentType;
    //     if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
    //       if (this.isActive) {
    //         this.currentMinDeposit = 0;
    //       } else {
    //         this.currentMinDeposit = this.currentType['minDeposit'];
    //       }
    //       this.updateValidation();
    //     } else { // TODO: remove this in future
    //       this.currentMinDeposit = 50;
    //       this.updateValidation();
    //     }
    //   });
    // });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
      if (accounts.length > 0) {
        this.accounts = accounts;
        this.openNewAccount = false;
      } else {
        const value = 'Open a new account';
        this.transferForm.get('account').setValue(value);
        this.openNewAccount = true;
      }
    });

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
      if (activeAccount) {
        this.activeAccountNumber = activeAccount['account'];
        if (localStorage.getItem('tmp_account')) {
          this.transferForm.get('account').setValue(localStorage.getItem('tmp_account'));
          localStorage.removeItem(('tmp_account'));
        } else {
          this.transferForm.get('account').setValue(this.activeAccountNumber);
        }
        this.changeAccount(this.activeAccountNumber);
      }
    });
  }

  submit(): void {
    const notRegisterBanks = [ '4', '5', '6' ];

    if (this.selectedCurrency) {
      if (notRegisterBanks.includes(this.selectedCurrency['id'])) {
        this.showCurrencyMsg = true;
      } else {
        this.showCurrencyMsg = false;
      }
    }

    if (this.transferForm.valid && !notRegisterBanks.includes(this.selectedCurrency['id'])) {
      this.showCurrencyMsg = false;
      // this.pageService.changeLoadingStatus(true);
      if (this.transferForm.controls.account.value === 'Open a new account') {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.transferForm.value.currency,
          accountType: this.currentType['id'],
          leverage: 30
        };

        // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
        //   this.newAccount = res.account;
        //   this.accountService.updateAccounts(res.account);
        //   this.submitDeposit();
        // });
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validBankBrand = this.controls['bank_brand'].valid;
      this.validBankName = this.controls['bank_name'].valid;
      this.validAccountNumber = this.controls['account_number'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
      this.validReference = this.controls['reference'].valid;
    }
  }

  get controls() { return this.transferForm.controls; }

  submitDeposit() {
    let account = this.transferForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      id: 0,
      clientId: this.clientId,
      depositMethod: 0,
      account: account,
      currencyId: this.transferForm.value.currency,
      amount: Number(this.transferForm.value.deposit_amount),
      wireTransfer: {
        id: 0,
        registrationDepositId: 0,
        preferredBank: this.transferForm.value.bank_brand,
        clientBank: this.transferForm.value.bank_name,
        clientAccountNumber: this.transferForm.value.account_number,
        notes: this.transferForm.value.reference
      },
      depositStatus: 0,
      done: true
    };

    // this.accountService.submitDepositInfo(data).subscribe(res => {
    //   this.depositTypeService.setWireData(data);
    //   this.accountService.updateAccounts();
    //   this.pageService.changeLoadingStatus(false);
    //   this.router.navigate(['wire-step2']);
    // });
  }

  selectCurrency() {
    const selectedCurrencyId = this.transferForm.value.currency;

    this.selectedCurrency = this.currencies.find(currency => {
      return currency.id === Number(selectedCurrencyId);
    });
    // this.bankService.getBanks(this.brandId, this.selectedCurrency.id).subscribe((banks) => {
    //   this.banks = banks;
    // });
  }

  selectBank() {
    const selectedBankName = this.transferForm.value.bank_brand;

    this.selectedBank = this.banks.find(bank => {
      return bank.bankName === selectedBankName;
    });
  }

  fireFormValidations() {
    this.transferForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      bank_brand: ['', [Validators.required]],
      bank_name: ['', [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
      account_number: ['', [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
      deposit_amount: ['', [Validators.required, Validators.min(this.currentMinDeposit)]],
      reference: ['', [Validators.required, Validators.pattern("(?!^ +$)^.+$")]],
    });
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(depositAmount, this.transferForm.get('deposit_amount'));
    this.validDepositAmount = true;
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {

      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });

        this.selectedCurrency = activeAccountCurrency;
        this.transferForm.get('currency').setValue(activeAccountCurrency.id);
        this.selectCurrency();
      }
    }
  }

  updateValidation() {
    this.transferForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
    this.transferForm.controls.deposit_amount.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.currentTypeSubscription.unsubscribe();
    this.currentTypeSubscription.complete();

    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();

    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();

    this.currenciesSubscription.unsubscribe();
    this.currenciesSubscription.complete();
  }

}
