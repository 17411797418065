import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suprem-modal',
  templateUrl: './suprem-modal.component.html',
  styleUrls: ['./suprem-modal.component.scss']
})
export class SupremModalComponent implements OnInit {
  src: string;
  state: boolean;
  constructor() { }

  ngOnInit() {
    if( this.state){
      this.buildIframe();
    }else{
      this.buildPngModal();
    }
  }

  buildIframe() {
    const el = document.createElement('iframe');
    el.setAttribute('src', this.src);
    el.setAttribute('style', 'width: 100%; height: 95vh; border: none;');
    el.setAttribute('allowfullscreen', 'allowfullscreen');
    document.getElementById('test').innerHTML = '';
    document.getElementById('test').appendChild(el);
  }

  buildPngModal() {
    const el = document.createElement('img');
    el.setAttribute('src', this.src);
    el.setAttribute('style', 'width: 100%; height: 95vh; border: none;');
    el.setAttribute('allowfullscreen', 'allowfullscreen');
    document.getElementById('pngModal').innerHTML = '';
    document.getElementById('pngModal').appendChild(el);
  }
}
