import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "../services/account/account.service";
import { RegistrationService } from "../../auth/services/registration/registration.service";
import { AuthService } from "../../auth/services/auth/auth.service";
import { Router } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import { Account, Currency } from "../../interfaces";
import { TranslateService } from "@ngx-translate/core";
import { DepositTypeService } from "../info/deposit-type/services/deposit-type.service";
import { ComplianceService } from "../services/compliance/compliance.service";
import {LanguageService} from '../../languages/services/language/language.service';
import { BrandService } from "src/app/auth/services/brand/brand.service";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-withdrawal",
  templateUrl: "./withdrawal.component.html",
  styleUrls: ["./withdrawal.component.scss"],
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  withdrawalForm: FormGroup;
  validAccount = true;
  validWithdrawalBalance = true;
  validCurrency = true;
  validWithdrawAmount = true;
  isTimeStock:boolean;
  accounts: Account[];
  activeAccountNumber: number;
  withdrawalBalance
  currencies: Currency[];
  showError: boolean;
  showErrorComplianceNotApproved: boolean;
  clientId: number;
  private accountsSubscription;
  private activeAccountSubscription;
  amountIsMore = false;
  clientApproved = false;
  clientApprovedDetected = false;
  isChina = false;
  amountValidationRules = []
  isActionAllowed = false

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private router: Router,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private depositTypeService: DepositTypeService,
    private complianceService: ComplianceService,
    private langService: LanguageService,
    private brandService: BrandService

  ) {}

  ngOnInit() {

    this.amountValidationRules = [
      Validators.required,
      Validators.pattern(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/),
      Validators.min(1),
      Validators.max(this.withdrawalBalance),
    ];
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.clientId = this.authService.getAuthClientId();
    this.getIsActionAllowed();

    this.fireFormValidations();


    this.complianceService.getComplianceStatus().subscribe((status) => {
      if (status === 2) {
        // if (window.location.hostname !== "portal.fxcm-globe.com") {
        //   this.getAppropriatenessTestStatus();
        // } else {
          this.clientApproved = true;
          this.clientApprovedDetected = true;
        // }
      } else {
        this.clientApproved = false;
        this.clientApprovedDetected = true;
      }
    });

    this.accountService.getWalletAccounts()
      .subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data
      }
    });

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe(
    //   (accounts) => {
    //     if (accounts) {
    //       this.accounts = accounts;
    //     }
    //   }
    // );

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe(
      (account) => {
        if (account) {
          this.activeAccountNumber = account["account"];
        }
      }
    );

    // const mainInfo = this.registrationService.getMainInfo();
    // const brandId = mainInfo["brandId"];

    // this.accountService.getCurrencies(brandId).subscribe((currencies) => {
    //   if (currencies) {
    //     this.currencies = currencies;
    //   }
    // });

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
        case BrandIdEnum.OTB:
        this.isTimeStock = true;
        break;
    }

    if (this.isTimeStock) {
        this.withdrawalForm.controls["amount"]
         .setValidators([Validators.required, Validators.pattern(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/), Validators.min(50)]);
        this.amountValidationRules.splice(2, 1, Validators.min(50))
    }
  }

  getIsActionAllowed(){
    this.registrationService.isActionAllowed(this.clientId, 3)
    .subscribe((res) => {
      if (res.status===1) {
        this.isActionAllowed = res.data
      }
    });
  }

  fireFormValidations() {
    this.withdrawalForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      withdrawal_balance: [""],
      // currency: ["", [Validators.required]],
      amount: ["", [Validators.required, Validators.pattern(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/)]],
      comment: ["", [Validators.required]],
    });
  }

  submit(): void {
    if (this.withdrawalForm.valid) {
      const values = this.withdrawalForm.value;
      const data = {
        // clientId: this.clientId,
        accountNumber: values.account,
        // currencyId: values.currency,
        amount: values.amount,
        commentText: values.comment,
      };

      this.accountService.addWithdrawal(data).subscribe(
        (res) => {
          switch (res["status"]) {
            case 1: // Success
              this.resetWithdrawalForm();
              let successMessage = "";
              let withdrawal = "";

              this.translateService
                .get("MESSAGES.SUCCESS.WITHDRAWAL")
                .subscribe((text) => {
                  successMessage = text;
                });

              this.translateService
                .get("WITHDRAWAL.TITLE")
                .subscribe((text) => {
                  withdrawal = text;
                });

              // this.toasterService.pop("success", withdrawal, successMessage);
              this.router.navigate(['withdrawal-step2', res['data']], {
                queryParams: { account: res['data']['accountNumber'] },
              });
              break;
            case 2: // Rejected
            this.toasterService.pop("error", 'Warning', res.message);
              break;
          }
        },
        () => {
          this.showError = true;
        }
      );
    } else {
      this.validAccount = this.controls["account"].valid;
      // this.validWithdrawalBalance = this.controls["withdrawal_balance"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validWithdrawAmount = this.controls["amount"].valid;
    }
  }

  get controls() {
    return this.withdrawalForm.controls;
  }

  changeAccountsSelect(ev) {
    this.validAccount = true;
    this.validWithdrawalBalance = true;

    // const chosenAccountNumber = this.withdrawalForm.value.account;

    // const chosenAccount = this.accounts.find((account) => {
    //   return account.accountId.toString() === chosenAccountNumber;
    // });

    this.accountService
      .getMaximumWithdrawalAmount( ev.target.value )
      .subscribe((res) => {
        this.withdrawalBalance = res.data.maxAmount;
        this.withdrawalForm.patchValue({withdrawal_balance: res.data})
        this.withdrawalForm.controls.withdrawal_balance.updateValueAndValidity();

        // const currencyName = accountBalance["currency"];

        // const accountCurrency = this.currencies.find((currency) => {
        //   return currencyName === currency["name"];
        // });

        // this.withdrawalForm.get("currency").setValue(accountCurrency.id);
        this.withdrawalForm.controls.amount.validator = Validators.compose(
          this.amountValidationRules
        );
        this.withdrawalForm.controls.amount.updateValueAndValidity();
      });
  }

  resetWithdrawalForm() {
    this.withdrawalForm.reset();
    this.withdrawalForm.get("account").setValue("");
    // this.withdrawalForm.get("currency").setValue("");
  }

  changeAmount(amount) {
    if (amount !== "") {
      const withdrawalAmount = this.withdrawalForm.get("amount");

      this.depositTypeService.changeAmount(amount, withdrawalAmount);
      const amountNumber = Number(amount);

      if (!isNaN(amountNumber)) {
        if (amountNumber > this.withdrawalBalance) {
          this.validWithdrawAmount = false;

          this.amountIsMore = true;
        } else {
          this.validWithdrawAmount = true;
          this.amountIsMore = false;
        }
      }
    }
  }

  ngOnDestroy() {
    // this.accountsSubscription.unsubscribe();
    // this.accountsSubscription.complete();

    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();
  }

  getAppropriatenessTestStatus() {
    // this.registrationService
    //   .getAppropriatenessTestLevel(this.clientId)
    //   .subscribe((res) => {
    //     switch (res["status"]) {
    //       case 2:
    //       case 3:
    //       case 4:
    //         this.clientApproved = true;
    //         break;
    //       default:
    //         this.clientApproved = false;
    //     }

    //     this.clientApprovedDetected = true;
    //   });
  }
}
