import { Component, OnInit, OnDestroy } from '@angular/core';
import { BankService } from '../../../services/bank/bank.service';
import { DepositTypeService } from '../services/deposit-type.service';
import { PdfService } from '../../../services/pdf/pdf.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { BasicInfo, Currency } from '../../../../interfaces';

@Component({
  selector: 'app-wire-step2',
  templateUrl: './wire-step2.component.html',
  styleUrls: ['./wire-step2.component.scss']
})
export class WireStep2Component implements OnInit, OnDestroy {

  wireData: object;
  basicInfo: BasicInfo;
  currentBank: object;
  chosenCurrency: string;
  legalName: string;
  chosenCurrencyId: number;
  private currenciesSubscription;
  clientId: number;

  constructor(
    private bankService: BankService,
    private depositTypeService: DepositTypeService,
    private pdfService: PdfService,
    private registrationService: RegistrationService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.wireData = this.depositTypeService.getWireData();
    const chosenBank = this.wireData['wireTransfer']['preferredBank'];
    this.chosenCurrencyId = parseFloat(this.wireData['currencyId']);

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe((currencies: Currency[]) => {
      currencies.find((currency: Currency) => {
        if (currency['id'] === this.chosenCurrencyId) {
          this.chosenCurrency = currency['name'];
          return true;
        } else {
          return false;
        }
      });
    });

    this.clientId = this.authService.getAuthClientId();

    // this.registrationService.getBasicInfo(this.clientId).subscribe((basicInfo: BasicInfo) => {
    //   this.basicInfo = basicInfo;
    // });

    // const mainInfo = this.registrationService.getMainInfo();

    // this.bankService.getBanks(mainInfo['brandId'], this.chosenCurrencyId).subscribe(banks => {

    //   this.currentBank = banks.find(bank => {
    //     return bank['bankName'] === chosenBank;
    //   });

    // });

    // if (mainInfo['brandId'] === 2) {
    //   this.legalName = 'Inter global';
    // } else {
    //   this.legalName = mainInfo['legalName'];
    // }
  }

  submit() {
    const documentName = 'wire instructions ' + this.clientId;
    this.pdfService.generatePDF(documentName);

    this.registrationService.redirectToNextRegistrationStep();
  }

  ngOnDestroy() {
    this.currenciesSubscription.unsubscribe();
    this.currenciesSubscription.complete();
  }

}
