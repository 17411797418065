import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '../../../../page.service';
import { AppropriatenessTestService } from '../sevices/appropriateness-test.service';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-level-of-knowledge',
  templateUrl: './level-of-knowledge.component.html',
  styleUrls: ['./level-of-knowledge.component.scss']
})
export class LevelOfKnowledgeComponent implements OnInit, OnDestroy {

  questionsForm: FormGroup;
  validForm = true;
  pageName = 'Level of Knowledge';
  tests: object;
  chooseOptionsArray = [];
  questions: object[];
  faCircle = faCircle;
  data=[];
  faCheckCircle = faCheckCircle;
  tempQuestions =[]
  private testsSubscription;
  finalPortraitAnswer;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);

    this.questionsForm = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    });

    this.pageService.changePageName(this.pageName);

    // this.testsSubscription = this.appropriatenessTestService.testsObservable.subscribe(tests => {
    //   if (tests && tests.pages) {
    //     const questions = tests.pages[this.pageName].questions;
    //     questions.forEach(() => {
    //       (this.questionsForm.controls['questions'] as FormArray).push(this.formBuilder.group({
    //         questionId: ['', Validators.required],
    //         answer: ['', Validators.required],
    //         score: ['', Validators.required]
    //       }));
    //     });

    //     this.tests = tests;
    //     this.questions = this.tests['pages'][this.pageName]['questions'];

    //     const answers = this.appropriatenessTestService.getAnswers();
    //     const firstPageQuestions = tests.pages['Financial Portrait'].questions;
    //     if (answers.length > firstPageQuestions.length) {
    //       answers.splice(firstPageQuestions.length);
    //       this.appropriatenessTestService.updateAnswers(answers);
    //     }
    //   }
    // });


    // this.appropriatenessTestService.getAppropriatnesstTest(1).subscribe(tests => {
    //   // this.data=tests['data']


    //   this.finalPortraitAnswer = this.appropriatenessTestService.getFinalPortraitAnswers();

    //   var questions = tests['data'];
    //   questions.forEach(q=>{
    //     if(q.questionType === 1){
    //       this.tempQuestions.push(q)
    //     }
    //   })

    //   // console.log(this.answers)
    //   this.tempQuestions.forEach(() => {
    //       (this.questionsForm.controls['questions'] as FormArray).push(this.formBuilder.group({
    //         AnswerId: ['', Validators.required],
    //         QuestionId: ['',],
    //         // AnswerText: ['',]

    //       }))
    //     })
    //   this.tests = tests;
    //   this.questions=this.tempQuestions;

    // })

  }

  submit(): void {

    if (this.questionsForm.valid) {
      const data = this.questionsForm.value.questions;
      this.appropriatenessTestService.addAnswers(data);
      this.appropriatenessTestService.addFinalPortraitAnswers(this.finalPortraitAnswer);
      this.router.navigate(['verify-account', 'appropriateness-test', 'level-of-experience']);
    } else {
      this.validForm = false;
    }
  }

  get controls() { return this.questionsForm.controls; }

  ngOnDestroy() {
    // this.testsSubscription.unsubscribe();
    // this.testsSubscription.complete();
  }

}
