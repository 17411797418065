import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { LanguageService } from '../../../../languages/services/language/language.service';
import { PageService } from '../../../../page.service';
import { Currency, Account } from '../../../../interfaces';

@Component({
  selector: 'app-dotpay',
  templateUrl: './dotpay.component.html',
  styleUrls: ['./dotpay.component.scss']
})
export class DotpayComponent implements OnInit, OnDestroy {

  dotpayForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: any;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: number;
  chooseChannelsVisible = false;
  channels: any;
  hasChannelError: string = null;
  currentMinDeposit: number;
  // isTestEnvironment: boolean;
  openNewAccount: boolean = false;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  isChina = false;

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private languageService: LanguageService,
    private pageService: PageService,
  ) { }

  ngOnInit() {
    this.fireFormValidations();
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.clientId = this.authService.getAuthClientId();

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe((currencies: Currency[]) => {
      this.currencies = currencies;

      this.changeAccount(this.activeAccountNumber);
    });

    this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
      this.currentType = currentType;
      if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
        this.currentMinDeposit = this.currentType['minDeposit'];

        this.updateValidation();
      } else { // TODO: remove this in future
        this.currentMinDeposit = 50;

        this.updateValidation();
      }
    });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
      if (accounts.length > 0) {
        this.openNewAccount = false;
        this.accounts = accounts;
      } else {
        const value = 'Open a new account';
        this.dotpayForm.get('account').setValue(value);
        this.openNewAccount = true;
      }
    });

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
      if (activeAccount) {
        this.activeAccountNumber = activeAccount['account'];
        if (localStorage.getItem('tmp_account')) {
          this.dotpayForm.get('account').setValue(localStorage.getItem('tmp_account'));
          localStorage.removeItem(('tmp_account'));
        } else {
          this.dotpayForm.get('account').setValue(this.activeAccountNumber);
        }

        this.changeAccount(this.activeAccountNumber);
      }
    });

    // this.isTestEnvironment = this.registrationService.isTestEnvironment();
  }

  submit(): void {
    if (this.dotpayForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      this.submitForm();
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
    }
  }

  submitForm() {
    this.chooseChannelsVisible = true;
    // this.registrationService.getDotPayChannels(this.isTestEnvironment).subscribe(res => {
    //   this.pageService.changeLoadingStatus(false);
    //   this.channels = res;
    // });
  }

  channelSelected(channel: number): boolean {
    this.hasChannelError = null;
    if (this.dotpayForm.controls.account.value === 'Open a new account') {
      // TODO: get leverage
      const accountData = {
        clientId: this.clientId,
        currencyId: this.dotpayForm.value.currency,
        accountType: this.currentType['id'],
        leverage: 30
      };

      // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
      //   this.newAccount = res.account;
      //   this.accountService.updateAccounts(res.account);
      //   this.payThroughChannel(channel);
      // });
    } else {
      this.payThroughChannel(channel);
    }
    return false;
  }

  payThroughChannel(channel: number) {
    let cur = '';
    for (let idx = 0; idx < (<any[]>this.currencies).length; idx++) {
      if (this.currencies[idx]['id'] === +this.dotpayForm.value.currency) {
        cur = this.currencies[idx]['name'];
      }
    }
    // this.registrationService.getClientBasicInfo(this.clientId).subscribe(data => {
    //   const firstName = data['basicInfo']['firstName'];
    //   const lastName = data['basicInfo']['lastName'];
    //   const email = data['basicInfo']['email'];
    //   const country = data['basicInfo']['countryCode'];
    //   const address = data['addressInfo']['address'];
    //   const zipCode = data['addressInfo']['zipCode'];
    //   const city = data['addressInfo']['city'];
    //   const redirectUrl = window.location.origin;
    //   let account = this.dotpayForm.value.account;

    //   if (account === 'Open a new account') {
    //     account = this.newAccount;
    //   }

    //   const depositInfo = {
    //     id: 0,
    //     clientId: this.clientId,
    //     depositMethod: 0,
    //     account: account,
    //     currencyId: this.dotpayForm.value.currency,
    //     amount: Number(this.dotpayForm.value.deposit_amount),
    //     dotPay: {
    //       amount: this.dotpayForm.value.deposit_amount,
    //       currency: cur,
    //       url: redirectUrl,
    //       firstName: firstName,
    //       lastName: lastName,
    //       email: email,
    //       street: address,
    //       buildingNumber: '57',
    //       postCode: zipCode,
    //       city: city,
    //       country: this.registrationService.getIso3166CountryCode(country),
    //       language: this.languageService.getLanguage(),
    //       isTest: this.isTestEnvironment,
    //       channelId: channel
    //     },
    //     depositStatus: 0,
    //     done: true
    //   };

    //   this.accountService.submitDepositInfo(depositInfo).subscribe(res => {
    //     if (res['response']['redirect_simplified_url']) {
    //       window.location.href = res['response']['redirect_simplified_url'];
    //     } else {
    //       this.hasChannelError = res['response'];
    //     }
    //   });
    // });
  }

  closeChannels() {
    this.chooseChannelsVisible = false;
    this.hasChannelError = null;
  }

  get controls() { return this.dotpayForm.controls; }

  fireFormValidations() {
    this.dotpayForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      deposit_amount: ['', [Validators.required, Validators.min(this.currentMinDeposit)]],
    });
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(depositAmount, this.dotpayForm.get('deposit_amount'));
    this.validDepositAmount = true;
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {

      const selectedAccount = this.accounts.find(account => {
        return account.accountId === Number(value);

      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.dotpayForm.get('currency').setValue(activeAccountCurrency.id);
      }
    }
  }

  updateValidation() {
    this.dotpayForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
    this.dotpayForm.controls.deposit_amount.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.currentTypeSubscription.unsubscribe();
    this.currentTypeSubscription.complete();

    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();

    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();

    this.currenciesSubscription.unsubscribe();
    this.currenciesSubscription.complete();
  }

}
