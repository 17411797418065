import { LanguageService } from './../../../../languages/services/language/language.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eu-countries',
  templateUrl: './eu-countries.component.html',
  styleUrls: ['./eu-countries.component.scss']
})
export class EuCountriesComponent implements OnInit {
  @Input() popUpData
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public form: FormGroup

  // title = 'Thank you for trusting'
  // text1 = 'Please note that as per your residence country you may conduct business with our EU licensed entity'
  // text2 = ' which operates in your jurisdiction. The EU law ensures different trading terms and more investor protections.'

  // yesBtn = 'YES,thank you for letting me know.  I will do business under EU regulatory framework.'
  // noBtn = 'NO, I want to trade outside EU regulatory framework under VFSC regulation. I am aware of what you are referring to.'

  constructor(
    public fb: FormBuilder,
    private modalService: NgbModal,
    public languageService: LanguageService
  ) {
    this.form = this.fb.group({
      checkBox: [true]
    })
   }

  ngOnInit() {
    // if('FR'===this.languageService.getLanguage()){
    //   this.title = " Merci d’avoir choisi "
    //   this.text1 = " Veuillez noter qu'en fonction de votre pays de résidence, vous pouvez faire affaire avec notre entité agréée Européenne "
    //   this.text2 = ". La législation de l'UE fournit des conditions commerciales différentes."

    //   this.yesBtn = " OUI, je ferais affaires dans le cadre réglementaire International sous la régulation VFSC. "
    //   this.noBtn = " NON, je ne ferais pas affaires dans le cadre réglementaire International mais sous UE, CySec. "
    // }
  }

  decline(){
    this.passEntry.emit(false)
    this.modalService.dismissAll(EuCountriesComponent);
  }

  ok(){
    this.passEntry.emit(this.form.value.checkBox==='yes' ? true : false)
    this.modalService.dismissAll(EuCountriesComponent);
  }

}
