import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWindows,
  faAndroid,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountService } from '../../services/account/account.service';

@Component({
  selector: 'app-created-mt5-account',
  templateUrl: './created-mt5-account.component.html',
  styleUrls: ['./created-mt5-account.component.scss']
})
export class CreatedMt5AccountComponent implements OnInit, OnDestroy {
  faWindows = faWindows;
  faAndroid = faAndroid;
  faApple = faApple;
  faDesktop = faDesktop;
  notifier = new Subject()

  accountName:number
  accountId$ = this.route.paramMap.pipe(
    map(paramMap => +paramMap.get('data'))
  )

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
  ) { }

  ngOnInit() {

    this.accountId$
    .pipe(
      switchMap((accountId) =>
        this.accountService.getClientAccountByAccountType(accountId)
      ),
      takeUntil((this.notifier))
    )
    .subscribe(result => {

      // if (result.status == 1) {
      //   this.accountName = result.data
      // }
    })



  }

  ngOnDestroy() {
    this.notifier.next()
    this.notifier.complete()
}

}
