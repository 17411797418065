import { SupremModalComponent } from "./suprem-modal/suprem-modal.component";
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { AccountService } from "../../../services/account/account.service";
import { Account, CountryStates, Currency, PersonalInfoModel } from "../../../../interfaces";
import { AuthService } from "src/app/auth/services/auth/auth.service";
import { PageService } from "src/app/page.service";
import { AccountTypeService } from "../../account-type/services/account-type.service";
import { Router } from "@angular/router";
import { DepositTypeService } from "../services/deposit-type.service";
import { RegistrationService } from "src/app/auth/services/registration/registration.service";
import { AppropriatenessTestService } from "src/app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { ValidationService } from "src/app/services/validation/validation.service";
import { ToasterService } from "angular2-toaster";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { interval, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormService } from "src/app/services/form.service";
import {LanguageService} from '../../../../languages/services/language/language.service';

enum MethodEnum {
  Boleto = 1,
  TED,
  Pix,
}

enum PixSuccsesStatus {
  NotAbleToCreate = 1,
  Created = 2,
  Approved = 3,
  Rejected = 4,
  NotAbleToApprove = 5,
  NotAbleToReject = 6,
  NotValidToCreate = 7,
  PreCreated = 8,
  PSPBackgroundServiceError = 9,
  TransactionStatusCheckError = 10,
}
@Component({
  selector: "app-suprem-cash",
  templateUrl: "./suprem-cash.component.html",
  styleUrls: ["./suprem-cash.component.scss"],
})
export class SupremCashComponent implements OnInit {
  public supremCashBoletoForm: FormGroup;
  public supremTEDForm: FormGroup;
  public supremPixForm: FormGroup;
  public form: FormGroup;

  public accounts: Account[];
  public years: number[] = [];
  public months: string[] = [];
  public fullName: string;
  public email: string;
  public zipCode: string;
  public city: string;
  public address: string;
  public clientId: number;
  public newAccount: number;
  public activeAccountNumber: number;
  public validHolder = true;
  public validCardNumber = true;
  public validSecurityCode = true;
  public validExpiryYear = true;
  public validExpiryMonth = true;
  public validAmount = true;
  public validAccount = true;
  public validAccountTED = true;
  public validEmail = true;
  public validZipCode = true;
  public validCpfOrCNPJ = true;
  public validPhoneCode = true;
  public validPhoneNumber = true;
  public validFirstAndLastNames = true;
  public validAddress = true;
  public validCountryName = true;
  public validCity = true;
  public validStateAbbreviation = true;
  public validTicketAmount = true;
  public fileUploded = true;
  public openNewAccount = false;
  public fileToUpload;
  public uplodedFileBase64: string;
  public countryStatesList: CountryStates[];
  public currencies: Currency;

  public currentType: object;
  public isActive: boolean;
  public currentMinDeposit: number ;
  public testTermsAccepted = false;
  private accountsSubscription;
  private currentTypeSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  boletoPredefinedList: any;
  clientBasicInfo: PersonalInfoModel;
  countryShortList: any;
  isChina = false;
  exChange = 1;
  validCurrency = true;
  errorMessage: string;
  acceptedExtensions = [".jpg", ".png"];

  // TODO get from back-end
  public methodlist = [];
  public stateAbbreviationIdList = [];

  public selectedMethod;
  public methodEnum = MethodEnum;
  public metodPix = PixSuccsesStatus;

  id = "12";
  sub;
  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private authService: AuthService,
    private pageService: PageService,
    private accountTypeService: AccountTypeService,
    private router: Router,
    private depositTypeService: DepositTypeService,
    private registrationService: RegistrationService,
    private appropriatenessTestService: AppropriatenessTestService,
    private validationService: ValidationService,
    private toasterService: ToasterService,
    private modalService: NgbModal
  ) {
    this.supremCashBoletoFormInit();
    this.supremTEDFormInit();
    this.supremPixFormInit();
  }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.generateMonthsYears();
    this.getDepositMethodeSubtypeShortList();
    this.getStateAbbreviationIdList();


    this.clientId = this.authService.getAuthClientId();

    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    // this.accountsSubscription =
    //   this.accountService.accountsObservable.subscribe(
    //     (accounts: Account[]) => {
    //       if (accounts.length > 0) {
    //         this.openNewAccount = false;
    //         this.accounts = accounts;
    //       } else {
    //         const value = "Open a new account";
    //         this.form.get("account").setValue(value);
    //         this.openNewAccount = true;
    //         // this.methodsDisabled = true;
    //       }
    //     }
    //   );

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });



    this.form = this.supremCashBoletoForm;

    this.getBoletoPredefinedList();
    this.getPersonInfo();
    this.getCountryShortList();

  }

  openModal(src, state) {
    const modalRef = this.modalService.open(SupremModalComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.src = src;
    modalRef.componentInstance.state = state;

    modalRef.result.then(null, () => {
      this.sub.unsubscribe();
    });
  }
  // openPngModal(src) {
  //   const modalRef = this.modalService.open(SupremModalComponent, { size: 'lg', centered: true });
  //   modalRef.componentInstance.src = src;
  //   modalRef.result.then(null, () => {
  //   });
  // }

  getDepositMethodeSubtypeShortList() {
    this.depositTypeService
      .depositMethodeSubtypeShortList(this.id)
      .subscribe((res) => {
        if (res.status === 1) {
          this.methodlist = res.data;
          this.methodChange(+res.data[0].id);
        }
      });
  }

  getStateAbbreviationIdList() {
    this.depositTypeService.stateAbbriviationIdList().subscribe((res) => {
      if (res.status === 1) {
        this.stateAbbreviationIdList = res.data;
      }
    });
  }

  public methodChange(method) {
    this.selectedMethod = +method;
    switch (+this.selectedMethod) {
      case this.methodEnum.Boleto:
        this.form = this.supremCashBoletoForm;
        break;
      case this.methodEnum.TED:
        this.form = this.supremTEDForm;
        break;
      case this.methodEnum.Pix:
        this.form = this.supremPixForm;
        break;
    }

    this.depositTypeService
      .getSolutionSupremCurrencyShortList(method, this.id)
      .subscribe((res) => {
        if (res.status === 1) {
          this.currencies = res.data;
        }
      });
  }

  public supremCashBoletoFormInit() {
    this.supremCashBoletoForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
      deposit_amount_usd: [0],
      // tslint:disable-next-line:max-line-length
      email: ["", [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)]],
      phoneCode: ["", [Validators.required]],
      // any number, from 8 digits or 9. any value acepted.
      phoneNumber: ["", [Validators.required, Validators.pattern(/^\d{8}(?:\d{1})?$/)]],
      // person social id or company id. 11 digits or 14 digits. with no dots or traces.
      cpfOrCNPJ: ["", [Validators.required, Validators.pattern(/^\d{11}(?:\d{3})?$/)]],
      // zip code (#####-###)
      zipCode: ["", [Validators.required, Validators.pattern(/^\d{5}-\d{3}$/) ]],
      invoiceExpirationDate: ["", [Validators.required]],
      StateAbbreviationId: ["", [Validators.required]],
      countryName: ["", [Validators.required]],
      city: ["", [Validators.required]],
      address: ["", [Validators.required]],
      firstAndLastNames: ["",],
    });
  }

  public supremTEDFormInit() {
    this.supremTEDForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
      deposit_amount_usd: [0],
      file: ["", [Validators.required]],
      name: ["",],
      // person social id or company id. 11 digits or 14 digits. with no dots or traces.
      document: ["", [Validators.required, Validators.pattern(/^\d{11}(?:\d{3})?$/) ]],
      // tslint:disable-next-line:max-line-length
      email: ["", [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)]],
      address: ["", [Validators.required]],
      // Cliente Zip Code (#####-###) eight digits. any value acepted
      zip_code: ["", [Validators.required, Validators.pattern(/^\d{5}-\d{3}$/)]],
      district: ["", [Validators.required]],
      StateAbbreviationId: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
    });
  }

  // This form also works for PIX but with few changes

  public supremPixFormInit() {
    this.supremPixForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit),Validators.max(12000)],
      ],
      deposit_amount_usd: [0],
    });
  }

  // public updateValidation() {
  //   this.supremCashBoletoForm.controls.amount.setValidators([
  //     Validators.required,
  //     Validators.min(this.currentMinDeposit)
  //   ]);

  //   this.supremCashThicketForm.controls.amount.setValidators([
  //     Validators.required,
  //     Validators.min(this.currentMinDeposit)
  //   ]);

  //   this.supremCashBoletoForm.controls.amount.updateValueAndValidity();
  //   this.supremCashThicketForm.controls.amount.updateValueAndValidity();
  // }

  get creditCardControls() {
    return this.supremCashBoletoForm.controls;
  }

  get TEDControls() {
    return this.supremTEDForm.controls;
  }

  get PixControls() {
    return this.supremPixForm.controls;
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
  }

  handleFileInput(files) {
    const that = this;
    this.fileToUpload = files.item(0);
    const reader = new FileReader();
    reader.onload = ((theFile) => {
      return function (e) {
        const binaryData = e.target.result;
        that.uplodedFileBase64 = window.btoa(binaryData);
      };
    })(that.fileToUpload);
    reader.readAsBinaryString(that.fileToUpload);
  }

  // public submit() {

  //   switch (+this.selectedMethod) {
  //     case this.methodEnum.Boleto:
  //       this.form = this.supremCashBoletoForm;
  //       break;
  //     // case this.methodEnum.ticket:
  //     //   this.form = this.supremCashThicketForm;
  //     //   break;
  //     // case this.methodEnum.ted:
  //     //   this.form = this.supremCashThicketForm;
  //     //   break;
  //   }

  //   if (form.valid) {
  //     // this.pageService.changeLoadingStatus(true);
  //     if (form.controls.account.value === 'Open a new account') {
  //       // TODO: get leverage
  //       const accountData = {
  //         clientId: this.clientId,
  //         currencyId: this.currencies.id,
  //         accountType: this.currentType['id'],
  //         leverage: 30,
  //       };

  //       // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
  //       //   this.newAccount = res.account;
  //       //   this.accountService.updateAccounts(res.account);
  //       //   if (this.selectedMethod == this.methodEnum.creditCard) {
  //       //     this.submitCreditCardDeposit();
  //       //   } else if (this.selectedMethod == this.methodEnum.ticket) {
  //       //     this.submitTicketDeposit();
  //       //   }
  //       // });
  //     } else {
  //       if (this.selectedMethod === this.methodEnum.creditCard) {
  //         this.submitCreditCardDeposit();
  // 	} else if (this.selectedMethod === this.methodEnum.ticket ||
  // 		this.selectedMethod === this.methodEnum.ted) {
  //         this.submitTicketDeposit();
  //       }
  //     }
  //   } else {
  //     if (this.selectedMethod === this.methodEnum.creditCard) {

  //      // this.validCardNumber = this.creditCardControls['card_number'].valid;
  //     //  this.validSecurityCode = this.creditCardControls['security_code'].valid;
  //       this.validExpiryMonth = this.creditCardControls['expiryMonth'].valid;
  //       this.validExpiryYear = this.creditCardControls['expiryYear'].valid;
  //       this.validAmount = this.creditCardControls['amount'].valid;
  //       this.validAccount = this.creditCardControls['account'].valid;
  //     } else {
  //       this.validAccountTED = this.ticketControls['account'].valid;
  // 	    this.validCpfOrCNPJ = this.ticketControls['cpfOrCNPJ'].valid;
  //       this.validCurrency = this.ticketControls['currency'].valid;
  // 	if (this.supremCashThicketForm.get('phoneCode') && this.supremCashThicketForm.get('phoneNumber')) {
  // 		this.validPhoneCode = this.ticketControls['phoneCode'].valid;
  // 		this.validPhoneNumber = this.ticketControls['phoneNumber'].valid;
  // 	}
  // 	if (this.supremCashThicketForm.get('file')) {
  // 		this.fileUploded = this.ticketControls['file'].valid;
  // 	}
  //       this.validStateAbbreviation = this.ticketControls['StateAbbreviationId'].valid;
  //       this.validTicketAmount = this.ticketControls['amount'].valid;
  //     }
  //   }
  // }

  submit() {
    switch (+this.selectedMethod) {
      case this.methodEnum.Boleto:
        this.submitBoletoForm(this.form);
        break;
      case this.methodEnum.TED:
        this.submitTEDForm();
        break;
      case this.methodEnum.Pix:
        this.submitPixForm();
        break;
    }
  }

  submitBoletoForm(form) {
    let account = form.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      accountId: form.value.account,
      currencyISOCode: form.value.currency,
      amount: Number(form.value.deposit_amount),
      depositMethodId: this.id,
      extraParams: {
        email: form.value.email,
        phoneCode: form.value.phoneCode,
        phoneNumber: form.value.phoneNumber,
        cpfOrCNPJ: form.value.cpfOrCNPJ,
        zipCode: form.value.zipCode,
        invoiceExpirationDate: form.value.invoiceExpirationDate,
        StateAbbreviationId: form.value.StateAbbreviationId,
        countryName: form.value.countryName,
        city: form.value.city,
        address: form.value.address,
        firstAndLastNames: form.value.firstAndLastNames,
        SupremCashPaymentMethod: this.selectedMethod,
      },
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        const urlData = res.data.extraParams;
        // window.location.href = urlData;
        this.openModal(urlData, true);
        this.router.navigate(["deposit-response/3"]);
      } else {
        this.errorMessage = res.message;
        this.router.navigate(["deposit-response/2"]);
      }
    });
  }

  submitTEDForm() {
    let account = this.supremTEDForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      accountId: this.supremTEDForm.value.account,
      currencyISOCode: this.supremTEDForm.value.currency,
      amount: Number(this.supremTEDForm.value.deposit_amount),
      depositMethodId: this.id,
      extraParams: {
        name: this.supremTEDForm.value.name,
        document: this.supremTEDForm.value.document,
        file: this.uplodedFileBase64,
        email: this.supremTEDForm.value.email,
        address: this.supremTEDForm.value.address,
        zip_code: this.supremTEDForm.value.zip_code,
        district: this.supremTEDForm.value.district,
        StateAbbreviationId: this.supremTEDForm.value.StateAbbreviationId,
        city: this.supremTEDForm.value.city,
        state: this.supremTEDForm.value.state,
        SupremCashPaymentMethod: this.selectedMethod,
      },
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {

      if (res.status === 1) {
        const success = res.data.extraParams.includes("sucesso");
        if (success) {
          this.toasterService.pop("success", res.data.extraParams);
          this.router.navigate(["deposit-response/3"]);
        } else {
          this.toasterService.pop("error", res.data.extraParams);
          this.router.navigate(["deposit-response/2"]);
        }
      } else {
        this.errorMessage = res.message;
        this.router.navigate(["deposit-response/2"]);
      }

    });
  }

  submitPixForm() {
    let account = this.supremPixForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      accountId: this.supremPixForm.value.account,
      currencyISOCode: this.supremPixForm.value.currency,
      amount: Number(this.supremPixForm.value.deposit_amount),
      depositMethodId: this.id,
      extraParams: {
        SupremCashPaymentMethod: this.selectedMethod,
      },
    };

    //   getMovies(res): Observable<> {

    //     return this.http.get('http://api.request.com)
    //         .map((res: Response) => res.json()['results']);
    // }

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        const source = interval(5000);
        const timer$ = timer(15000 * 60 * 10);
        const example = source.pipe(takeUntil(timer$));

        this.sub = example.subscribe(
          () => {
            this.depositTypeService
              .getSupremCashPixStatusCheck(res.data.newDepositId)
              .subscribe((res1) => {
                if (res1.status == 1 && res1.data == 3) {
                  this.sub.unsubscribe();
                  this.router.navigate(["deposit-response/1"]);
                } else if (res1.status == 1 && res1.data == 2) {
                  this.router.navigate(["deposit-response/3"]);
                } else {
                  this.sub.unsubscribe();
                  this.router.navigate(["deposit-response/2"]);
                  this.toasterService.pop("error", res1.message);
                }
              });
          },
          (err) => {
            console.log(err);
          },
          () => {
            this.modalService.dismissAll(SupremModalComponent);
            this.router.navigate(["deposit-response/2"]);
          }
        );
        const urlData = res.data.extraParams;
        // window.location.href = urlData;
        // window.open("//" + urlData, '_blank');
        // console.log('just worke ');
        // const el = document.createElement('a');
        // el.setAttribute('href', urlData);
        // el.setAttribute('target', '_blank');
        // el.click()

        // window.open(urlData + '')

        this.openModal(urlData, false);
        this.router.navigate(["deposit-response/3"]);
      } else {
        this.errorMessage = res.message;
        this.router.navigate(["deposit-response/2"]);
      }
    });
  }

  // submitTicketDeposit() {
  //   let account = this.supremCashThicketForm.value.account;
  //   if (this.newAccount) {
  //     account = this.newAccount;
  //   }
  //   const amount = this.supremCashThicketForm.value.amount;

  //   this.supremCashThicketForm.value.amount = undefined;

  //   let data = {};

  //   switch (+this.selectedMethod) {
  //     case this.methodEnum.ticket:

  //       data = {
  //         depositMethodId: '4',
  //         depositMethodSubtypeId: this.selectedMethod,
  //         amount: Number(this.supremCashThicketForm.value.amount),
  //         accountId: account,
  //         currencyISOCode: this.supremCashThicketForm.value.currency,
  //         extraParams: {
  //           cpfOrCNPJ: this.supremCashThicketForm.value.cpfOrCNPJ,
  //           StateAbbreviationId: this.supremCashThicketForm.value.StateAbbreviationId,
  //         },
  //       };
  //     break;
  //     case this.methodEnum.ted:
  //       const form = this.supremCashThicketForm.value;
  //       data = {
  //         depositMethodId: '4',
  //         depositMethodSubtypeId: this.selectedMethod,
  //         amount: Number(this.supremCashThicketForm.value.amount),
  //         accountId: account,
  //         currencyISOCode: this.supremCashThicketForm.value.currency,
  //         extraParams: {
  //           document: form.cpfOrCNPJ,
  // 		      state: form.StateAbbreviationId,
  // 		      file: this.uplodedFileBase64,
  //         },
  //       };
  //       break;
  //   }

  //   console.log('form', data);
  //   this.depositTypeService.submitDeposit(data)
  //   .subscribe((res) => {
  //     if (res.status === 1) {
  //       console.log(res);
  //     }
  //   });

  // }

  public amountFormater(amount) {
    const isDecimal = amount - Math.floor(amount) !== 0;
    if (isDecimal) {
      amount = Number(amount).toFixed(2);
    } else {
      amount = amount + ".00";
    }
    return amount;
  }

  public generateMonthsYears() {
    const thisYear = new Date().getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.years.unshift(thisYear + i);
    }

    for (let j = 1; j <= 12; j++) {
      if (j < 10) {
        this.months.push(`0${j}`);
      } else {
        this.months.push(String(j));
      }
    }
  }

  set(ev) {
    this.form.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.form.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  // onAccountChange(ev){
  //   this.getDepositAmount(ev.target.value)
  //   this.getCurrencyRate()
  // }

  // getDepositAmount(clientAccountId){
  //   this.depositTypeService
  //   .getDepositAmount(clientAccountId)
  //   .subscribe((result) => {
  //     if (!result.data.madeDeposit) {
  //       this.currentMinDeposit = result.data.minDepositAmount;
  //       this.supremCashBoletoForm.controls.deposit_amount.setValidators([
  //         Validators.required,
  //         Validators.min(this.currentMinDeposit),
  //       ]);
  //       this.supremTEDForm.controls.deposit_amount.setValidators([
  //         Validators.required,
  //         Validators.min(this.currentMinDeposit),
  //       ]);
  //       this.supremPixForm.controls.deposit_amount.setValidators([
  //         Validators.required,
  //         Validators.min(this.currentMinDeposit),
  //       ]);
  //     }
  //   });
  // }

  getCurrencyRate() {
    this.form.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.form.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });
           const payload = {
             clientAccountId: account,
             depositCurrency: currency,
           };
               this.depositTypeService
                 .getMinDepositAmountByCurrency(payload)
                 .subscribe((result) => {
                   if (!result.data.madeDeposit) {
                     this.currentMinDeposit = result.data.minDepositAmount;
                     this.supremCashBoletoForm.controls.deposit_amount.setValidators(
                       [
                         Validators.required,
                         Validators.min(this.currentMinDeposit),
                       ]
                     );
                     this.supremTEDForm.controls.deposit_amount.setValidators([
                       Validators.required,
                       Validators.min(this.currentMinDeposit),
                     ]);
                     this.supremPixForm.controls.deposit_amount.setValidators([
                       Validators.required,
                       Validators.min(this.currentMinDeposit),
                     ]);
                   }
                 });
    }
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.form.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  getBoletoPredefinedList() {
    this.depositTypeService.getBoletoPredefineList().subscribe((res) => {
      if (res.status === 1) {
        this.boletoPredefinedList = res.data;
      }
    });
  }

  getPersonInfo() {
    this.registrationService
    .getPersonalInfo()
    .subscribe(res => {
      if(res.status === 1){
      this.clientBasicInfo = res.data;
      }
    });
  }

  getCountryShortList(){
    this.appropriatenessTestService.getCountryBlockedShortList()
    .subscribe((result)=>{
      if(result.status === 1){
        this.countryShortList = result.data
      }
    })
  }

  hasError(name: string, touched?: boolean): boolean {
    return FormService.hasError(name, this.form, touched ? touched : null);
  }

  ngOnDestroy() {}
}
