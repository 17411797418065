import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { AlertItem } from '../models';
import { AlertType } from '../enums';

@Injectable({ providedIn: 'root' })
export class AlertService {

  public alerts: Subject<AlertItem> = new Subject();
  showFullError = false;

  constructor() { }

  error(text: string, title: string = 'Error') {
    this.alerts.next(new AlertItem(title, text));
  }

  success(text: string, title: string = 'Info') {
    this.alerts.next(new AlertItem(title, text, AlertType.Success));
  }

  info(text: string, title: string = 'Info') {
    this.alerts.next(new AlertItem(title, text, AlertType.Info));
  }

}
