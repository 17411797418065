import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { LanguageService } from '../../../../languages/services/language/language.service';
import { PageService } from '../../../../page.service';
import { Currency, Account } from '../../../../interfaces';

@Component({
  selector: 'app-sofort',
  templateUrl: './sofort.component.html',
  styleUrls: ['./sofort.component.scss']
})
export class SofortComponent implements OnInit, OnDestroy {

  sofortForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: object;
  chooseChannelsVisible = false;
  channels: any;
  hasChannelError: string = null;
  currentMinDeposit: number;
  openNewAccount: boolean = false;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  isChina = false;

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private languageService: LanguageService,
    private pageService: PageService,
  ) { }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");

    this.clientId = this.authService.getAuthClientId();

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe((currencies: Currency[]) => {
      this.currencies = currencies;

      this.changeAccount(this.activeAccountNumber);
    });

    this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
      this.currentType = currentType;
      if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
        this.currentMinDeposit = this.currentType['minDeposit'];
      } else { // TODO: remove this in future
        this.currentMinDeposit = 50;

        this.updateValidation();
      }
    });

    this.sofortForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      deposit_amount: ['', [Validators.required]],
    });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
      if (accounts.length > 0) {
        this.accounts = accounts;
        this.openNewAccount = false;
      } else {
        const value = 'Open a new account';
        this.sofortForm.get('account').setValue(value);
        this.openNewAccount = true;
      }
    });

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
      if (activeAccount) {
        this.activeAccountNumber = activeAccount['account'];
        if (localStorage.getItem('tmp_account')) {
          this.sofortForm.get('account').setValue(localStorage.getItem('tmp_account'));
          localStorage.removeItem(('tmp_account'));
        } else {
          this.sofortForm.get('account').setValue(this.activeAccountNumber);
        }

        this.changeAccount(this.activeAccountNumber);
      }
    });


  }

  submit(): void {
    if (this.sofortForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      this.submitForm();
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
    }
  }

  submitForm() {
    // TODO: Implement Ronen's endpoint here ...
    /*this.depositTypeService.getDotPayChannels().subscribe(res => {
      this.channels = res;
    });
    const depositInfo = {
      id: 0,
      clientId: this.clientId,
      depositMethod: 0,
      account: account,
      currencyId: this.sofortForm.value.currency,
      amount: Number(this.sofortForm.value.deposit_amount),
      sofort: {
        amount: this.sofortForm.value.deposit_amount,
        currency: cur,
        // TODO: Implement as Ronen has done his part
      },
      depositStatus: 0,
      done: true
    };

    this.depositTypeService.submitDepositInfo(depositInfo).subscribe(res => {
      this.pageService.changeLoadingStatus(false);
      if (res['response']['redirect_simplified_url']) {
        window.location.href = res['response']['redirect_simplified_url'];
      } else {
        this.hasChannelError = res['response'];
      }
    });*/
  }

  get controls() { return this.sofortForm.controls; }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(depositAmount, this.sofortForm.get('deposit_amount'));
    this.validDepositAmount = true;
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {

      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.sofortForm.get('currency').setValue(activeAccountCurrency.id);
      }

    }
  }

  updateValidation() {
    this.sofortForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
    this.sofortForm.controls.deposit_amount.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.currentTypeSubscription.unsubscribe();
    this.currentTypeSubscription.complete();

    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();

    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();

    this.currenciesSubscription.unsubscribe();
    this.currenciesSubscription.complete();
  }

}
