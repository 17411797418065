import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../../auth/services/registration/registration.service';
import { AccountService } from '../services/account/account.service';
import { LanguageService } from '../../languages/services/language/language.service';
import { AuthService } from '../../auth/services/auth/auth.service';
import { BrandIdEnum } from 'src/app/brand.enum';

@Component({
  selector: 'app-account-types',
  templateUrl: './account-types.component.html',
  // styleUrls: ['./account-types.component.scss']
  styleUrls: ['../info/account-type/account-type.component.scss']
})
export class AccountTypesComponent implements OnInit, OnDestroy {

  type: string;
  accountTypes: object;
  accountTypesOriginal: object;
  currentLanguage: string;
  defaultText: string;
  clientId: number;
  descriptionList: any[];
  private chosenLanguageSubscription;
  public localBrandId = `${localStorage.getItem('localBrandId')}`;
  public isRevolution: boolean;
  public isOtb: boolean;
  public isTradeCenter = false;

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private accountService: AccountService,
    private authService: AuthService,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.clientId = this.authService.getAuthClientId();
    this.currentLanguage = this.languageService.getLanguage();

    this.accountService.getBrandAccountTypes().subscribe((accountTypes) => {
      if (accountTypes.status === 1) {
      accountTypes.data.sort((a, b) => {
        if (a['minDeposit'] < b['minDeposit']) {
          return -1;
        }

        if (a['minDeposit'] > b['minDeposit']) {
          return 1;
        }
        return 0;
      });

      // console.log('a', accountTypes.data[0]['description']);
      // accountTypes.data.forEach((x, i) => {
      //   x['description'] = x['description'].replace(',', '\n')
      // })
      // console.log('b', accountTypes.data[0]['description']);

      this.accountTypes = accountTypes.data;

    if (+this.localBrandId === BrandIdEnum.RevolutionFX) {
      this.isRevolution = true;
    } else if (+this.localBrandId === BrandIdEnum.OTB) {
      this.isOtb = true;
    } else if (+this.localBrandId === BrandIdEnum.tradeCenter) {
      this.isTradeCenter = true;
    }

  // this.setdescriptionList()

      // this.chosenLanguageSubscription = this.languageService.chosenLanguageObservable.subscribe(language => {
      //   const accountTypesClone = JSON.parse(JSON.stringify(this.accountTypesOriginal));

      //   accountTypesClone.forEach((accountType: [], i) => {
      //     if (accountType['comments'].length > 0) {
      //       accountType['comments'].forEach((comment: [], j) => {
      //         if ('string' !== typeof accountTypesClone[i]['comments']) {
      //           let text;
      //           if (accountTypesClone[i]['comments'][j]['code'] === language) {
      //             text = accountTypesClone[i]['comments'][j]['text'].toString();
      //           } else if (accountTypesClone[i]['comments'][j]['code'] === 'EN') {
      //             this.defaultText = accountTypesClone[i]['comments'][j]['text'].toString();
      //           }

      //           if ('undefined' !== typeof text) {
      //             accountTypesClone[i]['comments'] = text;
      //           } else if (accountTypesClone[i]['comments'].length === Number(j) + 1) {
      //             if ('undefined' !== typeof this.defaultText) {
      //               accountTypesClone[i]['comments'] = this.defaultText;
      //             } else {
      //               accountTypesClone[i]['comments'] = '';
      //             }
      //             this.defaultText = '';
      //           }
      //         }
      //       });
      //     } else {
      //       accountTypesClone[i]['comments'] = '';
      //     }
      //   });

      //   this.accountTypes = accountTypesClone;
      // });
    }
    });
  }

  // setdescriptionList() {
  //   const description = [this.accountTypes[0]['description']]
  //   this.descriptionList = (String(description).split(','));
  // }

  hasCardGold(ev): boolean {

    return String(ev).toLowerCase().includes('gold')
  }

  hasCardDiamond(ev): boolean {
    return String(ev).toLowerCase().includes('diamond')
  }

  ngOnDestroy() {
    // this.chosenLanguageSubscription.unsubscribe();
    // this.chosenLanguageSubscription.complete();
  }

}
