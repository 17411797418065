import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '../services/account/account.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Account } from '../../interfaces';
import { AppropriatenessTestService } from '../../../app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service'
import { AuthService } from '../../../app/auth/services/auth/auth.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {
  faTimes = faTimes;
  isHiddenBackground = false;
  accountContentIsClicked = false;
  accounts: Account[] = [];
  account: Account;
  private accountsSubscription;
  testTermsAccepted: boolean = false;
  clientId: number;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private appropriatenessTestService: AppropriatenessTestService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.accountsSubscription = this.accountService.accountsObservable.subscribe(accounts => {
      this.accounts = accounts;
    });

    this.clientId = this.authService.getAuthClientId();
    // this.appropriatenessTestService.getClientTestScore(this.clientId).subscribe(score => {
    //   this.appropriatenessTestService.getTestTerms(this.clientId).subscribe(testTerms => {
    //     if(
    //       (score > 20 && testTerms['testTerms'] == true)
    //       || score > 80 // TODO  WHY DO WE USE LEVELS
    //     ) { // TODO USELESS
    //       this.testTermsAccepted = true;
    //     } else {
    //       this.testTermsAccepted = false;
    //     }
    //   })
    // })
  }

  openDetailsAccount(i: number): void {
    this.account = this.accounts[i];
    this.accountContentIsClicked = true;
    this.isHiddenBackground = true;
  }

  closeDetailsAccount(): void {
    this.accountContentIsClicked = false;
    this.isHiddenBackground = false;
  }

  closeOpenedDetailsAccount(): void {
    this.closeDetailsAccount();
  }

  increaseLeverage(account: string): void {
    localStorage.setItem('tmp_account', account);
    this.router.navigate(['/deposit-type']);
  }

  ngOnDestroy() {
    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();
  }
}
