import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DepositTypeService } from "../services/deposit-type.service";
import { AuthService } from "../../../../auth/services/auth/auth.service";
import { AccountService } from "../../../services/account/account.service";
import { RegistrationService } from "../../../../auth/services/registration/registration.service";
import { BrandService } from "../../../../auth/services/brand/brand.service";
import { AccountTypeService } from "../../account-type/services/account-type.service";
import { Router } from "@angular/router";
import { ValidationService } from "../../../../services/validation/validation.service";
import { PageService } from "../../../../page.service";
import { environment } from "../../../../../environments/environment";
import { Currency, Account } from "../../../../interfaces";
import { AppropriatenessTestService } from "../../../../../app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: "app-skrill",
  templateUrl: "./skrill.component.html",
  styleUrls: ["./skrill.component.scss"],
})
export class SkrillComponent implements OnInit, OnDestroy {
  skrillForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validBankName = true;
  validDepositAmount = true;
  validSkrillEmail = true;
  clientId: number;
  accounts: Account[];
  currencies: Currency[];
  activeAccountNumber: number;
  activeAccount: any;
  activeCurrency: string;
  pay_to_email: string;
  selectedBrandId: number;
  currentType: object;
  newAccount: number;
  currentMinDeposit: number;
  apiURL = environment.apiURL;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount: boolean = false;
  errorMessage: string;
  isChina = false;
  exChange = 1;

  id = "6";

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private brandService: BrandService,
    private accountTypeService: AccountTypeService,
    private router: Router,
    private validationService: ValidationService,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.fireFormValidations();

    this.clientId = this.authService.getAuthClientId();

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });
    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
    //   if (accounts.length > 0) {
    //     this.accounts = accounts;
    //     this.openNewAccount = false;
    //   } else {
    //     const value = 'Open a new account';
    //     this.skrillForm.get('account').setValue(value);
    //     this.openNewAccount = true;
    //   }
    // });
    this.activeAccountSubscription =
      this.accountService.activeAccountObservable.subscribe(
        (activeAccount: Account) => {
          this.activeAccount = activeAccount;
          if (activeAccount) {
            this.activeAccountNumber = activeAccount["account"];
            if (localStorage.getItem("tmp_account")) {
              this.skrillForm
                .get("account")
                .setValue(localStorage.getItem("tmp_account"));
              localStorage.removeItem("tmp_account");
            } else {
              this.skrillForm.get("account").setValue(this.activeAccountNumber);
            }

            this.changeAccount(this.activeAccountNumber);

            // if (this.activeAccount) {
            //   this.activeCurrency = this.activeAccount.currency;
            //   this.pay_to_email = this.brandService.getBrandSetting(this.selectedBrandId,
            //     'skrill_pay_to_email_' + this.activeCurrency);
            // }
          }
        }
      );

    this.activeAccount = this.accountService.getActiveAccount();

    if (this.activeAccount) {
      this.activeCurrency = this.activeAccount.currency;
    }
  }

  set(ev) {
    this.skrillForm.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.skrillForm.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.skrillForm.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  // onAccountChange(ev){
  //   this.getDepositAmount(ev.target.value)
  //   this.getCurrencyRate()
  // }

  // getDepositAmount(clientAccountId){
  //   this.depositTypeService
  //   .getDepositAmount(clientAccountId)
  //   .subscribe((result) => {
  //     if (!result.data.madeDeposit) {
  //       this.currentMinDeposit = result.data.minDepositAmount;
  //       this.skrillForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
  //     }
  //   });
  // }

  getCurrencyRate() {
    this.skrillForm.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.skrillForm.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });
      const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };

      this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.skrillForm.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }

  submit(): void {
    if (this.skrillForm.valid) {
      // this.pageService.changeLoadingStatus(true);

      if (this.skrillForm.controls.account.value === "Open a new account") {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.skrillForm.value.currency,
          accountType: this.currentType["id"],
          leverage: 30,
        };

        // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
        //   this.newAccount = res.account;
        //   this.accountService.updateAccounts(res.account);

        //   this.submitDeposit();
        // });
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls["account"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validDepositAmount = this.controls["deposit_amount"].valid;
      this.validSkrillEmail = this.controls["pay_from_email"].valid;
    }
  }

  get controls() {
    return this.skrillForm.controls;
  }

  getReturnUrl(): string {
    return window.location.origin;
  }

  submitDeposit() {
    let account = this.skrillForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      depositMethodId: this.id,
      depositMethodSubtypeId: null,
      amount: Number(this.skrillForm.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.skrillForm.value.currency,
      ExtraParams: {
        Email: this.skrillForm.value.skrillEmail,
      },
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        window.location.href = res.data.extraParams;
      } else {
        this.errorMessage = res.message;
      }
    });

    // this.accountService.submitDepositInfo(data).subscribe(res => {
    //   this.pageService.changeLoadingStatus(false);

    //   window.location.href = res['response'].redirectUrl;
    // });
  }

  fireFormValidations() {
    this.skrillForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      skrillEmail: ["", [Validators.required]],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
      deposit_amount_usd: [0],
    });
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.skrillForm.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }

  updateValidation() {
    this.skrillForm.controls.deposit_amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit),
    ]);
    this.skrillForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(
          (currency: Currency) => {
            return currency["name"] === selectedAccount["currency"];
          }
        );
        this.skrillForm.get("currency").setValue(activeAccountCurrency["id"]);
      }
    }
  }

  ngOnDestroy() {}
}
