import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { RegistrationService } from '../services/registration/registration.service';
import { ValidationService } from '../../services/validation/validation.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { BrandService } from '../services/brand/brand.service';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  resetForm: FormGroup;
  validNewPassword = true;
  validConfirmPassword = true;
  // currentWebsitesBrandId: number;
  passwordShowObject = {
    inputType: 'password',
    icon: faEye,
  };
  passwordHideObject = {
    inputType: 'text',
    icon: faEyeSlash,
  };
  passwordShowHideObject = this.passwordShowObject;
  confirmPasswordShowHideObject = this.passwordShowObject;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private validationService: ValidationService,
    private toasterService: ToasterService,
    private router: Router,
    private brandService: BrandService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      new_password: ['', this.validationService.password()],
      confirm_password: ['', this.checkConfirm()],
    });

    // this.currentWebsitesBrandId = this.brandService.getCurrentWebsitesBrandId();
  }

  submit(): void {
    if (this.resetForm.valid) {
      const data = {
        email: localStorage.getItem('email'),
        oldPassword: localStorage.getItem('code'),
        password: this.resetForm.value.new_password,
        // brandId: this.currentWebsitesBrandId,
      };

      // this.registrationService.changePassword(data).subscribe(res => {
      //   if (res['status'] === 0) {
      //     localStorage.removeItem('email');
      //     localStorage.removeItem('code');

      //     let successMessage = '';
      //     let resetPassword = '';

      //     this.translateService.get('MESSAGES.SUCCESS.RESET_PASSWORD').subscribe(text => {
      //       successMessage = text;
      //     });

      //     this.translateService.get('RESET_PASSWORD.TITLE').subscribe(text => {
      //       resetPassword = text;
      //     });

      //     this.toasterService.pop('success', resetPassword, successMessage);
      //     this.router.navigate(['/']);
      //   }
      // });
    } else {
      this.validNewPassword = this.controls['new_password'].valid;
      this.validConfirmPassword = this.controls['confirm_password'].valid;
    }
  }

  get controls() { return this.resetForm.controls; }

  checkConfirm(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (this.resetForm && control.value === this.resetForm.controls.new_password.value) {
        return null;
      } else {
        return {'notSame': true};
      }
    };
  }

  showHidePassword() {
    if (this.passwordShowHideObject.inputType === 'text') {
      this.passwordShowHideObject = this.passwordShowObject;
    } else {
      this.passwordShowHideObject = this.passwordHideObject;
    }
  }

  showHideConfirmPassword() {
    if (this.confirmPasswordShowHideObject.inputType === 'text') {
      this.confirmPasswordShowHideObject = this.passwordShowObject;
    } else {
      this.confirmPasswordShowHideObject = this.passwordHideObject;
    }
  }

}
