import { Injectable } from '@angular/core';
// import { HttpClientService } from '../http-client.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  prefix = 'Notifications';

  constructor(
    // private http: HttpClientService,
  ) { }

  // public clientFailedInStep(data) {
  //   return this.http.post(this.prefix + '/ClientFailedInStep?id=' + data['clientId'] + '&step=' + data['step'], []);
  // }

  // public appropriatenessTestDropped(data) {
  //   return this.http.post(this.prefix + '/AppropriatenessTestDropped?id=' + data['clientId'], []);
  // }

  // public applicationForProfessionalAccountDropped(data) {
  //   return this.http.post(this.prefix + '/ApplicationForProfessionalAccountDropped?id=' + data['clientId'] + '&step=' + data['step'], []);
  // }

}
