import { BrandService } from "src/app/auth/services/brand/brand.service";
import { PageService } from "src/app/page.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  faHome,
  faTerminal,
  faWallet,
  faUserCheck,
  faUserCircle,
  faDownload,
  faCreditCard,
  faHandHoldingUsd,
  faTimes,
  faGlobeAfrica,
  faBars,
  faPowerOff,
  faExchangeAlt,
  faComments,
  faEnvelope,
  faPhone,
  faList,
  faSync,
  faFileInvoiceDollar,
  faUsers,
  faPeopleCarry,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../auth/services/auth/auth.service";
import { AccountService } from "./services/account/account.service";
import { LanguageService } from "../languages/services/language/language.service";
import { environment } from "../../environments/environment";
import { UserIdleService } from "angular-user-idle";
import { Account, PersonalInfoModel } from "../interfaces";
import { AccountTypeService } from "./info/account-type/services/account-type.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { RegistrationService } from "../auth/services/registration/registration.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppropriatenessTestService } from "../../../src/app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { BrandIdEnum } from "../brand.enum";
import { LegalDocumentationService } from "./services/legal-documentation/legal-documentation.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public isOtb: boolean;
  public isEu: boolean;
  public isRevolution: boolean;
  public TuDoFx: boolean;
  public isEmerald: boolean;
  public kado: boolean;
  public greenLine: boolean;
  public tradeCenter: boolean;
  public tradeMarkets: boolean;
  public phenoFx: boolean;
  public micMarket: boolean;
  public isInternational = false;
  public sefx: boolean;
  southAfrica = false;
  growProMarkets = false;

  public localBrandId;

  isEditProfileCollapsed = true;
  isSocialTradingCollapsed = true;
  isEditProfilePrefix = true;
  isAccountAndWalletsCollapsed = true;
  asideNavOpened = false;
  openedAccountsDropDown = false;
  faHome = faHome;
  faTerminal = faTerminal;
  faWallet = faWallet;
  faUserCheck = faUserCheck;
  faUserCircle = faUserCircle;
  faDownload = faDownload;
  faCreditCard = faCreditCard;
  faHandHoldingUsd = faHandHoldingUsd;
  faTimes = faTimes;
  faGlobeAfrica = faGlobeAfrica;
  faBars = faBars;
  faPowerOff = faPowerOff;
  faExchangeAlt = faExchangeAlt;
  faComments = faComments;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faLinkedinIn = faLinkedinIn;
  faTwitter = faTwitter;
  faList = faList;
  faSync = faSync;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faUsers = faUsers;
  faPeopleCarry = faPeopleCarry;
  faHeadphones = faHeadphones;
  selectedLang: string;
  accounts: Account[];
  activeAccount: any;
  activeAccountBalance: number;
  languagesOpened = false;
  showLiveChat = false;
  showViewAllLink = false;
  isHiddenBackground: boolean;
  logo: string;
  mainWebsiteUrl: string;
  openPhoneWidget = false;
  // showContactWidgets = true;
  src;
  isAppTestEnabled = false;
  isTestTermsAccepted = false;
  private accountsSubscription;
  private activeAccountSubscription;
  private languageDropDownOpenedSubscription;
  private chosenLanguageSubscription;
  clientId: number;
  // isSwissCapital: boolean = false;
  // TODO: get brand settings form API call
  brandSettings = {
    phoneNumber: "+357 2 528 1634",
    socialLinks: {
      facebook: "https://www.facebook.com/FXGlobetrading/",
      instagram: "https://www.instagram.com/fx_globe/",
      linkedin: "https://www.linkedin.com/company/fxglobe/",
      twitter: "https://twitter.com/FXGlobeOfficial",
    },
  };
  // firstTime = true;
  // message = "";
  // isGlobal: boolean;
  // isTest: boolean;
  hostname: string;
  newActiveAccountData = {};

  brandShortList = [];
  public showWebTerminal: boolean;
  public clientBasicInfo: PersonalInfoModel;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,
    private languageService: LanguageService,
    private userIdle: UserIdleService,
    private router: Router,
    private registrationService: RegistrationService,
    public pageService: PageService,
    private modalService: NgbModal,
    private appropriatenessTestService: AppropriatenessTestService,
    private brandService: BrandService,
    private legalDocumentationService: LegalDocumentationService,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe((event) => {
      if (this.route.snapshot.queryParams["webterminal"]) {
        this.showWebTerminal = true;
      } else {
        this.showWebTerminal = false;
      }
      if (
        event instanceof NavigationEnd &&
        event.url &&
        event.url.split("/")[1] === "profile"
      ) {
        this.isEditProfilePrefix = true;
      } else {
        this.isEditProfilePrefix = false;
      }
    });

    this.hostname = window.location.hostname;

    // TODO: remove this in future ?
    // if (this.hostname === "localhost") {
    //   this.hostname = "portal.mgflex.com";
    // }

    // this.registrationService.getBrands(this.hostname).subscribe(
    //   (brands) => {
    //     if ("undefined" !== typeof brands[0]) {
    //       const mainBrand = brands[0];

    //       if (!mainBrand.enabled) {
    //        // this.authService.logout();
    //       }
    //     }
    //   },
    //   () => {
    //     //this.authService.logout();
    //   }
    // );
  }

  // openNotice() {
  //   if (this.firstTime && this.isGlobal) {
  //     const modalRef = this.modalService.open(
  //       NoticeUponMakingADepositComponent
  //     );
  //     modalRef.componentInstance.clientId = this.clientId;
  //     modalRef.componentInstance.message = this.message;
  //     modalRef.result.then((result) => {
  //       this.firstTime = result;
  //     });
  //   }
  // }

  ngOnInit() {
    this.logo = environment.logo;

    this.hostname = window.location.hostname;

    this.mainWebsiteUrl =
      "//" +
      this.hostname.substring(
        this.hostname.lastIndexOf(".", this.hostname.lastIndexOf(".") - 1) + 1
      );

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
        this.isEu = true;
        this.mainWebsiteUrl = "https://www.fxglobe.com/en/";
        break;
      case BrandIdEnum.International:
        this.mainWebsiteUrl = "https://www.fxglobe.io/";
        this.isInternational = true;
        break;
      case BrandIdEnum.southAfrica:
        this.mainWebsiteUrl = "https://www.fxglobe.net/";
        this.southAfrica = true;
        break;
      case BrandIdEnum.OTB:
        this.isOtb = true;
        this.mainWebsiteUrl = "https://timestocks.com/";
        break;
      case BrandIdEnum.emerald:
        this.isEmerald = true;
        this.mainWebsiteUrl = "https://emerald-markets.com/";
        break;
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
        break;
      case BrandIdEnum.TuDoFx:
        this.TuDoFx = true;
        break;
      case BrandIdEnum.kado:
        this.kado = true;
        break;
      case BrandIdEnum.greenLine:
        this.greenLine = true;
        break;
      case BrandIdEnum.tradeCenter:
        this.tradeCenter = true;
        break;
      case BrandIdEnum.tradeMarkets:
        this.tradeMarkets = true;
        break;
      case BrandIdEnum.phenoFx:
        this.phenoFx = true;
        break;
      case BrandIdEnum.micMarket:
        this.micMarket = true;
        break;
      case BrandIdEnum.sefx:
        this.sefx = true;
        this.mainWebsiteUrl = "https://7seasfx.com/";
        break;
      case BrandIdEnum.growProMarkets:
        this.growProMarkets = true;
        break;
      default:
        break;
    }

    // if (this.hostname === "portal.swisscapital.fm") {
    //   this.isSwissCapital = true;
    // }
    // if (this.hostname === "portal.otb-trading.com") {
    //   this.isOtb = true;
    // }
    this.clientId = this.authService.getAuthClientId();

    // this.registrationService
    //   .getTermsAndCondFlag(this.clientId)
    //   .subscribe((result) => {
    //     this.firstTime = !result.termsAndCondFlag;
    //     this.message = result.message;
    //   });

    // this.isGlobal = this.registrationService.isGlobal();
    // this.isTest = this.registrationService.isTestEnvironment();

    this.accountService
      .getClientAccounts(this.clientId)
      .subscribe((accounts) => {
        if (accounts.status === 1) {
          this.accounts = accounts.data;
          this.accountService.setAccounts(this.accounts);

          let activeAccount;
          const storageData = localStorage.getItem("activeAccount");

          if (storageData) {
            activeAccount = JSON.parse(storageData);

            // TODO: Remove this in future
          } else {
            activeAccount = this.accounts[0];
          }

          this.activeAccount = activeAccount;

          // Call to get balance of active account

          this.accountTypeService.getAccountType(activeAccount["account"]);
          this.accountService.setActiveAccount(this.activeAccount);

          this.accountService
            .getClientAccountById(
              this.clientId,
              this.activeAccount.id,
              this.activeAccount.isDemoAccount
            )
            .subscribe((res) => {
              if (res.status === 1) {
                this.newActiveAccountData = res.data;
              }
            });
        }
      });

    this.registrationService.getPersonalInfo().subscribe((res) => {
      this.clientBasicInfo = res.data;
    });

    this.accountsSubscription =
      this.accountService.accountsObservable.subscribe(
        (accounts: Account[]) => {
          this.accounts = accounts;

          // this.appropriatenessTestService
          //   .IsClientAppropraitnessTestEnabled(this.clientId)
          //   .subscribe((enabled) => {
          //     this.isAppTestEnabled = Boolean(enabled);
          //   });

          // this.appropriatenessTestService
          //   .getTestTerms(this.clientId)
          //   .subscribe((terms) => {
          //     this.isTestTermsAccepted = Boolean(terms);
          //   });
          if (accounts.length > 5) {
            this.showViewAllLink = true;
          } else {
            this.showViewAllLink = false;
          }
          if (accounts.length === 1) {
            this.activeAccount = accounts[0];
            this.accountService.setActiveAccount(this.activeAccount);
          }
        }
      );

    this.activeAccountSubscription =
      this.accountService.activeAccountObservable.subscribe((activeAccount) => {
        this.activeAccount = activeAccount;
      });

    this.languageDropDownOpenedSubscription =
      this.languageService.languageDropDownOpenedObservable.subscribe(
        (languageDropDownOpened) => {
          this.languagesOpened = languageDropDownOpened;
        }
      );

    this.chosenLanguageSubscription =
      this.languageService.chosenLanguageObservable.subscribe((language) => {
        this.selectedLang = language;
      });

    // TODO: remove this in future (just for testing)
    if (window.location.hostname !== "localhost") {
      // Start watching for user inactivity.
      this.userIdle.startWatching();

      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe((count) => {
        localStorage.setItem("session_expired", "true");
        this.authService.logout();
      });

      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe();
    }

    // if (window.location.hostname === "portal.fxcm-globe.com") {
    //   this.showContactWidgets = false;
    // }

    this.accountService.activeAccountBalanceChangedObservable.subscribe(
      (status: boolean) => {
        if (status) {
          this.activeAccountBalance = undefined;
          // Call to get balance of active account
          // this.accountService
          //   .getBalance(this.clientId, this.activeAccount.id)
          //   .subscribe((accountBalance) => {
          //     this.activeAccountBalance = accountBalance.balance;
          //   });
        }
      }
    );

    this.pageService.brandListSubject.subscribe((res) => {
      this.brandShortList = res;
    });

    this.getMT4Link();

    this.localBrandId = localStorage.getItem("localBrandId");
  }

  getMT4Link() {
    this.legalDocumentationService.getMT4Link().subscribe((res) => {
      if (res.status === 1) {
        this.src = res.data;
      }
    });
  }

  openWebterminal() {
    let src = this.src;

    if (this.src && this.selectedLang) {
      if (String(src).includes("https://trade.mql5.com")) {
        // Language
        if (this.selectedLang) {
          src += "&lang=" + this.selectedLang.toLowerCase();
        }

        // TODO: check active Account after log out and log in to new account
        if (this.activeAccount) {
          const login = this.activeAccount["accountNumber"];
          src += "&login=" + login;
        }
      }
    }

    window.open(src, "_blank");
  }

  checkWebTerminal() {}

  changeBrand(value) {
    this.pageService.brandShortlist.next(value);
    localStorage.setItem("localBrandId", value);
    window.location.reload();
  }

  navigation() {
    const lang = localStorage.getItem("lang").toLocaleLowerCase();
    if (this.hostname !== "portal.otb-trading.com") {
      this.router.navigateByUrl("/");
    } else {
      window.location.href = `${this.mainWebsiteUrl}/${lang}/`;
    }
  }

  closeOpenedDropDown(event: Event = null): void {
    this.openedAccountsDropDown = false;
    if (event) {
      event.stopPropagation();
    }
  }

  openLeftMenu(): void {
    this.asideNavOpened = true;
  }

  closeLeftMenu(): void {
    this.asideNavOpened = false;
  }

  toggleLeftMenu(): void {
    this.asideNavOpened = !this.asideNavOpened;
  }

  logout(): void {
    this.authService.logout();
  }

  changeActiveAccount(account, event: Event = null) {
    this.activeAccount = account;
    this.activeAccountBalance = undefined;

    // TODO: call to get balance of active account
    this.accountService
      .getClientAccountById(this.clientId, account.id, account.isDemoAccount)
      .subscribe((res) => {
        if (res.status === 1) {
          this.newActiveAccountData = res.data;
        }
      });

    this.accountTypeService.getAccountType(account);
    this.openedAccountsDropDown = false;
    this.accountService.setActiveAccount(account);
    if (event) {
      event.stopPropagation();
    }
  }

  openLanguages() {
    this.languagesOpened = true;
  }

  getLanguageDirection() {
    const lang = this.languageService.getLanguage();
    switch (lang) {
      case "AR":
        return "rtl";
      default:
        return "ltr";
    }
  }

  ngOnDestroy() {
    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();

    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();

    this.languageDropDownOpenedSubscription.unsubscribe();
    this.languageDropDownOpenedSubscription.complete();

    this.chosenLanguageSubscription.unsubscribe();
    this.chosenLanguageSubscription.complete();
  }
}
