import { Component, OnInit, OnDestroy } from "@angular/core";
import { faGlobeAfrica } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { RegistrationService } from "./services/registration/registration.service";
import { IpStackService } from "../services/ipstack/ipstack.service";
import { BrandService } from "./services/brand/brand.service";
import { LocationService } from "./services/location/location.service";
import { LanguageService } from "../languages/services/language/language.service";
import { environment } from "../../environments/environment";
import { AuthService } from "./services/auth/auth.service";
import { Brand } from "../interfaces";
import { Router } from "@angular/router";
import { PageService } from "../page.service";
import { BrandIdEnum } from "../brand.enum";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit, OnDestroy {
  faGlobeAfrica = faGlobeAfrica;
  selectedLang: string;
  brands: Brand[];
  location: object;
  currentBrandId: number;
  defaultBrand: object;
  languagesOpened = false;
  logo: string;
  micMarket: boolean;
  hideBrands: boolean;
  southAfrica = false;

  mainWebsiteUrl: string;
  private hideBrandsSubscription;
  private chosenBrandSubscription;
  private languageDropDownOpenedSubscription;
  private chosenLanguageSubscription;
  brandIsEnabled: boolean;
  brandIsEnabledDetected = false;
  hideBrandSwitcher = true;
  host: string;
  // TODO: in future get this from API for each of brands
  brandEmailAddress = "hello@applebrie.com";

  brandShortList = [];

  localBrandId = `${localStorage.getItem("localBrandId")}`;

  constructor(
    public translate: TranslateService,
    private registrationService: RegistrationService,
    private ipStackService: IpStackService,
    private brandService: BrandService,
    private locationService: LocationService,
    private languageService: LanguageService,
    private authService: AuthService,
    private router: Router,
    public pageService: PageService
  ) {}

  ngOnInit() {
    this.host = window.location.hostname;

    if (this.host) {
      this.mainWebsiteUrl =
        "//" +
        this.host.substring(
          this.host.lastIndexOf(".", this.host.lastIndexOf(".") - 1) + 1
        );
    }

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
        this.mainWebsiteUrl = "https://www.fxglobe.com/en/";
        break;
      case BrandIdEnum.International:
        this.mainWebsiteUrl = "https://www.fxglobe.io/";
        break;
      case BrandIdEnum.southAfrica:
        this.mainWebsiteUrl = "https://fxglobe.net/";
        this.southAfrica = true;
        break;
      case BrandIdEnum.OTB:
        this.mainWebsiteUrl = "http://www.timestocks.com/";
        break;
      case BrandIdEnum.TuDoFx:
        this.mainWebsiteUrl = "https://tudofx.com/";
        break;
      case BrandIdEnum.greenLine:
        this.mainWebsiteUrl = "http://www.greenlinepro.com/";
        break;
      case BrandIdEnum.tradeCenter:
        this.mainWebsiteUrl = "https://invest-center.com/";
        break;
      case BrandIdEnum.tradeMarkets:
        this.mainWebsiteUrl = "https://invest-center.com/";
        break;
      case BrandIdEnum.phenoFx:
        // this.mainWebsiteUrl = 'https://www.phenoFx.com/';
        break;
      case BrandIdEnum.micMarket:
        this.mainWebsiteUrl = "http://mic-market.com/";
        this.micMarket = true;
        break;
      case BrandIdEnum.sefx:
        this.southAfrica = false;
        this.mainWebsiteUrl = "https://7seasfx.com/";
        break;
      case BrandIdEnum.growProMarkets:
        // this.mainWebsiteUrl = "http://www.growProMarkets.com/";
        break;
    }

    this.hideBrandsSubscription =
      this.authService.hideBrandsObservable.subscribe((hideBrands) => {
        this.hideBrands = hideBrands;
      });

    this.logo = environment.logo;
    let hostname = window.location.hostname;
    // TODO: remove this in future ?
    if (hostname === "localhost") {
      hostname = "portal.mgflex.com";
    }

    // this.registrationService.getBrands(hostname).subscribe(brands => {

    // }, () => {
    //   this.brandIsEnabledDetected = false;
    // });

    // this.fixedBrand()

    this.chosenBrandSubscription =
      this.brandService.chosenBrandObservable.subscribe((brand) => {
        if (brand) {
          this.currentBrandId = brand.id;
        }
      });

    this.languageDropDownOpenedSubscription =
      this.languageService.languageDropDownOpenedObservable.subscribe(
        (languageDropDownOpened) => {
          this.languagesOpened = languageDropDownOpened;
        }
      );

    this.chosenLanguageSubscription =
      this.languageService.chosenLanguageObservable.subscribe((language) => {
        this.selectedLang = language;
      });

    // if (this.host === 'portal.otb-trading.com') {
    //   this.brandEmailAddress = 'support@otb-trading.com';
    // }

    this.pageService.brandListSubject.subscribe((res) => {
      this.brandShortList = res;
    });
  }

  changeBrand(value) {
    this.pageService.brandShortlist.next(value);
    localStorage.setItem("localBrandId", value);
    window.location.reload();
  }

  // fixedBrand(){
  //   this.brands = [{availableCountryCodes: "",
  //   brandUrl: "portal.fxglobe.international",
  //   enabled: true,
  //   id: 2,
  //   isDefault: true,
  //   leverages: [1, 10, 20, 30, 40, 50, 100, 200, 300],
  //   name: "Global"}];

  //   if ('undefined' !== typeof this.brands[0]) {
  //     this.brandService.setBrands(this.brands);
  //     const mainBrand = this.brands[0];

  //     this.brandIsEnabled = mainBrand.enabled;
  //     this.brandIsEnabledDetected = true;

  //     this.brandService.setMainBrand(mainBrand);

  // this.ipStackService.get().subscribe(
  //   location => this.setCurrentLocation(location),
  //   () => {
  //     if ((!this.currentBrandId || 'undefined' === typeof this.currentBrandId) && this.brands[0].isDefault) {
  //       this.defaultBrand = this.brands[0];
  //     }
  //     this.brandService.changeChosenBrand(this.defaultBrand);
  //   },
  //   () => {
  //     let inStepTwo = false;
  //     if (window.location.pathname === '/register-step-2') {
  //       inStepTwo = true;
  //     }

  //     this.brands.forEach(brand => {
  //       if (inStepTwo) {
  //         if (brand.brandUrl === window.location.origin) {
  //           this.brandService.changeChosenBrand(brand);
  //           this.currentBrandId = brand.id;
  //         }
  //       } else {
  //         this.asyncFunction(brand, () => {
  //           if ((!this.currentBrandId || 'undefined' === typeof this.currentBrandId) && 'undefined' !== typeof this.defaultBrand) {
  //             this.brandService.changeChosenBrand(this.defaultBrand);
  //             this.currentBrandId = this.defaultBrand['id'];
  //           }
  //         });
  //       }
  //     });
  //   });
  // } else {
  // this.ipStackService.get().subscribe(location => this.setCurrentLocation(location));
  // }
  // }

  navigation() {
    const lang = localStorage.getItem("lang").toLocaleLowerCase();
    if (this.host !== "portal.otb-trading.com") {
      this.router.navigateByUrl("/");
    } else {
      window.location.href = `${this.mainWebsiteUrl}/${lang}/`;
    }
  }

  setCurrentLocation(location) {
    this.location = location;
    this.locationService.setLocation(location);
  }

  asyncFunction(brand, cb) {
    setTimeout(() => {
      const availableCountryCodes = brand.availableCountryCodes.split(",");

      if (
        availableCountryCodes.indexOf(
          this.location["country_code"].toLowerCase()
        ) >= 0
      ) {
        this.brandService.changeChosenBrand(brand);
        this.currentBrandId = brand.id;
      }

      if (
        (!this.currentBrandId || "undefined" === typeof this.currentBrandId) &&
        brand.isDefault
      ) {
        this.defaultBrand = brand;
      }

      cb();
    }, 100);
  }

  changeChosenBrand(brand): void {
    this.brandService.changeChosenBrand(brand);
    this.currentBrandId = brand.id;
  }

  openLanguages() {
    this.languagesOpened = true;
  }

  ngOnDestroy() {
    this.hideBrandsSubscription.unsubscribe();
    this.hideBrandsSubscription.complete();

    this.chosenBrandSubscription.unsubscribe();
    this.chosenBrandSubscription.complete();

    this.languageDropDownOpenedSubscription.unsubscribe();
    this.languageDropDownOpenedSubscription.complete();

    this.chosenLanguageSubscription.unsubscribe();
    this.chosenLanguageSubscription.complete();
  }
}
