import { DepositType } from "./../../../../enums/deposit-type";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DepositTypeService } from "../services/deposit-type.service";
import { AuthService } from "../../../../auth/services/auth/auth.service";
import { AccountService } from "../../../services/account/account.service";
import { AccountTypeService } from "../../account-type/services/account-type.service";
import { RegistrationService } from "../../../../auth/services/registration/registration.service";
import { PageService } from "../../../../page.service";
import { Currency, Account } from "../../../../interfaces";
import { AppropriatenessTestService } from "../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { ToasterService } from "angular2-toaster";
import {LanguageService} from '../../../../languages/services/language/language.service';
@Component({
  selector: "app-trustly",
  templateUrl: "./trustly.component.html",
  styleUrls: ["./trustly.component.scss"],
})
export class TrustlyComponent implements OnInit, OnDestroy {
  trustlyForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validNetellerEmail = true;
  validSecureId = true;
  validDepositAmount = true;
  // validMerchantRef = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: number;
  currentMinDeposit: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount: boolean = false;
  exChange = 1;
  errorMessage: string;
  isChina = false;
  id = "2";

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,
    private registrationService: RegistrationService,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.clientId = this.authService.getAuthClientId();

    this.trustlyForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount_usd: [0],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
    });

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });

    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe(
    //   (accounts: Account[]) => {
    //     if (accounts.length > 0) {
    //       this.accounts = accounts;
    //       this.openNewAccount = false;
    //     } else {
    //       const value = "Open a new account";
    //       this.trustlyForm.get("account").setValue(value);
    //       this.openNewAccount = true;
    //     }
    //   }
    // );

    this.activeAccountSubscription =
      this.accountService.activeAccountObservable.subscribe(
        (activeAccount: Account) => {
          if (activeAccount) {
            this.activeAccountNumber = activeAccount["account"];
            if (localStorage.getItem("tmp_account")) {
              this.trustlyForm
                .get("account")
                .setValue(localStorage.getItem("tmp_account"));
              localStorage.removeItem("tmp_account");
            } else {
              this.trustlyForm
                .get("account")
                .setValue(this.activeAccountNumber);
            }
            this.changeAccount(this.activeAccountNumber);
          }
        }
      );
    this.clientId = this.authService.getAuthClientId();
  }

  submit(): void {
    if (this.trustlyForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      if (this.trustlyForm.controls.account.value === "Open a new account") {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.trustlyForm.value.currency,
          accountType: this.currentType["id"],
          leverage: 30,
        };
        // this.accountService
        //   .addAdditionalLiveAccount(accountData)
        //   .subscribe(res => {
        //     this.newAccount = res.account;
        //     this.accountService.updateAccounts(res.account);
        //     this.submitDeposit();
        //   });
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls["account"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validDepositAmount = this.controls["deposit_amount"].valid;
      // this.validMerchantRef = this.controls['merchantRef'].valid;
    }
  }

  get controls() {
    return this.trustlyForm.controls;
  }

  submitDeposit() {
    let account = this.trustlyForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }
    const data = {
      depositMethodId: this.id,
      depositMethodSubtypeId: null,
      amount: Number(this.trustlyForm.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.trustlyForm.value.currency,
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        window.location.href = res.data.extraParams;
      } else {
        this.errorMessage = res.message;
      }
    });
    // this.accountService.submitDepositInfo(data).subscribe(
    //   res => {
    //     switch (res["status"]) {
    //       case DepositType.New:
    //         break;
    //       case DepositType.Prep:
    //         break;
    //       case DepositType.Parked:
    //         this.toasterService.pop(
    //           "success",
    //           "Thanks for your deposit!",
    //           "Your payment is under review. You won't be charged until it's approved."
    //         );
    //         break;
    //       case DepositType.Approved:
    //         this.toasterService.pop(
    //           "success",
    //           "Thanks for your deposit!",
    //           "Your payment has been successful."
    //         );
    //         window.location.href = res["response"].redirectUrl;
    //         break;
    //       case DepositType.Rejected:
    //         this.toasterService.pop(
    //           "error",
    //           "Oops, something went wrong.",
    //           "Unfortunately your payment has failed. Please try again."
    //         );
    //         break;
    //     }

    //     this.pageService.changeLoadingStatus(false);
    //   },
    //   error => {
    //     this.pageService.changeLoadingStatus(false);
    //     this.toasterService.pop("error", "Error", "Unable to register order");
    //   }
    // );
  }

  set(ev) {
    this.trustlyForm.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.trustlyForm.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.trustlyForm.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  // onAccountChange() {
  //   // this.getMinDepositAmountByCurrency();
  //   this.getCurrencyRate();
  // }

  // getMinDepositAmountByCurrency() {
  //   const { account, currency } = this.trustlyForm.value;
  //   const payload = {
  //     clientAccountId: account,
  //     depositCurrency: currency,
  //   };
  //   if (account && currency) {
  //     this.depositTypeService
  //       .getMinDepositAmountByCurrency(payload)
  //       .subscribe((result) => {
  //         if (!result.data.madeDeposit) {
  //           this.currentMinDeposit = result.data.minDepositAmount;
  //           this.trustlyForm.controls.deposit_amount.setValidators([
  //             Validators.required,
  //             Validators.min(this.currentMinDeposit),
  //           ]);
  //         }
  //       });
  //   }
  // }

  getCurrencyRate() {
    this.trustlyForm.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.trustlyForm.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });

      const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };

      this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.trustlyForm.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  // changeMerchantRef(value){
  //   AccountService
  // }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });
      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find((currency) => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.trustlyForm.get("currency").setValue(activeAccountCurrency.id);
      }
    }
  }

  updateValidation() {
    this.trustlyForm.controls.deposit_amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit),
    ]);
    this.trustlyForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.trustlyForm.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }

  ngOnDestroy() {}
}
