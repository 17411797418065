import { BrandService } from './../../../auth/services/brand/brand.service';
import { IpAccessService } from "./../../services/ipAccess/ip-access.service";
import { Component, OnInit } from "@angular/core";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { PlatformLocation, Location } from "@angular/common";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { NavigationEnd, Router, Event } from "@angular/router";
import { RegistrationService } from "../../../auth/services/registration/registration.service";
import { AccountTypeService } from "../account-type/services/account-type.service";
import { AuthService } from "../../../auth/services/auth/auth.service";
import { NoticeUponMakingADepositComponent } from "./modals/notice-upon-making-a-deposit/notice-upon-making-a-deposit.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DepositTypeService } from "./services/deposit-type.service";
import { BrandIdEnum } from 'src/app/brand.enum';

library.add(far, fab);

@Component({
  selector: "app-deposit-type",
  templateUrl: "./deposit-type.component.html",
  styleUrls: ["./deposit-type.component.scss"],
})
export class DepositTypeComponent implements OnInit {
  faUniversity = faUniversity;
  currentLoc: string;
  depositMethodeShortList = [];
  depositMethods = [
    {
      name: "Credit Card",
      slug: "credit-card",
    },
    {
      name: "Skrill",
      slug: "skrill",
    },
    {
      name: "Neteller",
      slug: "neteller",
    },
    {
      name: "Fasapay",
      slug: "fasapay",
    },
    {
      name: "人民币",
      slug: "zotapay",
    },
    {
      name: "Vietnam",
      slug: "zotapay-vietnam",
    },
    {
      name: "Thailand",
      slug: "zotapay-thailand",
    },
    // {
    //   name: "Wonderland",
    //   slug: "wonderland"
    // },
    // {
    //   name: "EFT",
    //   slug: "eft"
    // },
    // {
    //   name: 'Sofort',
    //   slug: 'sofort'
    // },
    // {
    //   name: 'Dotpay',
    //   slug: 'dotpay'
    // },
    {
      name: "Wire Transfer",
      slug: "wire-transfer",
    },
    {
      name: "Trustly",
      slug: "trustly",
    },
    // {
    //   name: "Payboutique",
    //   slug: "pay-boutique"
    // },
    {
      name: "SupremCash",
      slug: "suprem-cash",
    },
    {
      name: "Credit Card",
      slug: "card",
    },
    // {
    //   name: "Sorin-XE",
    //   slug: "sorin-XE"
    // }
  ];
  clientId: number;
  // firstTime = true;
  isGlobal: boolean;
  //message = "";
  isTimeStock = false;

  isActionAllowed = false;

  constructor(
    private router: Router,
    private location: PlatformLocation,
    private loc: Location,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private authService: AuthService,
    private depositTypeService: DepositTypeService,
    private modalService: NgbModal,
    private ipAccessService: IpAccessService,
    private brandService: BrandService
  ) {
    this.location.onPopState(() => {
      if (
        this.loc.path() === "/deposit-type" &&
        this.currentLoc !== "/deposit-type"
      ) {
        this.currentLoc = "/deposit-type";
        this.loc.back();
      }
    });
  }

  ngOnInit() {
    this.accountTypeService.isAccountTypeChosen.next(true);
    this.clientId = this.authService.getAuthClientId();
    this.getIsActionAllowed();

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.International:
      case BrandIdEnum.southAfrica:
        this.isGlobal = true;
        break;
      case BrandIdEnum.OTB:
        this.isTimeStock = true;
        this.router.navigate(["deposit-type/ts"]);
        break;
    }

    if (!this.isTimeStock) {
      this.getDepositMethodeShortList();
    }

    // this.ipAccessService.getIpAccess().subscribe(result => {
    //   if (
    //     result.level === "Success"
    //     //&&
    //     //window.location.hostname === "portal.swisscapital.fm"
    //   ) {
    //     if (result.data) {
    //       this.depositMethods.push({
    //         name: "EFT",
    //         slug: "eft"
    //       });
    //     }
    //   }
    // });

    // TODO: remove this once we have getDepositTypes API call
    // if (
    //   window.location.hostname === "portal.fxcm-globe.com" ||
    //   window.location.hostname === "portal.otb-trading.com"
    // ) {
    //   this.depositMethods = [
    //     {
    //       name: "Payboutique",
    //       slug: "pay-boutique",
    //     },
    //   ];
    //   if (window.location.hostname === "portal.otb-trading.com") {
    //     this.depositMethods.push(
    //       {
    //         name: "Bitcoin",
    //         slug: "bitcoin",
    //       },
    //       {
    //         name: "Credit Card",
    //         slug: "card"
    //       }
    //     );
    //   }
    // }

    // if (window.location.hostname === "portal.swisscapital.fm") {
    //   this.depositMethods = [
    //     {
    //       name: "Wonderland",
    //       slug: "wonderland"
    //     },
    //     {
    //       name: "Bitcoin",
    //       slug: "bitcoin"
    //     }
    //   ];
    // }

    // this.isGlobal = this.registrationService.isGlobal();

    // const isFxGlobe = this.registrationService.isFxGlobeBrand();
    // if (isFxGlobe) {
    //   if ( window.location.hostname !== 'portal.fxglobe.com' && window.location.hostname !== 'portal.fxglobe.international') {
    //     this.depositMethods.push(
    //       {
    //         name: "人民币",
    //         slug: "zotapay"
    //       }
    //     );
    //   }

    //   if (window.location.hostname == 'portal.fxglobe.international'){
    //     this.depositMethods.push(
    //       {
    //           name: "SupremCash",
    //           slug: "suprem-cash"
    //       }, {
    //           name: "Bitcoin",
    //           slug: "bitcoin"
    //       }
    //     );
    //   }
    // }

    // if (this.currentLoc !== "/deposit-type") {
    //   this.router.events.subscribe((event: Event) => {
    //     if (event instanceof NavigationEnd && event.url === "/deposit-type") {
    //       this.router.navigate(["deposit-type", this.depositMethods[0].slug]);
    //     }
    //   });

    //   if (this.router.url === "/deposit-type") {
    //     this.router.navigate(["deposit-type", this.depositMethods[0].slug]);
    //   }
    // }

    // this.clientId = this.authService.getAuthClientId();

    // this.registrationService
    //   .getTermsAndCondFlag(this.clientId)
    //   .subscribe((result) => {
    //     this.firstTime = !result.termsAndCondFlag;
    //     this.message = result.message;
    //     this.openNotice();
    //   });
  }

  getIsActionAllowed() {
    this.registrationService
      .isActionAllowed(this.clientId, 2)
      .subscribe((res) => {
        if (res.status === 1) {
          this.isActionAllowed = res.data;
        }
      });
  }

  getDepositMethodeShortList() {
    this.depositTypeService.depositMethodeShortList().subscribe((res) => {
      if (res.status === 1) {
        this.depositMethodeShortList = res.data;
        this.router.navigate(["deposit-type", res.data[0].id]);
      }
    });
  }

  // openNotice() {
  //   if (this.firstTime && this.isGlobal) {
  //     const modalRef = this.modalService.open(
  //       NoticeUponMakingADepositComponent
  //     );
  //     modalRef.componentInstance.clientId = this.clientId;
  //     modalRef.componentInstance.message = this.message;
  //     modalRef.result.then((result) => {
  //       this.firstTime = result;
  //     });
  //   }
  // }

  changeDepositType(depositType) {
    this.router.navigate(["deposit-type", depositType]);
  }
}
