import { Component, OnInit } from '@angular/core';
import { Account } from '../../interfaces/account';
import { AccountService } from '../services/account/account.service';
import { ReportsService } from '../services/reports/reports.service';
import { WithdrawalDepositReportRequest,
         WithdrawalDepositReportResponse,
         ReportsTimeInterval
        } from 'src/app/models/reports.model';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import * as moment from 'moment';
import { TimeIntervalEnum, SelectAccountEnum } from '../../enums/reports.enum';
import { PageService } from 'src/app/page.service';
import { ToasterService } from 'angular2-toaster';
import { RegistrationService } from 'src/app/auth/services/registration/registration.service';
import { Currency } from 'src/app/interfaces';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public accounts: any;
  public timeInterval: ReportsTimeInterval[];
  public selectedInterval = TimeIntervalEnum.allTime;
  public tableData: WithdrawalDepositReportResponse;
  public selectedAccount = 0;
  public clientId: number;
  public timeIntervalEnum = TimeIntervalEnum;
  public selectAccountEnum = SelectAccountEnum;
  public currencies: Currency[];
  public currencySymbol: string;
  public hostname: string;
  // public dateFormat: string = 'YYYY-MM-DD HH:MM:SS.MSS';

  constructor(
    private accountService: AccountService,
    private reportsService: ReportsService,
    private authService: AuthService,
    private pageService: PageService,
    private toasterService: ToasterService,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
    this.hostname = window.location.hostname;
    this.timeInterval = this.reportsService.timeInterval;
    // const mainInfo = this.registrationService.getMainInfo();
    // const brandId = mainInfo['brandId'];
    // this.accountService.getCurrencies(brandId).subscribe(currencies => {
    //   this.currencies = currencies;
    // });

    this.accountService.getClientAccountByAccountType(1).subscribe((accounts) => {

      // Accounts for only MT4
          this.accounts = accounts.data.filter(elem => elem.tradingPlatform === 1);
      });

      // ......Old version get accounts.....
    // this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
    //   if (accounts.length > 0) {
    //     this.accounts = accounts;
    //   }
    // });



    this.clientId = this.authService.getAuthClientId();
    this.initRequest();
  }

  public execute = () => {
    // let startDate: string;

    // switch (+this.selectedInterval) {
    //   case this.timeIntervalEnum.today :
    //     startDate = this.getToday();
    //     break;
    //   case this.timeIntervalEnum.lastThreeDays :
    //     startDate = moment().subtract(3, 'days').format(this.dateFormat);
    //     break;
    //   case this.timeIntervalEnum.lastWeek :
    //     startDate = moment().subtract(1, 'week').format(this.dateFormat);
    //     break;
    //   case this.timeIntervalEnum.lastMonth :
    //     startDate = moment().subtract(1, 'month').format(this.dateFormat);
    //     break;
    //   case this.timeIntervalEnum.lastThreeMonth :
    //     startDate = moment().subtract(3, 'month').format(this.dateFormat);
    //     break;
    //   case this.timeIntervalEnum.lastSixMonth :
    //     startDate = moment().subtract(6, 'month').format(this.dateFormat);
    //     break;
    //   case this.timeIntervalEnum.allTime :
    //     startDate = moment(new Date(1950, 0, 1)).format(this.dateFormat);
    //     break;
    // }

    // const data: WithdrawalDepositReportRequest = {
    //   Client_ID: +this.selectedAccount === this.selectAccountEnum.allAccounts ?
    //               this.clientId : 0,
    //   Login: +this.selectedAccount === this.selectAccountEnum.allAccounts ?
    //           this.selectAccountEnum.allAccounts : this.selectedAccount,
    //   StartDate: startDate,
    //   EndDate: moment(new Date()).format(this.dateFormat)
    // };
    const data: WithdrawalDepositReportRequest = {
      accountId: this.selectedAccount,
      clientId: this.clientId,
      depositWithdrawalReportTimeInterval: +this.selectedInterval
    };
    this.request(data);
  }

  public request = (data) => {
    this.reportsService.getWithdrawalDepositReport(data).subscribe((result) => {
      if (result.status === 1) {
        this.tableData = result.data;
        this.generateTotalCurrencySymbol();
      }
    });
  }

  public initRequest = () => {
    const data: WithdrawalDepositReportRequest = {
      accountId: 0,
      clientId: this.clientId,
      depositWithdrawalReportTimeInterval: 0
    };
    this.request(data);
  }



  public generateTotalCurrencySymbol = () => {
    if (this.tableData && this.currencies) {
      this.currencySymbol = this.currencies.find(
        currency => currency.name === this.tableData.totalsCurrency
        ).currencySymbol;
    }
  }

  public changeAccount = (account) => {
    this.selectedAccount = account;
  }


}
