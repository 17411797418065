import { Component, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { PageService } from '../../../../page.service';
import { Currency, Account } from '../../../../interfaces';
import { AppropriatenessTestService } from '../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { ToasterService } from 'angular2-toaster';
import { DepositType } from './../../../../enums/deposit-type';
import { ZotaPayRatesAndLimits } from 'src/app/interfaces/zotapay-rates-and-limits';
import { ZotapayMethodEnum, ZotapayTypeEnum } from 'src/app/enums/zota-pay.enum';
import { ThrowStmt } from '@angular/compiler';
import { timeout } from 'rxjs/operators';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: 'app-zotapay',
  templateUrl: './zotapay.component.html',
  styleUrls: ['./zotapay.component.scss']
})
export class ZotapayComponent implements OnInit, OnDestroy {
  zotapayForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validDepositAmount = true;
  validMethod = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: number;
  // isTestEnvironment: boolean;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  testTermsAccepted = false;
  isActive: boolean;
  selectedMethod: number;
  zotapayMethodEnum = ZotapayMethodEnum;
  zotapayTypeEnum = ZotapayTypeEnum;
  activeAccountCurrency: number;
  openNewAccount = false;
  methodsDisabled = true;
  zotaVietnam = false;
  @Input() zotapayType: number = ZotapayTypeEnum.zotapayChina;
  isChina = false;

  methods = [];

  curencyRateAndLimits: ZotaPayRatesAndLimits[];
  validAmountRange = '';
  requestStatuses: boolean[] = [];

  id = '3';

  currentMinDeposit: number;
  exChange = 1;
  errorMessage: string;



  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
    private validationService: ValidationService,
    private toasterService: ToasterService
  ) {

    this.fireFormValidations();
  }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.getDepositMethodeSubtypeShortList();


    this.clientId = this.authService.getAuthClientId();


    this.accountService.getPspCurrency(this.id).subscribe(currencies => {
      this.currencies = currencies.data;
    });

    this.accountService.getWalletAccounts()
      .subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe(
    //   (accounts: Account[]) => {
    //     if (accounts.length > 0) {
    //       this.openNewAccount = false;
    //       this.accounts = accounts;


    //     } else {
    //       const value = 'Open a new account';
    //       this.zotapayForm.get('account').setValue(value);
    //       this.openNewAccount = true;
    //       this.methodsDisabled = true;
    //     }
    //   }
    // );

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe(
      (activeAccount: Account) => {
        if (activeAccount) {
          this.activeAccountNumber = activeAccount['account'];
          if (localStorage.getItem('tmp_account')) {
            this.zotapayForm
              .get('account')
              .setValue(localStorage.getItem('tmp_account'));
            localStorage.removeItem('tmp_account');
          } else {
            this.zotapayForm.get('account').setValue(this.activeAccountNumber);
          }

          this.changeAccount(this.activeAccountNumber);
        }
      }
    );

    // this.isTestEnvironment = this.registrationService.isTestEnvironment();

  }


  getDepositMethodeSubtypeShortList() {
    this.depositTypeService.depositMethodeSubtypeShortList(this.id)
    .subscribe((res) => {
      if (res.status === 1) {
        this.methods = res.data;
      }
    });
  }

  set(ev) {
    this.zotapayForm.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2)
    });
  }
  get(ev) {
    this.zotapayForm.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2)
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.zotapayForm.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  changeSolution(solutionId) {
    this.depositTypeService.getSolutionSupremCurrencyShortList(solutionId, this.id)
    .subscribe((res) => {
      if (res.status === 1) {
        this.currencies = res.data;
      }
    });
  }


  // onAccountChange(ev){
  //   this.getDepositAmount(ev.target.value)
  //   this.getCurrencyRate()
  // }

  // getDepositAmount(clientAccountId){
  //   this.depositTypeService
  //   .getDepositAmount(clientAccountId)
  //   .subscribe((result) => {
  //     if (!result.data.madeDeposit) {
  //       this.currentMinDeposit = result.data.minDepositAmount;
  //       this.zotapayForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
  //     }
  //   });
  // }

  getCurrencyRate() {
    this.zotapayForm.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const {currency, account} = this.zotapayForm.value;
    if (account && currency) {
      this.accountService.getCurrencyRate(currency, account)
      .subscribe((res) => {
        if (res.status === 1 && res.data) {
          this.exChange = res.data;
        } else {
          this.exChange = 1;
        }
      });
          const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };
            this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.zotapayForm.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }


  submit(): void {
    if (this.zotapayForm.valid) {
      // this.pageService.changeLoadingStatus(true);

      if (this.zotapayForm.controls.account.value === 'Open a new account') {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.zotapayForm.value.currency,
          accountType: this.currentType['id'],
          leverage: 30
        };

      } else {
        this.submitForm();
      }
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
    }
  }

  submitForm() {
    let cur = '';
    for (let idx = 0; idx < (<any[]>this.currencies).length; idx++) {
      if (this.currencies[idx]['id'] === +this.zotapayForm.value.currency) {
        cur = this.currencies[idx]['name'];
      }
    }

    const data = {
      depositMethodId: this.id,
      // depositMethodSubtypeId: this.zotapayForm.value.method,
      amount: Number(this.zotapayForm.value.deposit_amount),
      accountId: this.zotapayForm.value.account,
      currencyISOCode: this.zotapayForm.value.currency,
      extraParams: {
        Solution: this.zotapayForm.value.method
      }
    };

    this.depositTypeService.submitDeposit(data)
    .subscribe((res) => {
      if (res.status === 1) {
        window.location.href = res.data.extraParams;
      } else {
        this.errorMessage = res.message;
      }
    });

  }

  get controls() {
    return this.zotapayForm.controls;
  }

  fireFormValidations() {
    this.zotapayForm = this.formBuilder.group({
      method: ['', [Validators.required]],
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      deposit_amount: ['',
        [Validators.required, Validators.min(this.currentMinDeposit)]
      ],
      deposit_amount_usd: [0]
    });
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.zotapayForm.get('deposit_amount')
    );
    this.validDepositAmount = true;
    // if (this.curencyRateAndLimits) {
    //   this.calculateConvertedAmount();
    // }
  }

  // calculateConvertedAmount() {
  //   this.zotapayForm.get('converted_amount').setValue(
  //     (this.zotapayForm.value.deposit_amount * this.curencyRateAndLimits[+this.selectedMethod - 1].currencyRate).toFixed(2)
  //     + ' ' + String(this.curencyRateAndLimits[+this.selectedMethod - 1].currencyName)
  //   )
  // }




  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find(account => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.activeAccountCurrency = Number(activeAccountCurrency.id);
      }
    }
  }

  updateValidation() {
    this.zotapayForm.controls.deposit_amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit)
    ]);
    this.zotapayForm.controls.deposit_amount.updateValueAndValidity();
  }



  ngOnDestroy() {

  }
}
