import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DepositTypeService } from "../services/deposit-type.service";
import { AuthService } from "../../../../auth/services/auth/auth.service";
import { AccountService } from "../../../services/account/account.service";
import { RegistrationService } from "../../../../auth/services/registration/registration.service";
import { AccountTypeService } from "../../account-type/services/account-type.service";
import { GeneralService } from "../../../../services/general/general.service";
import { PageService } from "../../../../page.service";
import { Currency, Account } from "../../../../interfaces";
import { AppropriatenessTestService } from "../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { UUID } from 'angular2-uuid';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: "app-wonderland",
  templateUrl: "./wonderland.component.html",
  styleUrls: ["./wonderland.component.scss"]
})
export class WonderlandComponent implements OnInit {
  wonderlandForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validFullName = true;
  validIssuingCountry = true;
  validVisaNumber = true;
  validExpiryMonth = true;
  validExpiryYear = true;
  validCVV = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  activeAccount: object;
  fullName: string;
  countries: object;
  currentType: object;
  currentMinDeposit: number;
  currencies: Currency[];
  newAccount: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  account: any;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount: boolean = false;
  isChina = false;
  // TODO HARDCODED CREDS
  merNo: string = '78052';
  gatewayNo: string = '78052003';
  uniqueId: string = UUID.UUID();

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private depositTypeService: DepositTypeService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private generalService: GeneralService,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.fireFormValidations();

    this.clientId = this.authService.getAuthClientId();

    // this.appropriatenessTestService
    //   .getClientTestScore(this.clientId)
    //   .subscribe(score => {
    //     this.appropriatenessTestService
    //       .getTestTerms(this.clientId)
    //       .subscribe(testTerms => {
    //         if (
    //           (score > 20 && testTerms["testTerms"] == true) ||
    //           score > 80 // TODO  WHY DO WE USE LEVELS
    //         ) {
    //           this.testTermsAccepted = true;
    //         } else {
    //           this.testTermsAccepted = false;
    //         }
    //       });
    //   });

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe(
      (currencies: Currency[]) => {
        this.currencies = currencies;
        this.changeAccount(this.activeAccountNumber);
      }
    );

    // this.registrationService.getBasicInfo(this.clientId).subscribe(basicInfo => {
    //   this.isActive = basicInfo.isActive;
    //   this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
    //     this.currentType = currentType;
    //     if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
    //       if (this.isActive) {
    //         this.currentMinDeposit = 0;
    //       } else {
    //         this.currentMinDeposit = this.currentType['minDeposit'];
    //       }
    //       this.updateValidation();
    //     } else { // TODO: remove this in future
    //       this.currentMinDeposit = 50;
    //       this.updateValidation();
    //     }
    //   });
    // });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe(
      (accounts: Account[]) => {
        if (accounts.length > 0) {
          this.accounts = accounts;
          this.openNewAccount = false;
        } else {
          const value = "Open a new account";
          this.wonderlandForm.get("account").setValue(value);
          this.openNewAccount = true;
        }
      }
    );

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe(
      (activeAccount: Account) => {
        if (activeAccount) {
          this.activeAccountNumber = activeAccount["account"];
          if (localStorage.getItem("tmp_account")) {
            this.wonderlandForm
              .get("account")
              .setValue(localStorage.getItem("tmp_account"));
            localStorage.removeItem("tmp_account");
          } else {
            this.wonderlandForm
              .get("account")
              .setValue(this.activeAccountNumber);
          }
          this.changeAccount(this.activeAccountNumber);
        }
      }
    );

    // this.registrationService
    //   .getBasicInfo(this.clientId)
    //   .subscribe(basicInfo => {
    //     this.fullName = basicInfo.firstName + " " + basicInfo.lastName;
    //     const issuingCountry = basicInfo.countryCode;
    //     this.wonderlandForm.get("issuing_country").setValue(issuingCountry);
    //   });

    // this.generalService.getCountries().subscribe(countries => {
    //   this.countries = countries;
    // });
  }

  submit(): void {
    if (this.wonderlandForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      if (this.wonderlandForm.controls.account.value === "Open a new account") {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.wonderlandForm.value.currency,
          accountType: this.currentType["id"],
          leverage: 30
        };

        // this.accountService
        //   .addAdditionalLiveAccount(accountData)
        //   .subscribe(res => {
        //     this.newAccount = res.account;
        //     this.accountService.updateAccounts(res.account);
        //     this.submitDeposit();
        //   });
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls["account"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validFullName = this.controls["full_name"].valid;
      this.validIssuingCountry = this.controls["issuing_country"].valid;
      this.validVisaNumber = this.controls["card_number"].valid;
      this.validExpiryMonth = this.controls["expiry_month"].valid;
      this.validExpiryYear = this.controls["expiry_year"].valid;
      this.validCVV = this.controls["CVV"].valid;
      this.validDepositAmount = this.controls["deposit_amount"].valid;
    }
  }

  get controls() {
    return this.wonderlandForm.controls;
  }

  submitDeposit() {
    let account = this.wonderlandForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      id: 0,
      clientId: this.clientId,
      depositMethod: 0,
      account: account,
      currencyId: this.wonderlandForm.value.currency,
      amount: Number(this.wonderlandForm.value.deposit_amount),
      WonderlandPay: {
        uniqueId: this.uniqueId,
        // orderCurrency: this.wonderlandForm.value.currency,
        // orderAmount: Number(this.wonderlandForm.value.deposit_amount),
        cardNo: this.wonderlandForm.value.card_number,
        cardExpireMonth: this.wonderlandForm.value.expiry_month,
        cardExpireYear: this.wonderlandForm.value.expiry_year,
        cardSecurityCode: this.wonderlandForm.value.CVV
      },
      depositStatus: 0,
      done: true
    };

    // this.accountService.submitDepositInfo(data).subscribe(res => {
    //   this.pageService.changeLoadingStatus(false);

    //   let messageName;

    //   switch (res["status"]) {
    //     case 2:
    //       messageName = "review";
    //       break;
    //     case 3:
    //       messageName = "success";
    //       break;
    //     case 4:
    //       messageName = "decline";
    //       break;
    //   }

    //   const selectedCurrency = this.currencies.find(currency => {
    //     return Number(this.wonderlandForm.value.currency) === currency.id;
    //   });

    //   const depositData = {
    //     depositMethod: "WonderlandPay",
    //     account: account,
    //     clientName: this.fullName,
    //     transactionDate: "",
    //     requestedAmount: this.wonderlandForm.value.deposit_amount,
    //     currency: selectedCurrency.currencySymbol,
    //     status: messageName
    //   };

    //   // TODO: change the way of doing this. Pass object to next component ?
    //   this.depositTypeService.setDepositInfo(depositData);
    //   this.accountService.updateAccounts();
    //   this.router.navigate(["message", messageName]);
    // });
  }

  fireFormValidations(): void {
    this.wonderlandForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      // full_name: ["", [Validators.required]],
      issuing_country: ["", [Validators.required]],
      card_number: ["", [Validators.required]],
      expiry_month: ["", [Validators.required]],
      expiry_year: ["", [Validators.required]],
      CVV: ["", [Validators.required]],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)]
      ]
      // deposit_uniqueId: ["", [Validators.required]]
    });
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.wonderlandForm.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }

  ngOnDestroy() {
    this.currentTypeSubscription.unsubscribe();
    this.currentTypeSubscription.complete();

    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();

    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();

    this.currenciesSubscription.unsubscribe();
    this.currenciesSubscription.complete();
  }

  updateValidation() {
    this.wonderlandForm.controls.deposit_amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit)
    ]);
    this.wonderlandForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find(account => {
        return account.accountId === Number(value);
      });
      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.wonderlandForm.get("currency").setValue(activeAccountCurrency.id);
      }
    }
  }
}
