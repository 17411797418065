import { Component, OnInit, OnDestroy } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { BrandIdEnum } from "../brand.enum";
import { LanguageService } from "./services/language/language.service";

@Component({
  selector: "app-languages",
  templateUrl: "./languages.component.html",
  styleUrls: ["./languages.component.scss"],
})
export class LanguagesComponent implements OnInit, OnDestroy {
  faTimes = faTimes;
  selectedLang: string;
  languages: object;
  private chosenLanguageSubscription;

  public localBrandId = `${localStorage.getItem("localBrandId")}`;

  constructor(private languageService: LanguageService) {
    if (BrandIdEnum.OTB === +this.localBrandId) {
      this.languages = this.languageService.getOtbLanguages();
    } else {
      this.languages = this.languageService.getAllLanguages();
    }
  }

  ngOnInit() {
    this.chosenLanguageSubscription =
      this.languageService.chosenLanguageObservable.subscribe((language) => {
        this.selectedLang = language;
      });
  }

  selectLanguage(language: string): boolean {
    this.languageService.changeLanguage(language);
    this.closeLanguages();
    return false;
  }

  closeLanguages() {
    this.languageService.closeLanguages();
  }

  ngOnDestroy() {
    this.chosenLanguageSubscription.unsubscribe();
    this.chosenLanguageSubscription.complete();
  }
}
