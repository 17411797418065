import { BrandService } from "src/app/auth/services/brand/brand.service";
import { Component, OnInit } from "@angular/core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { takeUntil } from "rxjs/operators";
import { DepositTypeService } from "../services/deposit-type.service";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-bitcoin",
  templateUrl: "./bitcoin.component.html",
  styleUrls: ["./bitcoin.component.scss"],
})
export class BitcoinComponent implements OnInit {
  constructor(
    private depositTypeService: DepositTypeService,
    private brandService: BrandService
  ) {}
  bitcoinDescription: boolean = true;
  hash: string;
  hostName: string;
  faCopy = faCopy;
  pspId = 11;
  displayName: string;
  public isOtb: boolean;
  public isRevolution: boolean;
  public isTuDoFx: boolean;
  public isInternational: boolean;
  public isEU: boolean;
  public emerald: boolean;
  public greenLine: boolean;
  public tradeCenter: boolean;
  public tradeMarkets: boolean;
  public phenoFx: boolean;
  public micMarket: boolean;
  southAfrica: boolean;
  growProMarkets: boolean;

  ngOnInit() {
    this.hostName = window.location.hostname;
    // if (this.hostName === "portal.swisscapital.fm") {
    //   this.bitcoinDescription = false;
    // }
    // if (this.hostName === "portal.fxglobe.international") {
    //   this.hash = "17MBPgaZJxTJ2SYWPvhrXrGhg5PNNgXpuZ";
    // }
    // if (this.hostName === "portal.otb-trading.com") {
    //   this.hash = '1JRC7FXp4RMr4rm4akSdEWRB2F2b4Dg5XL';
    // }

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
        this.isEU = true;
        break;
      case BrandIdEnum.International:
        this.isInternational = true;
        break;
      case BrandIdEnum.southAfrica:
        this.southAfrica = true;
        break;
      case BrandIdEnum.OTB:
        this.isOtb = true;
        break;
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
        break;
      case BrandIdEnum.TuDoFx:
        this.isTuDoFx = true;
        break;
      case BrandIdEnum.emerald:
        this.emerald = true;
        break;
      case BrandIdEnum.greenLine:
        this.greenLine = true;
        break;
      case BrandIdEnum.tradeCenter:
        this.tradeCenter = true;
        break;
      case BrandIdEnum.tradeMarkets:
        this.tradeMarkets = true;
        break;
      case BrandIdEnum.phenoFx:
        this.phenoFx = true;
        break;
      case BrandIdEnum.micMarket:
        this.micMarket = true;
        break;
      case BrandIdEnum.growProMarkets:
        this.growProMarkets = true;
        break;
    }
    this.getPspById();
  }

  getPspById() {
    this.depositTypeService.getPspById(this.pspId).subscribe((result) => {
      this.hash = result.data.walletId;
      this.displayName = result.data.displayName;
    });
  }

  copyText(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val["innerText"];
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
}
