import { Component, OnInit } from "@angular/core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ToasterService } from "angular2-toaster";

@Component({
  selector: "app-ts-payments",
  templateUrl: "./ts-payments.component.html",
  styleUrls: ["./ts-payments.component.scss"],
})
export class TsPaymentsComponent implements OnInit {
  constructor(private toasterService: ToasterService) {}

  faCopy = faCopy;
  public cryptoWallets = [
    {
      img: "assets/images/crypto-logos/bitcoin-btc-logo.png",
      title: "TIMESTOCK_PAYMENTS.CRYPTO.BITCOIN",
      walletId: "1Jztvncm6foweYL82bRLz4MzAcEKnWYiP4",
    },

    {
      img: "assets/images/crypto-logos/tether-usdt-logo.png",
      title: "TIMESTOCK_PAYMENTS.CRYPTO.TETHER_ERC",
      walletId: "0x3Ee36e54fF882fc3377fE3AdFE48ade404C29A86",
    },
    {
      img: "assets/images/crypto-logos/tether-usdt-logo.png",
      title: "TIMESTOCK_PAYMENTS.CRYPTO.TETHER_TRC",
      walletId: "TJR3Hs1fBHsL15nVhbi9eGC5kNPLfRnWWw",
    },
    {
      img: "assets/images/crypto-logos/ethereum-eth-logo.png",
      title: "TIMESTOCK_PAYMENTS.CRYPTO.ETHEREUM",
      walletId: "0x65Ae4f39b712eF325891416a6ac3c43a65cB97Ab",
    },
    {
      img: "assets/images/crypto-logos/xrp-xrp-logo.png",
      title: "TIMESTOCK_PAYMENTS.CRYPTO.XRP",
      walletId: "rGbDxVoyCNhrzxwUnP1PMwycTsYXEGUmoR",
    },
    {
      img: "assets/images/crypto-logos/litecoin-ltc-logo.png",
      title: "TIMESTOCK_PAYMENTS.CRYPTO.LITECOIN",
      walletId: "LXVgNDd2PxoXkCCRQF6e7ujjCsm1kKMDzq",
    },
  ];

  ngOnInit() {}

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    this.toasterService.pop("success", "Copied !");
  }
}
