import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DepositTypeService } from "../services/deposit-type.service";
import { AuthService } from "../../../../auth/services/auth/auth.service";
import { AccountService } from "../../../services/account/account.service";
import { AccountTypeService } from "../../account-type/services/account-type.service";
import { RegistrationService } from "../../../../auth/services/registration/registration.service";
import { ValidationService } from "../../../../services/validation/validation.service";
import { PageService } from "../../../../page.service";
import { Currency, Account } from "../../../../interfaces";
import { AppropriatenessTestService } from "../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { ToasterService } from "angular2-toaster";
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: "app-neteller",
  templateUrl: "./neteller.component.html",
  styleUrls: ["./neteller.component.scss"],
})
export class NetellerComponent implements OnInit, OnDestroy {
  netellerForm: FormGroup;
  validAccount = true;
  netellerAccountId = true;
  validCurrency = true;
  validNetellerEmail = true;
  validSecureId = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  testTermsAccepted: boolean = false;
  isActive: boolean = false;
  openNewAccount: boolean = false;
  currentMinDeposit: number;
  exChange = 1;
  errorMessage: string;
  isChina = false;
  id = "5";

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,
    private registrationService: RegistrationService,
    private pageService: PageService,
    private validationService: ValidationService,
    private appropriatenessTestService: AppropriatenessTestService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.clientId = this.authService.getAuthClientId();

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });

    this.netellerForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      netellerAccountId: ["", [Validators.required]],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
      deposit_amount_usd: [0],
    });

    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
    //   if (accounts.length > 0) {
    //     this.accounts = accounts;
    //     this.openNewAccount = false;
    //   } else {
    //     const value = 'Open a new account';
    //     this.netellerForm.get('account').setValue(value);
    //     this.openNewAccount = true;
    //   }
    // });
  }

  set(ev) {
    this.netellerForm.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.netellerForm.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.netellerForm.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  // onAccountChange(ev){
  //   this.getDepositAmount(ev.target.value)
  //   this.getCurrencyRate()
  // }

  // getDepositAmount(clientAccountId){
  //   this.depositTypeService
  //   .getDepositAmount(clientAccountId)
  //   .subscribe((result) => {
  //     if (!result.data.madeDeposit) {
  //       this.currentMinDeposit = result.data.minDepositAmount;
  //       this.netellerForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
  //     }
  //   });
  // }

  getCurrencyRate() {
    this.netellerForm.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.netellerForm.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });
      const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };
      this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.netellerForm.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }

  submit(): void {
    if (this.netellerForm.valid) {
      this.submitDeposit();
    } else {
      this.netellerAccountId = this.controls["accountId"].valid;

      this.validAccount = this.controls["account"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validNetellerEmail = this.controls["neteller_email"].valid;
      // this.validSecureId = this.controls['secure_id'].valid;
      this.validDepositAmount = this.controls["deposit_amount"].valid;
    }
  }

  get controls() {
    return this.netellerForm.controls;
  }

  submitDeposit() {
    let account = this.netellerForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      depositMethodId: this.id,
      depositMethodSubtypeId: null,
      amount: Number(this.netellerForm.value.deposit_amount),
      accountId: account,
      PaymentSystemProviderFieldValue:
        this.netellerForm.value.netellerAccountId,
      currencyISOCode: this.netellerForm.value.currency,
      ExtraParams: {
        AccountId: this.netellerForm.value.netellerAccountId,
      },
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        window.location.href = res.data.extraParams;
      } else {
        this.errorMessage = res.message;
      }
    });
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find((currency) => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.netellerForm.get("currency").setValue(activeAccountCurrency.id);
      }
    }
  }

  updateValidation() {
    this.netellerForm.controls.deposit_amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit),
    ]);
    this.netellerForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.netellerForm.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }

  ngOnDestroy() {}
}
