import { Component, ElementRef, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/auth/services/auth/auth.service";
import { AccountService } from "src/app/dashboard/services/account/account.service";
import { Currency, Account } from "src/app/interfaces";
import { DepositTypeService } from "../services/deposit-type.service";
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: "app-pay-way",
  templateUrl: "./pay-way.component.html",
  styleUrls: ["./pay-way.component.scss"],
})
export class PayWayComponent implements OnInit {
  payWay: FormGroup;
  validAccount = true;
  validCurrency = true;
  validFullName = true;
  validIssuingCountry = true;
  validVisaNumber = true;
  validExpiryMonth = true;
  validExpiryYear = true;
  validCVV = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  activeAccount: object;
  fullName: string;
  countries: object;
  currentType: object;
  currentMinDeposit: number;
  currencies: Currency[];
  newAccount: number;
  account: any;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount = false;
  errorMessage: string;
  str = {"merchantID":"8639962","trans_amount":"1","trans_…":"W8UMJAXxFuaglyAAlSo3pKbdfeOTwoCyb5UT5IBiOwg="};
  isChina = false;
  id = "18";

  exChange = 1;

  modal = false;

  constructor(
    private langService: LanguageService,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private depositTypeService: DepositTypeService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");

    this.fireFormValidations();
    this.clientId = this.authService.getAuthClientId();

    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });

    this.getDepositMethodeSubtypeShortList();
  }

  getDepositMethodeSubtypeShortList() {
    this.depositTypeService
      .depositMethodeSubtypeShortList(this.id)
      .subscribe((res) => {
        if (res.status === 1) {
          // // this.depositMethodeSubtypeShortList = res.data
          // console.log(res.data);
        }
      });
  }

  get controls() {
    return this.payWay.controls;
  }

  submit(): void {
    if (this.payWay.valid) {
      this.submitDeposit();
    }
  }

  submitDeposit() {
    let account = this.payWay.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      depositMethodId: this.id,
      depositMethodSubtypeId: null,
      amount: Number(this.payWay.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.payWay.value.currency,
      extraParams: {},
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        const params = JSON.parse(res.data.extraParams)
        const {
          merchantID,
          signature,
          trans_amount,
          trans_currency,
          trans_installments,
          trans_type,
          trans_refNum,
          url_redirect
        } = params

        const URL =`https://uiservices.payola.ca/hosted/default.aspx?merchantId=${merchantID}&trans_type=${trans_type}&trans_refNum=${trans_refNum}&trans_installments=${trans_installments}&trans_amount=${trans_amount}&trans_currency=${trans_currency}&url_redirect=${encodeURIComponent(url_redirect)}&signature=${encodeURIComponent(signature)}`;

        window.location.href = URL;

      } else {
        this.errorMessage = res.message;
      }
    });
  }

  fireFormValidations(): void {
    this.payWay = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount_usd: [0],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
    });
  }

  set(ev) {
    this.payWay.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.payWay.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.payWay.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  getCurrencyRate() {
    this.payWay.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.payWay.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });
      const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };
      this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.payWay.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.payWay.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }
  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find((currency) => {
          return currency.name === selectedAccount.currencyIsoCode;
        });

        this.payWay.get("currency").setValue(activeAccountCurrency.id);
      }
    }
  }
}
