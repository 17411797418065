export enum ZotapayMethodEnum {
  AliPay = 1,
  UnionPay,
  WechatPay,
  Payout,
  ZotapayVietnam,
  ZotapayThailand
}

export enum ZotapayTypeEnum {
  zotapayChina = 1,
  zotapayVietnam,
  zotapayThailand
}