import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { GeneralService } from '../../../../services/general/general.service';
import { PageService } from '../../../../page.service';
import {Currency, Account, BasicInfo} from '../../../../interfaces';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: 'app-payboutique',
  templateUrl: './payboutique.component.html',
  styleUrls: ['./payboutique.component.scss']
})
export class PayboutiqueComponent implements OnInit, OnDestroy {

  payboutiqueForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validFullName = true;
  validIssuingCountry = true;
  validVisaNumber = true;
  validExpiryMonth = true;
  validExpiryYear = true;
  validCVV = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  activeAccount: object;
  fullName: string;
  // countries: object;
  currentType: object;
  currentMinDeposit: number;
  currencies: Currency[];
  newAccount: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  account: any;
  isActive: boolean;
  openNewAccount: boolean = false;
  isChina = false;
  id = '1'


  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private depositTypeService: DepositTypeService,
    private accountService: AccountService,
    private registrationService: RegistrationService,
    private accountTypeService: AccountTypeService,
    private generalService: GeneralService,
    private pageService: PageService,
  ) { }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.fireFormValidations();

    this.clientId = this.authService.getAuthClientId();

    this.accountService.getPspCurrency(this.id).subscribe(currencies => {
      this.currencies = currencies.data;
    });


    this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
      if (accounts.length > 0) {
        this.accounts = accounts;
        this.openNewAccount = false;
      } else {
        const value = 'Open a new account';
        this.payboutiqueForm.get('account').setValue(value);
        this.openNewAccount = true;
      }
    });

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
      if (activeAccount) {
        this.activeAccountNumber = activeAccount['account'];
        if (localStorage.getItem('tmp_account')) {
          this.payboutiqueForm.get('account').setValue(localStorage.getItem('tmp_account'));
          localStorage.removeItem(('tmp_account'));
        } else {
          this.payboutiqueForm.get('account').setValue(this.activeAccountNumber);
        }

        this.changeAccount(this.activeAccountNumber);
      }
    });

    // this.registrationService.getBasicInfo(this.clientId).subscribe(basicInfo => {
    //   this.fullName = basicInfo.firstName + ' ' + basicInfo.lastName;
    //   const issuingCountry = basicInfo.countryCode;
    //   //this.payboutiqueForm.get('issuing_country').setValue(issuingCountry);
    // });

    // this.generalService.getCountries().subscribe(countries => {
    //   this.countries = countries;
    // });
  }

  submit(): void {
    if (this.payboutiqueForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      if (this.payboutiqueForm.controls.account.value === 'Open a new account') {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.payboutiqueForm.value.currency,
          accountType: this.currentType['id'],
          leverage: 30
        };

        // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
        //   this.newAccount = res.account;
        //   this.accountService.updateAccounts(res.account);
        //   this.submitDeposit();
        // });
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
    }
  }

  get controls() { return this.payboutiqueForm.controls; }

  submitDeposit() {
    let account = this.payboutiqueForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      depositMethodId: '1',
      depositMethodSubtypeId: null,
      amount: Number(this.payboutiqueForm.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.payboutiqueForm.value.currency
    };

    this.depositTypeService.submitDeposit(data)
    .subscribe((res) => {
      if (res.status===1) {
        console.log(res);
      }
    });

    // this.accountService.submitDepositInfo(data).subscribe(res => {
    //   this.pageService.changeLoadingStatus(false);

    //   let messageName;

    //   switch (res['status']) {
    //     case 2:
    //       messageName = 'review';
    //       break;
    //     case 3:
    //       messageName = 'success';
    //       break;
    //     case 4:
    //       messageName = 'decline';
    //       break;
    //   }

    //   const selectedCurrency = this.currencies.find(currency => {
    //     return Number(this.payboutiqueForm.value.currency) === currency.id;
    //   });

    //   const depositData = {
    //     depositMethod: 'Payboutique',
    //     account: account,
    //     clientName: this.fullName,
    //     transactionDate: '',
    //     requestedAmount: this.payboutiqueForm.value.deposit_amount,
    //     currency: selectedCurrency.currencySymbol,
    //     status: messageName,
    //   };

    //   // TODO: change the way of doing this. Pass object to next component ?
    //   this.depositTypeService.setDepositInfo(depositData);
    //   this.accountService.updateAccounts();
    //   if(messageName==='success'){
    //     window.location.href = res['response'].redirectUrl;
    //   }else{
    //     this.router.navigate(['message', messageName]);
    //   }

    // });
  }

  fireFormValidations(): void {
    this.payboutiqueForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      deposit_amount: ['', [Validators.required, Validators.min(this.currentMinDeposit)]],
    });
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(depositAmount, this.payboutiqueForm.get('deposit_amount'));
    this.validDepositAmount = true;
  }

  ngOnDestroy() {

  }

  updateValidation() {
    this.payboutiqueForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
    this.payboutiqueForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {

      const selectedAccount = this.accounts.find(account => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });

        this.payboutiqueForm.get('currency').setValue(activeAccountCurrency.id);
      }
    }
  }

}
