import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';

export interface Data {
  data:{
    id:string,
    name: string
  }[],
  message: string,
  status: number
}
@Injectable()
export class PageService {
  brandList = new BehaviorSubject<any>([])
  brandListSubject = this.brandList.asObservable()


  brandShortlist = new BehaviorSubject<any>(0)
  brandShortlistSubject = this.brandShortlist.asObservable()

  private page = new BehaviorSubject('Financial Portrait');
  private loading = new BehaviorSubject(false);
  activePage = this.page.asObservable();
  loadingStatus = this.loading.asObservable();

  constructor() { }

  // getData(){
  //   return this.brandShortlistSubject as Observable<any>
  // }

  changePageName(page: string) {
    this.page.next(page);
  }

  changeLoadingStatus(showLoading) {
    this.loading.next(showLoading);
  }

}
