import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from '../../../page.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfessionalAccountService } from '../services/professional-account/professional-account.service';
import { RegistrationService } from '../../../auth/services/registration/registration.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { NotificationService } from '../../../services/notification/notification.service';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-professional-account-app',
  templateUrl: './professional-account-app.component.html',
  styleUrls: ['./professional-account-app.component.scss']
})
export class ProfessionalAccountAppComponent implements OnInit {

  professionalAppForm: FormGroup;
  validForm = true;
  legalName: string;
  clientId: number;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private pageService: PageService,
    private professionalAccountService: ProfessionalAccountService,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.professionalAppForm = this.formBuilder.group({
      options: this.formBuilder.group({
        first_option: [''],
        second_option: [''],
        third_option: [''],
      })
    });

    this.pageService.changePageName('App');

    // const mainInfo = this.registrationService.getMainInfo();
    // this.legalName = mainInfo['legalName'];

    this.clientId = this.authService.getAuthClientId();

    // On Dropping page
    window.onbeforeunload = () => {
      const data = {
        clientId: this.clientId,
        step: 1,
      };

      // this.notificationService.appropriatenessTestDropped(data);
    };
  }

  submit(): void {
    if (this.checkTwoIsChecked() === null) {
      this.validForm = true;
      this.professionalAccountService.setChosenApplications(this.professionalAppForm.controls.options.value);
      this.router.navigate(['profile', 'professional-account']);
    } else {
      this.validForm = false;
    }
  }

  get controls() { return this.professionalAppForm.controls; }

  checkTwoIsChecked(): object | null {
    const minCount = 2;
    let chosenCount = 0;
    const options = this.professionalAppForm.controls.options.value;

    for (const option in options) {
      if (options.hasOwnProperty(option)) {
        if (options[option] === true) {
          chosenCount++;
        }
      }
    }

    if (chosenCount >= minCount) {
      return null;
    } else {
      return {'notCompleted': true};
    }
  }

}
