import { IpAccessService } from "./dashboard/services/ipAccess/ip-access.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
// import { TokenInterceptor } from './auth/interceptors/token.interceptor';
import { AuthGuardService } from "./auth/services/auth-guard.service";
import { GuestGuardService } from "./auth/services/guest-guard.service";
import { RegistrationGuardService } from "./auth/services/registration-guard.service";
import { RegistrationFirstStepNotPassedGuard } from "./auth/services/registration-first-step-not-passed-guard.service";
import { FileDropModule } from "ngx-file-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule, ROUTED_COMPONENTS } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LoginComponent } from "./auth/login/login.component";
import { RegistrationStep1Component } from "./auth/registration-step1/registration-step1.component";
import { RegistrationStep2Component } from "./auth/registration-step2/registration-step2.component";
import { AuthComponent } from "./auth/auth.component";
import { AccountTypeComponent } from "./dashboard/info/account-type/account-type.component";
import { AccountTypesComponent } from "./dashboard/account-types/account-types.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProfessionalAccountComponent } from "./dashboard/professional-account-layout/professional-account/professional-account.component";
import { ProfessionalAccountAppComponent } from "./dashboard/professional-account-layout/professional-account-app/professional-account-app.component";
import { HomeComponent } from "./dashboard/home/home.component";
import { OpenAdditionalAccountComponent } from "./dashboard/open-additional-account/open-additional-account.component";
import { DepositTypeComponent } from "./dashboard/info/deposit-type/deposit-type.component";
import { CreditCardComponent } from "./dashboard/info/deposit-type/credit-card/credit-card.component";
import { SkrillComponent } from "./dashboard/info/deposit-type/skrill/skrill.component";
import { NetellerComponent } from "./dashboard/info/deposit-type/neteller/neteller.component";
import { FasapayComponent } from "./dashboard/info/deposit-type/fasapay/fasapay.component";
import { ZotapayComponent } from "./dashboard/info/deposit-type/zotapay/zotapay.component";
import { SofortComponent } from "./dashboard/info/deposit-type/sofort/sofort.component";
import { DotpayComponent } from "./dashboard/info/deposit-type/dotpay/dotpay.component";
import { PayboutiqueComponent } from "./dashboard/info/deposit-type/payboutique/payboutique.component";
import { WireTransferComponent } from "./dashboard/info/deposit-type/wire-transfer/wire-transfer.component";
import { VerifyAccountLayoutComponent } from "./dashboard/verify-account-layout/verify-account-layout.component";
import { VerifyAccountComponent } from "./dashboard/verify-account-layout/verify-account/verify-account.component";
import { AppropriatenessTestComponent } from "./dashboard/verify-account-layout/appropriateness-test/appropriateness-test.component";
import { FinancialPortraitComponent } from "./dashboard/verify-account-layout/appropriateness-test/financial-portrait/financial-portrait.component";
import { LevelOfKnowledgeComponent } from "./dashboard/verify-account-layout/appropriateness-test/level-of-knowledge/level-of-knowledge.component";
import { LevelOfExperienceComponent } from "./dashboard/verify-account-layout/appropriateness-test/level-of-experience/level-of-experience.component";
import { MessageComponent } from "./dashboard/info/deposit-type/credit-card/message/message.component";
import { DeclineMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/decline-message/decline-message.component";
import { SuccessMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/success-message/success-message.component";
import { ReviewMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/review-message/review-message.component";
import { ThankYouMessageComponent } from "./dashboard/info/deposit-type/credit-card/message/thank-you-message/thank-you-message.component";
import { AccountsComponent } from "./dashboard/accounts/accounts.component";
import { InfoComponent } from "./dashboard/info/info.component";
import { AccountTypeService } from "./dashboard/info/account-type/services/account-type.service";
import { PageService } from "./page.service";
import { ProfessionalAccountLayoutComponent } from "./dashboard/professional-account-layout/professional-account-layout.component";
import { AuthService } from "./auth/services/auth/auth.service";
import { TokenService } from "./auth/services/token/token.service";
import { RegistrationService } from "./auth/services/registration/registration.service";
import { EditProfileComponent } from "./dashboard/edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./dashboard/change-password/change-password.component";
import { NationalIdComponent } from "./dashboard/national-id/national-id.component";
import { TaxIdComponent } from "./dashboard/verify-account-layout/tax-id/tax-id.component";
import { DownloadComponent } from "./dashboard/download/download.component";
import { ManageFundsComponent } from "./dashboard/manage-funds/manage-funds.component";
import { WithdrawalComponent } from "./dashboard/withdrawal/withdrawal.component";
import { WithdrawalStep2Component } from "./dashboard/withdrawal-step2/withdrawal-step2.component";
import { WithdrawalStep3Component } from './dashboard/withdrawal-step3/withdrawal-step3.component';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { SubscriptionComponent } from "./dashboard/subscription/subscription.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { WireStep2Component } from "./dashboard/info/deposit-type/wire-step2/wire-step2.component";
import { OpenDemoAccountComponent } from "./dashboard/open-demo-account/open-demo-account.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToasterModule, ToasterService } from "angular2-toaster";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { ResetComponent } from "./auth/reset/reset.component";
import { AppropriatenessTestService } from "./dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { ResultMessageComponent } from "./dashboard/verify-account-layout/appropriateness-test/modals/result-message/result-message.component";
import { LanguagesComponent } from "./languages/languages.component";
// import { Ng2TelInputModule } from "ng2-tel-input";
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input'
import { RedirectToRegistrationStepTwoComponent } from "./auth/redirect-to-registration-step-two/redirect-to-registration-step-two.component";
import { TwoDecimalsPipe } from "./pipes/two-decimals.pipe";
import { VerifyResetPasswordComponent } from "./auth/verify-reset-password/verify-reset-password.component";
import { UserIdleModule } from "angular-user-idle";
import { BitcoinComponent } from "./dashboard/info/deposit-type/bitcoin/bitcoin.component";
import { AppTestPassedGuardService } from "./auth/services/app-test-passed-guard.service";
import { IcryptFxcmComponent } from "./dashboard/info/deposit-type/icrypt-fxcm/icrypt-fxcm.component";
import { ViewDocumentComponent } from "./dashboard/verify-account-layout/verify-account/modals/view-document/view-document.component";
import { HttpClientInterceptorService } from "./services/http-client-interceptor.service";
import { DepositResultComponent } from "./dashboard/info/deposit-type/deposit-result/deposit-result.component";
import { NoticeUponMakingADepositComponent } from "./dashboard/info/deposit-type/modals/notice-upon-making-a-deposit/notice-upon-making-a-deposit.component";
import { WonderlandComponent } from "./dashboard/info/deposit-type/wonderland/wonderland.component";
import { EftComponent } from "./dashboard/info/deposit-type/eft/eft.component";
import { ScriptHackComponent } from "./shared/script-hack/script-hack.component";
import { InterkassaComponent } from './dashboard/info/deposit-type/interkassa/interkassa.component';
import { TrustlyComponent } from "./dashboard/info/deposit-type/trustly/trustly.component";
import { SupremCashComponent } from './dashboard/info/deposit-type/suprem-cash/suprem-cash.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { ZotapayVietnamComponent } from './dashboard/info/deposit-type/zotapay-vietnam/zotapay-vietnam.component';
import { ZotapayThailandComponent } from './dashboard/info/deposit-type/zotapay-thailand/zotapay-thailand.component';
import { BecomePartnerComponent } from './dashboard/become-partner/become-partner.component';
import { SuccessPopupComponent } from './dashboard/become-partner/success-popup/success-popup.component';
import { AlreadyPartnerPopupComponent } from './dashboard/become-partner/already-partner-popup/already-partner-popup.component';
import { LegalDocumentationComponent } from './dashboard/legal-documentation/legal-documentation.component';
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import { NewHttpClientService } from "./services/new-http-client.service";
import {
  CarouselModule,
} from 'ngx-bootstrap/carousel';
import { SafeChargeComponent } from './dashboard/info/deposit-type/safe-charge/safe-charge.component';
import { ReadMorePipe } from './pipes/read-more.pipe';
import { CommentPopupComponent } from './dashboard/verify-account-layout/verify-account/modals/comment-popup/comment-popup.component';
import { CardPayComponent } from './dashboard/info/deposit-type/card-pay/card-pay.component';
import { DepositResponseComponent } from './dashboard/info/deposit-response/deposit-response/deposit-response.component';
import { WithdrawalResponseComponent } from './dashboard/withdrawal-step2/components/withdrawal-response/withdrawal-response.component';
import { EuCountriesComponent } from "./auth/registration-step1/popup/eu-countries/eu-countries.component";
import { SupremModalComponent } from './dashboard/info/deposit-type/suprem-cash/suprem-modal/suprem-modal.component';
import { SorinXeComponent } from './dashboard/info/deposit-type/sorin-xe/sorin-xe.component';
import { BankWireComponent } from './dashboard/info/deposit-type/bank-wire/bank-wire/bank-wire.component';
import { EPayComponent } from './dashboard/info/deposit-type/e-pay/e-pay.component';
import { PayNowComponent } from './dashboard/info/deposit-type/pay-now/pay-now.component';
import { ChargeMoneyComponent } from './dashboard/info/deposit-type/charge-money/charge-money.component';
// AoT requires an exported function for factories
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CreatedMt5AccountComponent } from './dashboard/open-additional-account/created-mt5-account/created-mt5-account.component';
import { TsPaymentsComponent } from './dashboard/info/deposit-type/ts-payments/ts-payments.component';
import { PayWayComponent } from './dashboard/info/deposit-type/pay-way/pay-way.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    EuCountriesComponent,
    AppComponent,
    LoginComponent,
    ROUTED_COMPONENTS,
    RegistrationStep1Component,
    RegistrationStep2Component,
    AuthComponent,
    AccountTypeComponent,
    AccountTypesComponent,
    DashboardComponent,
    ProfessionalAccountComponent,
    ProfessionalAccountAppComponent,
    HomeComponent,
    OpenAdditionalAccountComponent,
    DepositTypeComponent,
    CreditCardComponent,
    SkrillComponent,
    NetellerComponent,
    DepositResultComponent,
    FasapayComponent,
    ZotapayComponent,
    SofortComponent,
    DotpayComponent,
    PayboutiqueComponent,
    WireTransferComponent,
    AppropriatenessTestComponent,
    VerifyAccountComponent,
    MessageComponent,
    DeclineMessageComponent,
    SuccessMessageComponent,
    ReviewMessageComponent,
    ThankYouMessageComponent,
    AccountsComponent,
    InfoComponent,
    FinancialPortraitComponent,
    LevelOfKnowledgeComponent,
    LevelOfExperienceComponent,
    ProfessionalAccountLayoutComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    NationalIdComponent,
    TaxIdComponent,
    DownloadComponent,
    ManageFundsComponent,
    WithdrawalComponent,
    WithdrawalStep2Component,
    SubscriptionComponent,
    WireStep2Component,
    VerifyAccountLayoutComponent,
    OpenDemoAccountComponent,
    ResetPasswordComponent,
    ResetComponent,
    ResultMessageComponent,
    LanguagesComponent,
    RedirectToRegistrationStepTwoComponent,
    TwoDecimalsPipe,
    VerifyResetPasswordComponent,
    BitcoinComponent,
    IcryptFxcmComponent,
    ViewDocumentComponent,
    NoticeUponMakingADepositComponent,
    WonderlandComponent,
    EftComponent,
    ScriptHackComponent,
    InterkassaComponent,
    WithdrawalStep3Component,
    TrustlyComponent,
    SupremCashComponent,
    ReportsComponent,
    ZotapayVietnamComponent,
    ZotapayThailandComponent,
    BecomePartnerComponent,
    SuccessPopupComponent,
    AlreadyPartnerPopupComponent,
    LegalDocumentationComponent,
    EmailVerificationComponent,
    SafeChargeComponent,
    ReadMorePipe,
    CommentPopupComponent,
    CardPayComponent,
    DepositResponseComponent,
    WithdrawalResponseComponent,
    SupremModalComponent,
    SorinXeComponent,
    BankWireComponent,
    EPayComponent,
    PayNowComponent,
    ChargeMoneyComponent,
    CreatedMt5AccountComponent,
    TsPaymentsComponent,
    PayWayComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FileDropModule,
    ReactiveFormsModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    ToasterModule.forRoot(),
    //Ng2TelInputModule,
    NgxIntlTelInputModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({ idle: 1800, timeout: 1, ping: 60 }),
    TooltipModule.forRoot()
  ],
  providers: [
    AccountTypeService,
    PageService,
    AuthService,
    TokenService,
    AuthGuardService,
    GuestGuardService,
    RegistrationGuardService,
    RegistrationFirstStepNotPassedGuard,
    AppTestPassedGuardService,
    RegistrationService,
    AppropriatenessTestService,
    IpAccessService,
    NewHttpClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptorService,
      multi: true
    }
  ],
  entryComponents: [
    EuCountriesComponent,
    ResultMessageComponent,
    ViewDocumentComponent,
    NoticeUponMakingADepositComponent,
    SuccessPopupComponent,
    AlreadyPartnerPopupComponent,
    CommentPopupComponent,
    SupremModalComponent,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
