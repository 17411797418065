import { BrandService } from "src/app/auth/services/brand/brand.service";
import { Component, ElementRef, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/auth/services/auth/auth.service";
import { AccountService } from "src/app/dashboard/services/account/account.service";
import { Currency, Account } from "src/app/interfaces";
import { DepositTypeService } from "../services/deposit-type.service";
import { BrandIdEnum } from "src/app/brand.enum";
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: "app-card-pay",
  templateUrl: "./card-pay.component.html",
  styleUrls: ["./card-pay.component.scss"],
})
export class CardPayComponent implements OnInit {
  cardPay: FormGroup;
  validAccount = true;
  validCurrency = true;
  validFullName = true;
  validIssuingCountry = true;
  validVisaNumber = true;
  validExpiryMonth = true;
  validExpiryYear = true;
  validCVV = true;
  validDepositAmount = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  activeAccount: object;
  fullName: string;
  countries: object;
  currentType: object;
  currentMinDeposit: number ;
  currencies: Currency[];
  newAccount: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  account: any;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount = false;
  errorMessage: string;
  isChina = false;

  id = "10";

  exChange = 1;

  modal = false;

  constructor(
    private langService: LanguageService,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private depositTypeService: DepositTypeService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.fireFormValidations();
    this.clientId = this.authService.getAuthClientId();

    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    // this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
    //   if (accounts.length > 0) {
    //     this.accounts = accounts;
    //     this.openNewAccount = false;
    //   } else {
    //     const value = 'Open a new account';
    //     this.cardPay.get('account').setValue(value);
    //     this.openNewAccount = true;
    //   }
    // });

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });

    this.getDepositMethodeSubtypeShortList();
  }

  getDepositMethodeSubtypeShortList() {
    this.depositTypeService
      .depositMethodeSubtypeShortList(this.id)
      .subscribe((res) => {
        if (res.status === 1) {
          // this.depositMethodeSubtypeShortList = res.data
          console.log(res.data);
        }
      });
  }

  get controls() {
    return this.cardPay.controls;
  }

  submit(): void {
    if (this.cardPay.valid) {
      this.submitDeposit();
    } else {
      this.validAccount = this.controls["account"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validFullName = this.controls["full_name"].valid;
      this.validIssuingCountry = this.controls["issuing_country"].valid;
      this.validVisaNumber = this.controls["card_number"].valid;
      this.validExpiryMonth = this.controls["expiry_month"].valid;
      this.validExpiryYear = this.controls["expiry_year"].valid;
      this.validCVV = this.controls["CVV"].valid;
      this.validDepositAmount = this.controls["deposit_amount"].valid;
    }
  }

  submitDeposit() {
    let account = this.cardPay.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }

    const data = {
      depositMethodId: this.id,
      depositMethodSubtypeId: null,
      amount: Number(this.cardPay.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.cardPay.value.currency,
      extraParams: {},
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        window.location.href = res.data.extraParams;
      } else {
        this.errorMessage = res.message;
      }
    });
  }

  fireFormValidations(): void {
    this.cardPay = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount_usd: [0],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
    });
  }

  set(ev) {
    this.cardPay.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.cardPay.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.cardPay.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  // onAccountChange(ev){
  //   this.getDepositAmount(ev.target.value)
  //   this.getCurrencyRate()
  // }

  // getDepositAmount(clientAccountId){
  //   this.depositTypeService
  //   .getDepositAmount(clientAccountId)
  //   .subscribe((result) => {
  //     if (!result.data.madeDeposit) {
  //       this.currentMinDeposit = result.data.minDepositAmount;
  //       this.cardPay.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
  //     }
  //   });
  // }

  getCurrencyRate() {
    this.cardPay.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.cardPay.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });
      const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };
      this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.cardPay.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.cardPay.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }
  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });

      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find((currency) => {
          return currency.name === selectedAccount.currencyIsoCode;
        });

        this.cardPay.get("currency").setValue(activeAccountCurrency.id);
      }
    }
  }
}
