import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '../../../../page.service';
import { AppropriatenessTestService } from '../sevices/appropriateness-test.service';
import { FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResultMessageComponent } from '../modals/result-message/result-message.component';
import {
  faCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { BrandService } from 'src/app/auth/services/brand/brand.service';

@Component({
  selector: 'app-level-of-experience',
  templateUrl: './level-of-experience.component.html',
  styleUrls: ['./level-of-experience.component.scss']
})
export class LevelOfExperienceComponent implements OnInit, OnDestroy {

  questionsForm: FormGroup;
  validForm = true;
  pageName = 'Level of Experience';
  tests: object;
  chooseOptionsArray = [];
  clientId: number;
  showAgreeDeclineButtons: boolean;
  showContinueButton: boolean;
  showOKButton: boolean;
  questions: object[];
  faCircle = faCircle;
  data =[];
  tempQuestions =[]
  faCheckCircle = faCheckCircle;
  private answersSubscription;
  private testsSubscription;
  finalPortraitAnswer;
  levelOfKnowldedge;
  answers;
  resultOfAllAnswers;
  currentWebsitesBrandId:number;
  resultMessage;
  public score : number = 0;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private modalService: NgbModal,
    private brandService: BrandService
  ) { }

  ngOnInit() {
    // this.currentWebsitesBrandId = this.brandService.getCurrentWebsitesBrandId();


    window.scroll(0, 0);

    this.questionsForm = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    });

    this.pageService.changePageName(this.pageName);



    // this.appropriatenessTestService.getAppropriatnesstTest(1).subscribe(tests => {

    //   this.finalPortraitAnswer = this.appropriatenessTestService.getFinalPortraitAnswers();
    //   this.levelOfKnowldedge = this.appropriatenessTestService.getLevelOfKnowledge();

    //   this.answers = [...this.finalPortraitAnswer, ...this.levelOfKnowldedge];

    //   var questions = tests['data'];
    //   questions.forEach(q=>{
    //     if(q.questionType === 2){
    //       this.tempQuestions.push(q)
    //     }
    //   })

    //   this.tempQuestions.forEach(() => {
    //       (this.questionsForm.controls['questions'] as FormArray).push(this.formBuilder.group({
    //         AnswerId: ['', Validators.required],
    //         QuestionId: ['',],

    //       }))
    //     })
    //   this.tests = tests;
    //   this.questions=this.tempQuestions;
    // })

    this.clientId = this.authService.getAuthClientId();

    // this.appropriatenessTestService.getAppropriatnesstTest(1).subscribe(tests => {
    //   this.data=tests['data']
    // })
  }

  submit(): void {
    if (this.questionsForm.valid) {
      const newData = this.questionsForm.value.questions;
      this.appropriatenessTestService.addAnswers(newData);
      this.resultOfAllAnswers = [...this.answers, ...newData]
      let res=this.resultOfAllAnswers.map((item)=>{
        item.AnswerId = +item.AnswerId;
        item.QuestionId = +item.QuestionId;
        item.AnswerText = item.AnswerText;
        return item
      })

      let data ={}
      data['BrandId'] = this.currentWebsitesBrandId;
      data['ClientId'] = this.clientId;
      // data['ClientTestCounter'] = 1;
      data['TestAnswers'] = res

      // this.appropriatenessTestService.setAppropriatnessTestQuestionAnswers(data).subscribe(riskLevel=>{
      //   this.resultOfAllAnswers = riskLevel['data'].clientRiskLevel

      //   // this.appropriatenessTestService.getClientTestAnswers(this.clientId).subscribe(result => {
      //     this.appropriatenessTestService.getClientTestScore(this.clientId).subscribe(result => {
      //       var s = JSON.stringify(result);
      //       var d = parseInt(s);
      //       this.score =  d;
      //       this.openModal();
      //     })
      //   })
    }
  }

  get controls() { return this.questionsForm.controls; }

  openModal() {
    const modalRef = this.modalService.open(ResultMessageComponent, { size: 'lg' });
    // modalRef.componentInstance.legalName = this.resultOfAllAnswers;
    // TODO: improvement - have only one variable "showButton" (AgreeAndDecline, Continue, OK)
    // Medium Upper Medium Level
    if(this.score >= 21 && this.score <= 80 ) {
      modalRef.componentInstance.showAgreeDeclineButtons = true;
      modalRef.componentInstance.legalName = this.resultOfAllAnswers;
    }
    else {
      modalRef.componentInstance.showOKButton = true;
      modalRef.componentInstance.legalName = this.resultOfAllAnswers;
    }

    // Low High Score





    // modalRef.componentInstance.showContinueButton = this.showContinueButton;



  }

  ngOnDestroy() {
    this.testsSubscription.unsubscribe();
    this.testsSubscription.complete();
    if ('undefined' !== typeof this.answersSubscription) {
      this.answersSubscription.unsubscribe();
      this.answersSubscription.complete();
    }
  }

}
