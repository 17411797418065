import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../auth/services/auth/auth.service';
import { RegistrationService } from '../../../../../auth/services/registration/registration.service';
import { AppropriatenessTestService } from '../../sevices/appropriateness-test.service';

@Component({
  selector: 'app-ngbd-modal-content',
  templateUrl: './result-message.component.html'
})
export class ResultMessageComponent {

  // TODO ADD TYPES
  @Input() data;

  info;
  clientId;


  constructor(
    public router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private appTestService: AppropriatenessTestService

  ) {
    this.clientId = this.authService.getAuthClientId();



    // TODO: find a way to do this without setTimeout
    setTimeout(() => {
      this.info = this.data;
    }, 500);
  }

  // TODO May USE one single function
  decline() {
    const payload = {
      action : 2
    };
    this.appTestService.setAppTestStatusByPopUpAction(this.clientId, payload)
    .subscribe(res => {
      if (res.status === 1) {
        this.modalService.dismissAll(ResultMessageComponent);
        this.router.navigate(['/home']);
      }
    });
  }

  agree() {
    const payload = {
      action : 1
    };
    this.appTestService.setAppTestStatusByPopUpAction(this.clientId, payload)
    .subscribe(res => {
      if (res.status === 1) {
        this.modalService.dismissAll(ResultMessageComponent);
        this.router.navigate(['/verify-account']);
      }
    });
  }

  dismiss() {
    this.modalService.dismissAll(ResultMessageComponent);
    this.router.navigate(['/verify-account']);
  }

}
