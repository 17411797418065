import { NewHttpClientService } from "./../../../services/new-http-client.service";
import { Injectable } from "@angular/core";
// import { HttpClientService } from '../../../services/http-client.service';
import { HttpClient } from "@angular/common/http";
import { PageService } from "../../../page.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  Client,
  BasicInfo,
  AddressInfo,
  TaxId,
  Brand,
  AppTestLevel,
  AppTest,
  DotPayChannel,
  Country,
  Document,
  BrandRegistrationStep,
  ResetPassword,
} from "../../../interfaces";
import { AppropriatenessTestService } from "src/app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";

@Injectable({
  providedIn: "root",
})
export class RegistrationService {
  prefix = "Registration";

  newPrefix = "client";

  constructor(
    private newHttp: NewHttpClientService,
    // private http: HttpClientService,
    private rawHttp: HttpClient,
    private pageService: PageService,
    private router: Router,
    private appropriatenessTestService: AppropriatenessTestService
  ) {}

  /// New API
  registrationPersonal(options): Observable<any> {
    const url = `${this.newPrefix}/registration`;
    return this.newHttp.post<any>(url, options);
  }
  registrationAdress(clientId, options): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/registration`;
    return this.newHttp.put<any>(url, options);
  }

  logIn(options): Observable<any> {
    const url = `${this.newPrefix}/login`;
    return this.newHttp.post<any>(url, options);
  }

  autoLogIn(options): Observable<any> {
    const url = `${this.newPrefix}/autologin`;
    return this.newHttp.post<any>(url, options);
  }

  loginNotAllowedInfo(): Observable<any> {
    const url = `${this.newPrefix}/LoginNotAllowedInfo`;
    return this.newHttp.get<any>(url);
  }

  isActionAllowed(clientId: number, actionId: 1 | 2 | 3): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/action/${actionId}/allowed`;
    return this.newHttp.get<any>(url);
  }

  verifyEmail(clientId): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/registration/verification`;
    return this.newHttp.patch<any>(url, null);
  }

  verifyCode(clientId, payload): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/registration/verification`;
    return this.newHttp.put<any>(url, payload);
  }

  resetPass(payload): Observable<any> {
    const url = `${this.newPrefix}/resetPassword`;
    return this.newHttp.post<any>(url, payload);
  }

  verifyResetPass(clientId, payload): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/resetPassword`;
    return this.newHttp.put<any>(url, payload);
  }

  mt4ResetPass(payload): Observable<any> {
    const url = `AccountPortal/ChangeClientMT4Password`;
    return this.newHttp.put<any>(url, payload);
  }

  updateClientMT4InvestorPasswor(payload): Observable<any> {
    const url = `AccountPortal/ChangeClientMT4InvestorPassword`;
    return this.newHttp.put<any>(url, payload);
  }
  createDemoAccount(): Observable<any> {
    const url = `accountPortal/CreateDemoAccount`;
    return this.newHttp.post<any>(url, null);
  }

  getClientReqDocs(clientId): Observable<any> {
    const url = `${this.newPrefix}/${clientId}`;
    return this.newHttp.get<any>(url);
  }

  getDocumentById(clientId, documentId): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/requiredDocument/${documentId}`;
    return this.newHttp.get<any>(url);
  }

  addDocumentById(clientId, documentId, payload): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/requiredDocument/${documentId}/upload`;
    return this.newHttp.post<any>(url, payload);
  }

  getPersonalInfo(): Observable<any> {
    const url = `${this.newPrefix}/GetCientPersonalInfo`;
    return this.newHttp.get<any>(url);
  }

  getIbPartnerInfo(clientId): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/ReadIbPartnerInfo`;
    return this.newHttp.get<any>(url);
  }

  becomePartner(clientId): Observable<any> {
    const url = `${this.newPrefix}/${clientId}/createIbPartner`;
    return this.newHttp.post<any>(url, null);
  }

  getBrandRegistrationDocumentShortList(): Observable<any> {
    const url = `${this.newPrefix}/brand/registrationDocument/shortList`;
    return this.newHttp.get<any>(url);
  }

  getBrandRegistrationDocumentById(documentId: number): Observable<any> {
    const url = `${this.newPrefix}/brand/document/${documentId}`;
    return this.newHttp.get<any>(url);
  }

  getIbDoc(id: number): Observable<any> {
    const url = `Document/${id}`;
    return this.newHttp.get<any>(url);
  }

  checkIsEUCountryPopUpInfo(countryIsoCode): Observable<any> {
    const url = `public/CheckEUCountry/${countryIsoCode}`;
    return this.newHttp.get<any>(url);
  }

  /////////////////

  // TODO: remove ?
  // public checkOldSystem(email: string, brandId: number) {
  //   return this.rawHttp.post('https://my.fxglobe.com/en/migrate/get_ref_data', {
  //     brand_id: brandId,
  //     email: email,
  //   });
  // }

  // public createClient(data: BasicInfo): Observable<BasicInfo> {
  //   return this.http.post<BasicInfo>(this.prefix + '/CreateClient', data);
  // }

  // public updateAddressInfo(data: AddressInfo): Observable<AddressInfo> {
  //   return this.http.put<AddressInfo>(this.prefix + '/UpdateAddressInfo', data);
  // }

  // public getClientBasicInfo(clientId: number): Observable<Client> {
  //   return this.http.get<Client>(this.prefix + '/GetClientBasicInfo?clientId=' + clientId);
  // }

  // public getBasicInfo(clientId): Observable<BasicInfo> {
  //   return this.http.get<BasicInfo>(this.prefix + '/GetBasicInfo?clientId=' + clientId);
  // }

  // public updateBasicInfo(data): Observable<BasicInfo> {
  //   return this.http.put<BasicInfo>(this.prefix + '/UpdateBasicInfo', data);
  // }

  // public setTaxId(data: TaxId[]): Observable<TaxId[]> {
  //   return this.http.post<TaxId[]>(this.prefix + '/SetTaxIds?clientId=' + data[0].clientId, data);
  // }

  // public acceptTermsAndCond(data): Observable<any> {
  //   return this.http.post<any>(this.prefix + '/AcceptTermsAndCond', data);
  // }

  // public getTermsAndCondFlag(clientId): Observable<any> {
  //   return this.http.get<any>(this.prefix + '/GetTermsAndCondFlag?clientId=' + clientId);
  // }

  // public getBrands(hostname: string): Observable<Brand[]> {
  //   return this.http.get<Brand[]>('healthcheck');
  // }

  // public getAppropriatenessTestLevel(clientId: number): Observable<AppTestLevel> {
  //   return this.http.get<AppTestLevel>(this.prefix + '/GetAppropriatenessTestLevel?clientId=' + clientId);
  // }

  // public resetPassword(data): Observable<ResetPassword> {
  //   return this.http.post<ResetPassword>(this.prefix + '/ResetPassword', data);
  // }

  // public verifyResetPassword(data) {
  //   return this.http.post(this.prefix + '/VerifyResetPassword', data);
  // }

  // public changePassword(data) {
  //   return this.http.post(this.prefix + '/ChangePassword', data);
  // }

  // public addAppropriatenessTest(data: AppTest): Observable<AppTest> {
  //   return this.http.post<AppTest>(this.prefix + '/AddAppropriatenessTest', data);
  // }

  // public getClientRequiredDocuments(clientId: number): Observable<Document[]> {
  //   return this.http.get<Document[]>(this.prefix + '/GetClientRequiredDocuments?clientId=' + clientId);
  // }

  // public getAccountRequiredDocuments(clientId: number) {
  //   return this.http.get(this.prefix + '/GetAccountRequiredDocuments?clientId=' + clientId);
  // }

  // public uploadFile(clientId, documentTypeId, file) {
  //   return this.http.post(this.prefix + '/UploadFile?clientId=' + clientId + '&documentType=' + documentTypeId, file);
  // }

  // public getDocument(documentId) {
  //   return this.http.post(this.prefix + '/Document?id=' + documentId, [], {responseType: 'blob'});
  // }

  // public getDotPayChannels(isTest: boolean): Observable<DotPayChannel[]> {
  //   // TODO: change the method of this API call to "get" (instead of "put")
  //   return this.http.put<DotPayChannel[]>(this.prefix + '/GetDotPayChannels?isTest=' + isTest, null);
  // }

  // public getBrandRegistrationSteps(brandId: number): Observable<BrandRegistrationStep[]> {
  //   return this.http.get<BrandRegistrationStep[]>(this.prefix + '/GetBrandRegistrationSteps?brandId=' + brandId);
  // }

  // public getBlockedCountries(brandId: number): Observable<Country[]> {
  //   return this.http.get<Country[]>(this.prefix + '/GetBlockedCountries?brandId=' + brandId);
  // }

  // public sendVerifyEmail(clientId: number): Observable<boolean> {
  //   return this.rawHttp.post<boolean>(`https://form.mgflex.com/EmailVerification/SendVerifyEmail/${clientId}`, null);
  // }

  public getPageRouteByName(name: string): string {
    let pageRoute;
    switch (name) {
      case "Deposit":
        pageRoute = "deposit-type";
        break;
      case "Step 4":
        pageRoute = "verify-account/appropriateness-test";
        break;
      case "Step 5":
        pageRoute = "verify-account/tax-id";
        break;
      default:
        pageRoute = "deposit-type";
    }

    return pageRoute;
  }

  public redirectToNextRegistrationStep() {
    // const mainInfo = this.getMainInfo();
    // const brandId = mainInfo['brandId'];

    if (localStorage.getItem("registrationStep") !== null) {
      const currentStep = Number(localStorage.getItem("registrationStep"));

      // this.getBrandRegistrationSteps(brandId).subscribe((registrationSteps: BrandRegistrationStep[]) => {

      //   const lastStep = registrationSteps.length - 1;

      //   const nextStep = currentStep + 1;

      //   if (nextStep === lastStep) {
      //     localStorage.removeItem('registrationStep');
      //   } else {
      //     localStorage.setItem('registrationStep', nextStep.toString());
      //   }

      //   const pageName = registrationSteps[nextStep]['pageName'];

      //   const pageRoute = this.getPageRouteByName(pageName);

      //   if (pageRoute === 'verify-account/appropriateness-test') {
      //     const client = JSON.parse(localStorage.getItem('client'));
      //     // this.getAppropriatenessTestLevel(client.id).subscribe((res: AppTestLevel) => {
      //       // TODO HARDCODED FIX IT CORRECTLY
      //       // this.appropriatenessTestService.isComplianceSettingsAppropraitnessTestEnabled(brandId).subscribe((test: boolean) => {
      //       //   if (!test) {
      //       //     this.redirectToNextRegistrationStep();
      //       //   } else {
      //       //     this.pageService.changeLoadingStatus(false);
      //       //     this.router.navigateByUrl(pageRoute);
      //       //   }
      //       // });
      //     // });
      //   } else {
      //     this.pageService.changeLoadingStatus(false);
      //     this.router.navigateByUrl(pageRoute);
      //   }
      // });
    } else {
      // this.pageService.changeLoadingStatus(false);
      this.router.navigateByUrl("/home");
    }
  }

  public getIso3166CountryCode(code: string) {
    switch (code.toLowerCase()) {
      case "af":
        return "AFG";
      case "ax":
        return "ALA";
      case "al":
        return "ALB";
      case "dz":
        return "DZA";
      case "as":
        return "ASM";
      case "ad":
        return "AND";
      case "ao":
        return "AGO";
      case "ai":
        return "AIA";
      case "aq":
        return "ATA";
      case "ag":
        return "ATG";
      case "ar":
        return "ARG";
      case "am":
        return "ARM";
      case "aw":
        return "ABW";
      case "au":
        return "AUS";
      case "at":
        return "AUT";
      case "az":
        return "AZE";
      case "bs":
        return "BHS";
      case "bh":
        return "BHR";
      case "bd":
        return "BGD";
      case "bb":
        return "BRB";
      case "by":
        return "BLR";
      case "be":
        return "BEL";
      case "bz":
        return "BLZ";
      case "bj":
        return "BEN";
      case "bm":
        return "BMU";
      case "bt":
        return "BTN";
      case "bo":
        return "BOL";
      case "ba":
        return "BIH";
      case "bw":
        return "BWA";
      case "bv":
        return "BVT";
      case "br":
        return "BRA";
      case "io":
        return "IOT";
      case "bn":
        return "BRN";
      case "bg":
        return "BGR";
      case "bf":
        return "BFA";
      case "bi":
        return "BDI";
      case "kh":
        return "KHM";
      case "cm":
        return "CMR";
      case "ca":
        return "CDN";
      case "cv":
        return "CPV";
      case "ky":
        return "CYM";
      case "cf":
        return "RCA";
      case "td":
        return "TCD";
      case "cl":
        return "RCH";
      case "cn":
        return "CHN";
      case "cx":
        return "CXR";
      case "cc":
        return "CCK";
      case "co":
        return "COL";
      case "km":
        return "COM";
      case "cg":
        return "COG";
      case "cd":
        return "COD";
      case "ck":
        return "COK";
      case "cr":
        return "CRI";
      case "ci":
        return "CIV";
      case "hr":
        return "HRV";
      case "cu":
        return "CUB";
      case "cy":
        return "CYP";
      case "cz":
        return "CZE";
      case "dk":
        return "DNK";
      case "dj":
        return "DJI";
      case "dm":
        return "DMA";
      case "do":
        return "DOM";
      case "ec":
        return "ECU";
      case "eg":
        return "EGY";
      case "sv":
        return "SLV";
      case "eq":
        return "";
      case "er":
        return "ERI";
      case "ee":
        return "EST";
      case "et":
        return "ETH";
      case "fk":
        return "FLK";
      case "fo":
        return "FRO";
      case "fj":
        return "FJI";
      case "fi":
        return "FIN";
      case "fr":
        return "FRA";
      case "gf":
        return "GUF";
      case "pf":
        return "PYF";
      case "tf":
        return "ATF";
      case "ga":
        return "GAB";
      case "gm":
        return "GMB";
      case "ge":
        return "GEO";
      case "de":
        return "DEU";
      case "gh":
        return "GHA";
      case "gi":
        return "GIB";
      case "gr":
        return "GRC";
      case "gl":
        return "GRL";
      case "gd":
        return "GRD";
      case "gp":
        return "GLP";
      case "gu":
        return "GUM";
      case "gt":
        return "GTM";
      case "gg":
        return "GGY";
      case "gn":
        return "GIN";
      case "gw":
        return "GNB";
      case "gy":
        return "GUY";
      case "ht":
        return "HTI";
      case "hm":
        return "HMD";
      case "va":
        return "VAT";
      case "hn":
        return "HND";
      case "hk":
        return "HKG";
      case "hu":
        return "HUN";
      case "is":
        return "ISL";
      case "in":
        return "IND";
      case "id":
        return "IDN";
      case "ir":
        return "IRN";
      case "iq":
        return "IRQ";
      case "ie":
        return "IRL";
      case "im":
        return "IMN";
      case "il":
        return "ISR";
      case "it":
        return "ITA";
      case "jm":
        return "JAM";
      case "jp":
        return "JPN";
      case "je":
        return "JEY";
      case "jo":
        return "JOR";
      case "kz":
        return "KAZ";
      case "ke":
        return "KEN";
      case "ki":
        return "KIR";
      case "kp":
        return "PRK";
      case "kr":
        return "KOR";
      case "kw":
        return "KWT";
      case "kg":
        return "KGZ";
      case "la":
        return "LAO";
      case "lv":
        return "LVA";
      case "lb":
        return "LBN";
      case "ls":
        return "LSO";
      case "lr":
        return "LBR";
      case "ly":
        return "LBY";
      case "li":
        return "LIE";
      case "lt":
        return "LTU";
      case "lu":
        return "LUX";
      case "mo":
        return "MAC";
      case "mk":
        return "MKD";
      case "mg":
        return "MDG";
      case "mw":
        return "MWI";
      case "my":
        return "MWI";
      case "mv":
        return "MDV";
      case "ml":
        return "FSM";
      case "mt":
        return "MLT";
      case "mh":
        return "MHL";
      case "mq":
        return "MTW";
      case "mr":
        return "MRT";
      case "mu":
        return "MUS";
      case "yt":
        return "MYT";
      case "mx":
        return "MEX";
      case "fm":
        return "FSM";
      case "md":
        return "MDA";
      case "mc":
        return "MCO";
      case "mn":
        return "MNG";
      case "me":
        return "MNE";
      case "ms":
        return "MSR";
      case "ma":
        return "MAR";
      case "mz":
        return "MOZ";
      case "mm":
        return "MGN";
      case "na":
        return "NAM";
      case "nr":
        return "NRU";
      case "np":
        return "NPL";
      case "nl":
        return "NLD";
      case "an":
        return "AND";
      case "nc":
        return "NLC";
      case "nz":
        return "NZL";
      case "ni":
        return "NIC";
      case "ne":
        return "NER";
      case "ng":
        return "NGA";
      case "nu":
        return "NIU";
      case "nf":
        return "NFK";
      case "mp":
        return "MNP";
      case "no":
        return "NOR";
      case "om":
        return "OMN";
      case "pk":
        return "PAK";
      case "pw":
        return "PLW";
      case "ps":
        return "PSE";
      case "pa":
        return "PAN";
      case "pg":
        return "PNG";
      case "py":
        return "PRY";
      case "pe":
        return "PER";
      case "ph":
        return "PHL";
      case "pn":
        return "PCN";
      case "pl":
        return "POL";
      case "pt":
        return "PRT";
      case "pr":
        return "PRI";
      case "qa":
        return "QAT";
      case "re":
        return "REU";
      case "ro":
        return "ROU";
      case "ru":
        return "RUS";
      case "rw":
        return "RWA";
      case "sh":
        return "SHN";
      case "kn":
        return "KNA";
      case "lc":
        return "LCA";
      case "pm":
        return "SPM";
      case "vc":
        return "VCT";
      case "ws":
        return "WSM";
      case "sm":
        return "SMR";
      case "st":
        return "STP";
      case "sa":
        return "SAU";
      case "sn":
        return "SEN";
      case "rs":
        return "SRB";
      case "sc":
        return "SYC";
      case "sl":
        return "SLE";
      case "sg":
        return "SGP";
      case "sk":
        return "SVK";
      case "sl":
        return "SVN";
      case "sb":
        return "SLB";
      case "so":
        return "SOM";
      case "za":
        return "ZAF";
      case "gs":
        return "SGS";
      case "es":
        return "ESP";
      case "lk":
        return "LKA";
      case "sd":
        return "SDN";
      case "sr":
        return "SUR";
      case "sj":
        return "SJM";
      case "se":
        return "SWE";
      case "ch":
        return "CHE";
      case "sy":
        return "SYR";
      case "tw":
        return "TWN";
      case "tj":
        return "TJK";
      case "tz":
        return "TZA";
      case "th":
        return "THA";
      case "tl":
        return "TLS";
      case "tg":
        return "TGO";
      case "tk":
        return "TKL";
      case "to":
        return "TON";
      case "tt":
        return "TTO";
      case "tn":
        return "TUN";
      case "tr":
        return "TUR";
      case "tm":
        return "TKM";
      case "tc":
        return "TCA";
      case "tv":
        return "TUV";
      case "ug":
        return "UGA";
      case "ua":
        return "UKR";
      case "ae":
        return "ARE";
      case "us":
        return "USA";
      case "um":
        return "UMI";
      case "uy":
        return "URY";
      case "uz":
        return "UZB";
      case "vu":
        return "VUT";
      case "ve":
        return "VEN";
      case "vn":
        return "VNM";
      case "vg":
        return "VGB";
      case "vi":
        return "VIR";
      case "wf":
        return "WLF";
      case "eh":
        return "ESH";
      case "ye":
        return "YEM";
      case "zm":
        return "ZMB";
      case "zw":
        return "ZWE";
      case "gb":
        return "GBR";
    }
  }

  // setMainInfo(mainInfo: object): void {
  //   localStorage.setItem('mainInfo', JSON.stringify(mainInfo));
  // }

  // getMainInfo(): object {
  //   return JSON.parse(localStorage.getItem('mainInfo'));
  // }

  // isTestEnvironment(): boolean {
  //   const hostname = window.location.hostname;

  //   if (hostname === 'localhost' || hostname === 'portal.mgflex.com') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isGlobal(): boolean {
  //   const hostname = window.location.hostname;
  //   switch (hostname) {
  //     // case 'localhost':
  //     case 'portal-dev.fxglobe.international':
  //     case 'portal.fxglobe.international':
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // isFxGlobeBrand(): boolean {
  //   const hostname = window.location.hostname;

  //   switch (hostname) {
  //     case 'localhost':
  //     case 'portal.mgflex.com':
  //     case 'portal.applebrie.com':
  //     case 'portal-dev.fxglobe.international':
  //     case 'portal.fxglobe.com':
  //     case 'portal.fxglobe.international':
  //       return true;
  //     default:
  //       return false;
  //   }
  // }
}
