import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngbd-modal-content',
  templateUrl: './view-document.component.html'
})
export class ViewDocumentComponent implements OnInit {

  @Input() image;
  documentsArray = [];

 // @Input() documentType;
  uploadedDocFile: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit() {
    // console.log(this.image);
    this.documentsArray = [];
    let uploadedDocFile;
    for ( let i = 0; i < this.image.length; i++ ) {
      // console.log(this.image[i])
      let src
      if (this.image[i].documentExtension === '.pdf') {
        // console.log(this.image[i]);
        src = this.convertImageToFile(this.image[i].documentContent, this.image[i].documentName, 'application');
        uploadedDocFile = document.createElement('iframe');
      } else {
        // console.log(this.image[i]);

        src = this.convertImageToFile(this.image[i].documentContent, this.image[i].documentName, 'image');
        uploadedDocFile = document.createElement('img');
      }
      uploadedDocFile.setAttribute('src', src);
      uploadedDocFile.setAttribute('class', 'w-100');
      uploadedDocFile.setAttribute('style',
      this.image[i].documentExtension === '.pdf' ? 'height: 600px' : 'height: unset');
      this.documentsArray.push(uploadedDocFile);
    }
       setTimeout( () =>{
    this.documentsArray.forEach((documentItem, j) => {
      // console.log(documentItem);
      document.getElementById('documentItem' + j).appendChild(documentItem);
    });
    }, 100);

  }

  continue() {
    this.modalService.dismissAll(ViewDocumentComponent);
  }

  convertImageToFile(base64, fileName, type = 'image') {
    const extension = fileName.split('.').pop()
    return `data:${type}/${extension};base64,${base64}`
  }

  download(){
    this.image.forEach(item=>{
      if (item.documentExtension === '.pdf') {
        this.fileDownload(item.documentContent, 'pdf', 'application', item.documentName)
      } else {
        const extension = item.documentExtension.split('.').pop()
        this.fileDownload(item.documentContent, extension, 'image', item.documentName)

      }
    })
  }

  fileDownload(b64Data, format, type, name) {
    const uploadedFile = b64Data
    const fileFormat = format
    const linkSource = `data:${type}/${fileFormat};base64,${uploadedFile}`
    const downloadLink = document.createElement('a')
    const fileName = name
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  // b64toBlob(b64Data, contentType='', sliceSize=512){
  //   const byteCharacters = atob(b64Data);
  //   const byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   const blob = new Blob(byteArrays, {type: contentType});
  //   return  URL.createObjectURL(blob);
  // }

  // vm.image.data.forEach(item=>{
  //   arr.push(this.b64toBlob(item.documentContent))
  // })
}
