import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepositTypeService } from '../services/deposit-type.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { AccountTypeService } from '../../account-type/services/account-type.service';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { PageService } from '../../../../page.service';
import { Currency, Account } from '../../../../interfaces';
import { AppropriatenessTestService } from '../../../verify-account-layout/appropriateness-test/sevices/appropriateness-test.service';
import { ToasterService } from 'angular2-toaster';
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: 'app-eft',
  templateUrl: './eft.component.html',
  styleUrls: ['./eft.component.scss']
})

export class EftComponent implements OnInit, OnDestroy {
  eftForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validNetellerEmail = true;
  validSecureId = true;
  validDepositAmount = true;
  // validMerchantRef = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: number;
  currentMinDeposit: number;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount: boolean = false;
  isChina = false;

  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,

    private pageService: PageService,
    private appropriatenessTestService: AppropriatenessTestService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.clientId = this.authService.getAuthClientId();
    // this.registrationService
    //   .getAppropriatenessTestLevel(this.clientId)
    //   .subscribe(res => {
    //     if(res["status"] == 4) {
    //       this.testTermsAccepted = true;
    //     } else if(res["status"] == 2 || res["status"] == 3) {
    //       this.appropriatenessTestService.getTestTerms(this.clientId).subscribe(testTerms => {
    //         if(testTerms['testTerms'] == true) {
    //           this.testTermsAccepted = true;
    //         } else {
    //           this.testTermsAccepted = false;
    //         }
    //       })
    //     } else {
    //       this.testTermsAccepted = false;
    //     }
    //   });

    this.eftForm = this.formBuilder.group({
      account: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      // neteller_email: ['', this.validationService.email()],
      // secure_id: ['', [Validators.required]],
      deposit_amount: [ '', [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
      // merchantRef: ['', [Validators.required]]
    });

    this.currenciesSubscription = this.depositTypeService.currenciesObservable.subscribe((currencies: Currency[]) => {
      this.currencies = currencies;
      this.changeAccount(this.activeAccountNumber);
    });

    // this.registrationService.getBasicInfo(this.clientId).subscribe(basicInfo => {
    //   this.isActive = basicInfo.isActive;
    //   this.currentTypeSubscription = this.accountTypeService.currentTypeObservable.subscribe(currentType => {
    //     this.currentType = currentType;
    //     if ('undefined' !== typeof this.currentType && 'undefined' !== typeof this.currentType['minDeposit']) {
    //       if (this.isActive) {
    //         this.currentMinDeposit = 0;
    //       } else {
    //         this.currentMinDeposit = this.currentType['minDeposit'];
    //       }
    //       this.updateValidation();
    //     } else { // TODO: remove this in future
    //       this.currentMinDeposit = 50;
    //       this.updateValidation();
    //     }
    //   });
    // });

    this.accountsSubscription = this.accountService.accountsObservable.subscribe((accounts: Account[]) => {
      if (accounts.length > 0) {
        this.accounts = accounts;
        this.openNewAccount = false;
      } else {
        const value = 'Open a new account';
        this.eftForm.get('account').setValue(value);
        this.openNewAccount = true;
      }
    });

    this.activeAccountSubscription = this.accountService.activeAccountObservable.subscribe((activeAccount: Account) => {
      if (activeAccount) {
        this.activeAccountNumber = activeAccount['account'];
        if (localStorage.getItem('tmp_account')) {
          this.eftForm.get('account').setValue(localStorage.getItem('tmp_account'));
          localStorage.removeItem(('tmp_account'));
        } else {
          this.eftForm.get('account').setValue(this.activeAccountNumber);
        }
        this.changeAccount(this.activeAccountNumber);
      }
    });
    this.clientId = this.authService.getAuthClientId();
  }

  submit(): void {
    if (this.eftForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      if (this.eftForm.controls.account.value === 'Open a new account') {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.eftForm.value.currency,
          accountType: this.currentType['id'],
          leverage: 30
        };
        // this.accountService.addAdditionalLiveAccount(accountData).subscribe(res => {
        //   this.newAccount = res.account;
        //   this.accountService.updateAccounts(res.account);
        //   this.submitDeposit();
        // });
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls['account'].valid;
      this.validCurrency = this.controls['currency'].valid;
      this.validDepositAmount = this.controls['deposit_amount'].valid;
      // this.validMerchantRef = this.controls['merchantRef'].valid;
    }
  }

  get controls() { return this.eftForm.controls; }

  submitDeposit() {
    let account = this.eftForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }
    const data = {
      id: 0,
      clientId: this.clientId,
      depositMethod: 0,
      account: account,
      currencyId: this.eftForm.value.currency,
      amount: Number(this.eftForm.value.deposit_amount),
      eft: { id: 0 },
      depositStatus: 0,
      done: true
    };
    // this.accountService.submitDepositInfo(data).subscribe(
    //   res => {
    //     this.pageService.changeLoadingStatus(false);
    //     window.location.href = res['response'].redirectUrl;
    //   },
    //   error => {
    //     this.pageService.changeLoadingStatus(false);
    //     this.toasterService.pop('error', "Error", "Unable to register order");
    //   }
    // );
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  // changeMerchantRef(value){
  //   AccountService
  // }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });
      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find(currency => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.eftForm.get('currency').setValue(activeAccountCurrency.id);
      }
    }
  }

  updateValidation() {
    this.eftForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
    this.eftForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(depositAmount, this.eftForm.get('deposit_amount'));
    this.validDepositAmount = true;
  }

  ngOnDestroy() {
    this.currentTypeSubscription.unsubscribe();
    this.currentTypeSubscription.complete();
    this.accountsSubscription.unsubscribe();
    this.accountsSubscription.complete();
    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();
    this.currenciesSubscription.unsubscribe();
    this.currenciesSubscription.complete();
  }
}
