import { BrandIdEnum } from "./../../brand.enum";
import { RegistrationService } from "../../auth/services/registration/registration.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AccountService } from "../services/account/account.service";
import { LanguageService } from "../../languages/services/language/language.service";
import { LegalDocumentationService } from "../services/legal-documentation/legal-documentation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BrandService } from "src/app/auth/services/brand/brand.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  private hasTriedToCreateIframe = false;
  private accountsSubscription;
  private activeAccountSubscription;
  private chosenLanguageSubscription;
  private activeAccount;
  private chosenLanguage;
  public isRevolution: boolean;
  public showWebTerminal: boolean;
  public isInternational: boolean;
  southAfrica: boolean;

  public localBrandId = `${localStorage.getItem("localBrandId")}`;
  public src: string;

  constructor(
    private accountService: AccountService,
    private legalDocumentationService: LegalDocumentationService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private brandService: BrandService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getMT4Link();

    this.route.queryParams.subscribe((param) => {
      const { webterminal } = param;
      if (webterminal) {
        this.showWebTerminal = true;
      } else {
        this.showWebTerminal = false;
      }
    });

    // const mainInfo = this.registrationService.getMainInfo();
    // this.brandId = mainInfo['brandId'];

    this.accountsSubscription =
      this.accountService.accountsObservable.subscribe((accounts) => {
        if (this.hasTriedToCreateIframe) {
          return;
        }
        this.hasTriedToCreateIframe = true;

        this.activeAccountSubscription =
          this.accountService.activeAccountObservable.subscribe(
            (activeAccount) => {
              this.activeAccount = activeAccount;
              this.buildIframe();
            }
          );
      });

    this.chosenLanguageSubscription =
      this.languageService.chosenLanguageObservable.subscribe((language) => {
        this.chosenLanguage = language;
        this.buildIframe();
      });

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
        break;
      case BrandIdEnum.International:
        this.isInternational = true;
        break;
      case BrandIdEnum.southAfrica:
        this.southAfrica = true;
        break;
      case BrandIdEnum.greenLine || BrandIdEnum.growProMarkets:
        this.router.navigate(["verify-account"]);
        break;
      case BrandIdEnum.tradeCenter:
        this.router.navigate(["accounts"]);
        break;
      case BrandIdEnum.tradeMarkets:
        this.router.navigate(["accounts"]);
        break;
      default:
        break;
    }
    // this.mt5Frame()
  }

  getMT4Link() {
    this.legalDocumentationService.getMT4Link().subscribe((res) => {
      if (res.status === 1) {
        this.src = res.data;
        this.buildIframe();
      }
    });
  }

  redirectToIframe() {
    this.showWebTerminal = true;
    this.buildIframe();
  }

  buildIframe() {
    let src = this.src;
    if (this.src && this.chosenLanguage) {
      const el = document.createElement("iframe");

      if (String(src).includes("https://trade.mql5.com")) {
        // Language
        if (this.chosenLanguage) {
          src += "&lang=" + this.chosenLanguage.toLowerCase();
        }

        // TODO: check active Account after log out and log in to new account
        if (this.activeAccount) {
          const login = this.activeAccount["accountNumber"];
          src += "&login=" + login;
        }
      }

      el.setAttribute("src", src);
      el.setAttribute(
        "style",
        "width: 100%; height: 100%; border: none; margin: 5px"
      );
      el.setAttribute("allowfullscreen", "allowfullscreen");
      document.getElementById("iframeWrapper").innerHTML = "";
      document.getElementById("iframeWrapper").appendChild(el);
      this.mt5Frame();
    }
  }

  mt5Frame() {
    let src = "https://trading.fxglobe.international";
    const el = document.createElement("iframe");
    el.setAttribute("src", src);
    el.setAttribute(
      "style",
      "width: 100%; height: 100%; border: none; margin: 5px"
    );
    el.setAttribute("allowfullscreen", "allowfullscreen");
    document.getElementById("iframeWrapper1").innerHTML = "";
    document.getElementById("iframeWrapper1").appendChild(el);
  }

  ngOnDestroy() {
    this.activeAccountSubscription.unsubscribe();
    this.activeAccountSubscription.complete();

    this.chosenLanguageSubscription.unsubscribe();
    this.chosenLanguageSubscription.complete();
  }
}
