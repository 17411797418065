export enum BrandIdEnum {
  EU = 1,
  International,
  OTB,
  RevolutionFX,
  TuDoFx,
  emerald,
  kado,
  greenLine,
  tradeCenter,
  phenoFx,
  sefx,
  southAfrica,
  growProMarkets,
  tradeMarkets,
  micMarket
}
