import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageDropDownOpened = new BehaviorSubject(false);
  chosenLanguage: any;
  languageDropDownOpenedObservable = this.languageDropDownOpened.asObservable();
  chosenLanguageObservable: any;
  languages = [
    {'lang': 'EN', 'description': 'English'},
    {'lang': 'FR', 'description': 'Français'},
    {'lang': 'DE', 'description': 'Deutsch'},
    {'lang': 'IT', 'description': 'Italiano'},
    {'lang': 'JP', 'description': '日本語'},
    {'lang': 'MY', 'description': 'Bahasa Melayu'},
    {'lang': 'PL', 'description': 'Polski'},
    {'lang': 'ES', 'description': 'Español'},
    {'lang': 'EL', 'description': 'ελληνικά'},
    {'lang': 'RU', 'description': 'Русский язык'},
    {'lang': 'CN', 'description': '中文'},
    {'lang': 'HI', 'description': 'हिन्दी'},
    {'lang': 'TR', 'description': 'Türkçe'},
    {'lang': 'VI', 'description': 'Tiếng Việt '},
    {'lang': 'TL', 'description': 'Filipino'},
    {'lang': 'NO', 'description': 'Norsk'},
    {'lang': 'ID', 'description': 'Bahasa Indonesia'},
    {'lang': 'KR', 'description': '한국어'},
    {'lang': 'PT', 'description': 'Português'},
    {'lang': 'AR', 'description': 'العربية'},
    {'lang': 'CZ', 'description': 'Čeština'},
    {'lang': 'TH', 'description': 'ภาษาไทย'},
    {'lang': 'IR', 'description': 'فارسی'},
  ];

  otbLanguages = [
    {'lang': 'EN', 'description': 'English'},
    {'lang': 'RU', 'description': 'Русский язык'},
  ];

  constructor(
    public translate: TranslateService,
  ) {
    let language = localStorage.getItem('lang');
    if (!language) {
      const allLanguages = this.getAllLanguages();
      const langs = [];
      for (let idx = 0; idx < allLanguages.length; idx++) {
        langs.push(allLanguages[idx]['lang']);
      }
      translate.addLangs(langs);

      let browserLang = translate.getBrowserLang().toUpperCase();
      if (browserLang === 'NB' || browserLang === 'NN') {
        browserLang = 'NO';
      }

      let regEx = '';
      for (let idx2 = 0; idx2 < allLanguages.length; idx2++) {
        regEx += allLanguages[idx2]['lang'];

        if (idx2 !== allLanguages.length - 1) {
          regEx += '|';
        }
      }

      language = browserLang.match(regEx) ? browserLang : 'EN';
    }

    this.chosenLanguage = new BehaviorSubject(language);
    this.chosenLanguageObservable = this.chosenLanguage.asObservable();
    this.changeLanguage(language);
  }

  closeLanguages() {
    this.languageDropDownOpened.next(false);
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang', language);
    this.chosenLanguage.next(language);
  }

  getLanguage(): string {
    return this.chosenLanguage.value;
  }

  getAllLanguages(): any[] {
    return this.languages;
  }

  getOtbLanguages(): any[] {
    return this.otbLanguages;
  }
}
