import { Component, OnInit } from '@angular/core';
import {
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { LegalDocumentationService } from '../services/legal-documentation/legal-documentation.service';
import { RegistrationService } from 'src/app/auth/services/registration/registration.service';

@Component({
  selector: 'app-legal-documentation',
  templateUrl: './legal-documentation.component.html',
  styleUrls: ['./legal-documentation.component.scss']
})
export class LegalDocumentationComponent implements OnInit {
  faFilePdf = faFilePdf;
  brandId: number;
  legalDocuments: [];

  constructor(private legalDocumentsService: LegalDocumentationService,
              private registrationService: RegistrationService) { }

  ngOnInit() {
    // const mainInfo = this.registrationService.getMainInfo();
    // this.brandId = mainInfo['brandId'];
    this.getBrandLegalDocumentsShortList();
  }

  getBrandLegalDocumentsShortList() {
    this.legalDocumentsService.getBrandLegalDocumentsShortList().subscribe(result => {
      this.legalDocuments = result.data;
    });
  }

  getBrandLegalDocumentById(documentId: number) {
    this.legalDocumentsService.getBrandLegalDocumentById(documentId).subscribe(result => {
      const win = window.open();
      win.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + result.data.documentContent + "'></iframe>");
    });
  }

}


