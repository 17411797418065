import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// import { HttpClientService } from '../../../services/http-client.service';
import { ResultModel } from 'src/app/models/result-model';
import { NewHttpClientService } from 'src/app/services/new-http-client.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LegalDocumentationService {
  newPrefix = 'client'

  constructor(
    private newHttp: NewHttpClientService,
  ) { }

  getBrandLegalDocumentsShortList(): Observable<any> {
    const url = `${this.newPrefix}/brand/legalDocument/shortList`
    return this.newHttp.get<any>(url)
  }

  getBrandLegalDocumentById(documentId: number): Observable<any> {
    const url = `${this.newPrefix}/brand/document/${documentId}`
    return this.newHttp.get<any>(url)
  }

  getMT4Link(): Observable<any> {
    const url = `${this.newPrefix}/GetMt4WebTraderUrl`
    return this.newHttp.get<any>(url)
  }
}
