import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/services/auth/auth.service";
import { SuccessPopupComponent } from "./success-popup/success-popup.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlreadyPartnerPopupComponent } from "./already-partner-popup/already-partner-popup.component";
import { ClientInfo } from "src/app/models/become-a-partner.model";
import { RegistrationService } from "src/app/auth/services/registration/registration.service";
import { BrandIdEnum } from "src/app/brand.enum";
import { BrandService } from "src/app/auth/services/brand/brand.service";

@Component({
  selector: "app-become-partner",
  templateUrl: "./become-partner.component.html",
  styleUrls: ["./become-partner.component.scss"],
})
export class BecomePartnerComponent implements OnInit {
  becomePartner = false;
  clientId: number;
  alreadyPartner = true;
  src = "";
  clientInfo: ClientInfo[] = [];
  isEu: boolean;
  mainWebsiteUrl: string;
  isOtb: boolean;
  isRevolution: boolean;
  isSouthAfrica: boolean;
  docId: number;

  constructor(
    private registrationService: RegistrationService,
    private authService: AuthService,
    private modalService: NgbModal,
    private brandService: BrandService
  ) {}

  ngOnInit() {
    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
        this.isEu = true;
        this.mainWebsiteUrl = "https://www.fxglobe.com/en/";
        break;
      case BrandIdEnum.International:
        this.mainWebsiteUrl = "https://www.fxglobe.io/";
        break;
      case BrandIdEnum.southAfrica:
        this.isSouthAfrica = true;
        this.mainWebsiteUrl = "https://www.fxglobe.net/";
        break;
      case BrandIdEnum.OTB:
        this.isOtb = true;
        this.mainWebsiteUrl = "https://otb-trading.com/";
        break;
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
        break;
      default:
        break;
    }
    this.clientId = this.authService.getAuthClientId();
    this.getPartnerDetails(this.clientId);
  }

  getPartnerDetails(clientId: number) {
    this.registrationService.getIbPartnerInfo(clientId).subscribe((result) => {
      if (result.status === 1) {
        this.alreadyPartner = result.data.alreadyPartner;
        if (result.data.alreadyPartner) {
          this.clientInfo = result.data.clientInfo;
        } else {
          this.getIbDoc();
        }
      } else {
        this.alreadyPartner = false;
        this.getIbDoc();
      }
    });
  }

  getIbDoc() {
    if (this.isRevolution) {
      this.docId = 33938;
    }
    if (this.isSouthAfrica) {
      this.docId = 63632;
    } else {
      this.docId = 9;
    }
    let frame;
    this.registrationService.getIbDoc(this.docId).subscribe((result) => {
      if (result.status === 1) {
        this.src = this.convertImageToFile(
          result.data.documentContent,
          result.data.documentExtension,
          "application"
        );
        frame = document.createElement("iframe");
        frame.setAttribute("src", this.src);
        frame.setAttribute("style", "width:100%; height:500px;");
        document.getElementById("frame").appendChild(frame);
      }
    });
  }

  convertImageToFile(base64, fileName, type = "image") {
    const extension = fileName.split(".").pop();
    return `data:${type}/${extension};base64,${base64}`;
  }

  onClickBecomePartner() {
    this.registrationService.becomePartner(this.clientId).subscribe(
      (result) => {
        if (result) {
          this.openModal();
        }
      },
      (error) => {}
    );
  }

  openModal() {
    const modalRef = this.modalService.open(SuccessPopupComponent, {
      size: "lg",
    });
    modalRef.result.then(null, () => {
      this.getPartnerDetails(this.clientId);
    });
  }

  openAlreadyPartnerModal() {
    const modalRef = this.modalService.open(AlreadyPartnerPopupComponent, {
      size: "lg",
    });
  }
}
