import { Component, OnInit } from '@angular/core';
import { PageService } from '../../page.service';

@Component({
  selector: 'app-professional-account-layout',
  templateUrl: './professional-account-layout.component.html',
  styleUrls: ['./professional-account-layout.component.scss']
})
export class ProfessionalAccountLayoutComponent implements OnInit {

  activePage: string;

  constructor(
    private pageService: PageService,
  ) { }

  ngOnInit() {
    const that = this;
    this.pageService.activePage.subscribe(pageName => setTimeout(function() {
      that.activePage = pageName;
    }, 0));
  }
}
