import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RegistrationService } from '../../../../auth/services/registration/registration.service';
import { AccountService } from '../../../services/account/account.service';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { AccountType } from '../../../../interfaces';

@Injectable()
export class AccountTypeService {

  private accountTypes = new BehaviorSubject([]);
  private currentType = new BehaviorSubject({});
  public isAccountTypeChosen = new BehaviorSubject(true);
  currentTypeObservable = this.currentType.asObservable();
  isAccountTypeChosenObservable = this.isAccountTypeChosen.asObservable();
  public accountTypeList: AccountType[];
  public accounts = [];

  constructor(
    private accountService: AccountService,
    private authService: AuthService,
  ) {
    const clientId = this.authService.getAuthClientId();

    this.accountService.getBrandAccountTypes().subscribe(accountTypes => {
      if(accountTypes.status===1){
        this.accountTypes.next(accountTypes.data);
        this.currentType.next(accountTypes.data[0]);
        this.accountTypeList = accountTypes.data;
      }
    });

    this.accountService.accountsObservable.subscribe(accounts => {
      if (accounts) {
        this.accounts = accounts;
      }
    });
  }

  changeType(type) {
    this.currentType.next(type);
    this.isAccountTypeChosen.next(true);
  }

  getAccountType(value) {
    if (value !== undefined && value !== 'Open a new account') {
      const selectedAccount = this.accounts.find(account => {
        return account.accountId === Number(value);
      });
      const accountType = this.accountTypes.value.find(type => {
        return type.name === selectedAccount.accountType;
      });
      this.changeType(accountType);
    }
  }
}
