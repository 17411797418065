import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { HttpClientService } from '../../../services/http-client.service';
import { ResultModel } from 'src/app/models/result-model';
import { GetBalanceRequestModel } from 'src/app/models/withdrawal/get-balance-request-model';
import { SubmitWithdrawalRequestStep1Request } from 'src/app/models/withdrawal/submit-withdrawal-request-step1-request';
import * as SWRS1Rs from 'src/app/models/withdrawal/submit-withdrawal-request-step1-response';
import { SubmitWithdrawalRequestStep2Request } from 'src/app/models/withdrawal/submit-withdrawal-request-step2-request';
import { SubmitWithdrawalRequestStep2Response, SubmitWithdrawalRequestStep3Response } from 'src/app/models/withdrawal/submit-withdrawal-request-step2-response';
import { GetFirstStep } from 'src/app/models/withdrawal/get-step1.model';

@Injectable({ providedIn: 'root' })

export class WithdrawalService {
  prefix = 'Withdrawals';

  constructor(
    // private http: HttpClientService
    ) {}

  // public getStep1 = (id: number): Observable <GetFirstStep> => {
  //   return this.http.get<GetFirstStep>(`${this.prefix}/GetStep1?Client_ID=${id}`)
  // }

  // public getBalance(data: GetBalanceRequestModel): Observable<ResultModel<number>> {
  //   var requestData = { currency_ID: data.currencyId, account: data.account };
  //   return this.http.post<ResultModel<number>>(`${this.prefix}/GetMaxWithdrawal`, requestData);
  // }

  // public submitWithdrawalRequestStep1(data: SubmitWithdrawalRequestStep1Request): Observable<ResultModel<SWRS1Rs.SubmitWithdrawalRequestStep1Response>> {
  //   return this.http.post<ResultModel<SWRS1Rs.SubmitWithdrawalRequestStep1Response>>(`${this.prefix}/PostStep1`, data);
  // }

  // public submitWithdrawalRequestStep2(data: SubmitWithdrawalRequestStep2Request): Observable<ResultModel<SubmitWithdrawalRequestStep2Response>> {
  //   return this.http.post<ResultModel<SubmitWithdrawalRequestStep2Response>>(`${this.prefix}/PostStep2`, data);
  // }

  // public submitWithdrawalRequestStep3 = (data: SubmitWithdrawalRequestStep2Request): Observable<ResultModel<SubmitWithdrawalRequestStep3Response>> => {
	// return this.http.post<ResultModel<SubmitWithdrawalRequestStep3Response>>(`${this.prefix}/PostStep3`, data);
  // }

}
