import { Component, OnInit, TemplateRef } from '@angular/core';
import { SubmitWithdrawalRequestStep1Request } from 'src/app/models/withdrawal/submit-withdrawal-request-step1-request';
import { SubmitWithdrawalRequestStep1Response } from 'src/app/models/withdrawal/submit-withdrawal-request-step1-response';
import { Currency } from 'src/app/interfaces';
import { RegistrationService } from 'src/app/auth/services/registration/registration.service';
import { AccountService } from '../services/account/account.service';
import { SubmitWithdrawalRequestStep2Request } from 'src/app/models/withdrawal/submit-withdrawal-request-step2-request';
import { SubmitWithdrawalRequestStep2Response } from 'src/app/models/withdrawal/submit-withdrawal-request-step2-response';
import { WithdrawalService } from '../services/withdrawal/withdrawal.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-withdrawal-step3',
    templateUrl: './withdrawal-step3.component.html',
    styleUrls: ['./withdrawal-step3.component.scss']
})
export class WithdrawalStep3Component implements OnInit {

	public currencyId: number;
	public step1Response: SubmitWithdrawalRequestStep1Response;
	public step2Response: SubmitWithdrawalRequestStep2Response;
	public step2Request: SubmitWithdrawalRequestStep2Request;
    public currencies: Currency[];
    public selectedCurrencySymbol: string;

    constructor(
        private accountService: AccountService,
		private registrationService: RegistrationService,
		private withdrawalService: WithdrawalService,
		private toasterService: ToasterService,
		private router: Router,
		public modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.currencyId = JSON.parse(localStorage.getItem('withdrawalCurencyId'));
		this.step2Response = JSON.parse(localStorage.getItem('withdrawalStep2Details'));
		this.step2Request = JSON.parse(localStorage.getItem('withdrawalRequestStep2Details'));
		this.step1Response = JSON.parse(localStorage.getItem('withdrawalDetails'));

        // const mainInfo = this.registrationService.getMainInfo();

        // const brandId = mainInfo['brandId'];

        // this.accountService.getCurrencies(brandId).subscribe(currencies => {
        //     if (currencies) {
        //         this.currencies = currencies;
        //         this.selectedCurrencySymbol = this.currencies.find(x =>
        //             x.id == this.currencyId
        //         ).currencySymbol;
        //     }
        // });
	}

	public submit = () => {
		let data: SubmitWithdrawalRequestStep2Request = this.step2Request
		data.brandID = undefined;
		data.rememberMethod = undefined;
		data.wALternativeMethod.bankWire.selectedCountryID = undefined;
		data.wALternativeMethod.account = this.step2Request.submitWithdrawalIn.account;
		data.wALternativeMethod.notCoveredAmount = this.step1Response.aLternativeMethod.notCoveredAmount;

		// this.withdrawalService.submitWithdrawalRequestStep3(data).subscribe(reponse => {
		// 	if(reponse.level == 'Success') {
		// 		this.toasterService.pop('success', 'Success', reponse.data.response);
		// 		this.router.navigateByUrl('/home');
		// 	} else {
		// 		this.toasterService.pop('error', 'Failure', reponse.errorMessage);
		// 	}
		// })
	}

	public cancel = (confirmTemplate: TemplateRef<any>) => {
		this.modalService.open(confirmTemplate);
	}

	public confirm = () => {
		this.router.navigateByUrl('/home');
		this.modalService.dismissAll();
	}
}
