import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppTestService {

  private testStatus = new BehaviorSubject('');
  testStatusObservable = this.testStatus.asObservable();

  constructor() { }

  setTestStatus(testStatus: string): void {
    this.testStatus.next(testStatus);
  }

  getTestStatus(): string {
    return this.testStatus.value;
  }
}
