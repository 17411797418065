import { Component, OnInit } from '@angular/core';
import { ZotapayTypeEnum } from 'src/app/enums/zota-pay.enum';

@Component({
  selector: 'app-zotapay-thailand',
  templateUrl: './zotapay-thailand.component.html',
  styleUrls: ['./zotapay-thailand.component.scss']
})
export class ZotapayThailandComponent implements OnInit {
  zotapayType = ZotapayTypeEnum.zotapayThailand;
  constructor() { }

  ngOnInit() {
  }

}
