import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../auth/services/auth/auth.service";
import { RegistrationService } from "../../auth/services/registration/registration.service";
import { AccountService } from "../services/account/account.service";
import { Router } from "@angular/router";
import { PageService } from "../../page.service";
import { Currency, AccountType } from "../../interfaces";
import { ToasterService } from "angular2-toaster";
import { BrandService } from "src/app/auth/services/brand/brand.service";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-open-additional-account",
  templateUrl: "./open-additional-account.component.html",
  styleUrls: ["./open-additional-account.component.scss"],
})
export class OpenAdditionalAccountComponent implements OnInit {
  accountTypeId;
  currencies: Currency[];
  additionalAccountForm: FormGroup;
  validAccountCurrency = true;
  validAccountType = true;
  validLeverage = true;
  clientId: number;
  accountTypes: AccountType[];
  accountTypeCategories = [];
  allowedLeverages = [];
  testTermsAccepted: boolean = false;
  greenLine = false;
  tradingPlatformShortList = [];
  tradingPlatformId: string;
  isInternational = false;
  southAfrica = false;

  mt5Currencies = [
    {
      id: "USD",
      name: "USD",
    },
    {
      id: "EUR",
      name: "EUR",
    },
    {
      id: "GBP",
      name: "GBP",
    },
    {
      id: "PLN",
      name: "PLN",
    },
    {
      id: "BRL",
      name: "BRL",
    },
    {
      id: "BTC",
      name: "BTC",
    },
  ];
  selectedValue = "";

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private accountService: AccountService,
    private router: Router,
    private pageService: PageService,
    private toasterService: ToasterService,
    private brandService: BrandService
  ) {}

  ngOnInit() {
    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.International:
        this.isInternational = true;
        break;
      case BrandIdEnum.southAfrica:
        this.southAfrica = true;
        break;
      default:
        break;
    }
    this.additionalAccountForm = this.formBuilder.group({
      currency: ["", [Validators.required]],
      trading_platform: ["", [Validators.required]],
      account_type: ["", [Validators.required]],
      account_type_category: ["", Validators.required],
      leverage: ["", [Validators.required]],
    });

    this.clientId = this.authService.getAuthClientId();

    this.accountService
      .getAccountTypeCategoryShortList()
      .subscribe((result) => {
        this.accountTypeCategories = result.data;
      });

    let hostname = window.location.hostname;
    // TODO: remove this in future ?
    if (hostname === "localhost") {
      hostname = "portal.mgflex.com";
    }

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.greenLine:
        this.greenLine = true;
        break;
      default:
        break;
    }
  }

  getCurrencyShortList(accountTypeId) {
    this.accountService
      .getCurrencyShortList(accountTypeId)
      .subscribe((currencies) => {
        this.currencies = currencies.data;
      });
  }

  accountTypeChange(ev) {
    this.accountService
      .getLeverageShortList(ev.target.value)
      .subscribe((leverages) => {
        this.allowedLeverages = leverages.data;
      });

    this.getCurrencyShortList(ev.target.value);
  }

  accountTypeCategoryChange(accountTypeId) {
    this.accountTypeId = accountTypeId.target.value;
    // if (this.isInternational) {
      this.accountService.getTradingPlatformShortList().subscribe((result) => {
        this.tradingPlatformShortList = result.data;
      });
    // }
  }

  tradingPlatformChange(tradingPlatform) {
    this.tradingPlatformId = tradingPlatform.target.value;
    this.accountService
      .getTypeShortList(this.tradingPlatformId)
      .subscribe((accountTypes) => {
        this.accountTypes = accountTypes.data;
      });
    this.selectedValue = "";
  }

  submit() {
    // if (this.tradingPlatformId === "1") {
    //   this.submitMT4();
    // } else {
    //   this.submitMT5();
    // }
    this.submitMT4();
  }

  // submitMT5() {
  //   if (this.selectedValue) {
  //     this.pageService.changeLoadingStatus(true);

  //     const data = {
  //       accountCurrencyIsoCode: this.selectedValue,
  //       accountTypeId: 0,
  //       leverageId: 0,
  //     };

  //     this.accountService
  //       .createNewLiveMT5Account(this.clientId, data)
  //       .subscribe(
  //         (res) => {
  //           if (res.status === 1) {
  //             this.accountService.updateAccounts(res.data, false);
  //             this.router.navigate(["created-mt5-account", res.data]);
  //             this.pageService.changeLoadingStatus(false);
  //           }

  //           if (res.status === 2) {
  //             this.toasterService.pop("error", "Warining", res.message);
  //           }
  //         },
  //         (error) => {
  //           this.pageService.changeLoadingStatus(false);
  //           this.toasterService.pop("error", "Error", error.error);
  //         },
  //         () => {
  //           this.pageService.changeLoadingStatus(false);
  //         }
  //       );
  //   }
  // }
  submitMT4(): void {
    if (this.additionalAccountForm.valid && this.accountTypeId === "1") {
      this.pageService.changeLoadingStatus(true);

      const data = {
        accountCurrencyIsoCode: this.additionalAccountForm.value.currency,
        accountTypeId: this.additionalAccountForm.value.account_type,
        leverageId: this.additionalAccountForm.value.leverage,
        tradingPlatform: this.additionalAccountForm.value.trading_platform,
      };

      this.accountService.createNewLiveAccount(this.clientId, data).subscribe(
        (res) => {
          if (res.status === 1) {
            this.accountService.updateAccounts(res.data, false);
            this.router.navigate(["/"]);
            this.pageService.changeLoadingStatus(false);
          }

          if (res.status === 2) {
            this.toasterService.pop("error", "Warining", res.message);
          }
        },
        (error) => {
          this.pageService.changeLoadingStatus(false);
          this.toasterService.pop("error", "Error", error.error);
        },
        () => {
          this.pageService.changeLoadingStatus(false);
        }
      );
    } else if (this.accountTypeId === "2") {
      this.registrationService.createDemoAccount().subscribe((res) => {
        if (res.status === 1) {
          this.accountService.updateAccounts(res.data, true);
          this.toasterService.pop(
            "success",
            "The demo account password has been sent to your email, please check"
          );
          this.router.navigate(["/"]);
          this.pageService.changeLoadingStatus(false);
        }
      });
    } else {
      this.validAccountCurrency = this.controls["currency"].valid;
      this.validAccountType = this.controls["account_type"].valid;
      this.validLeverage = this.controls["leverage"].valid;
    }
  }

  changeAccountTypeSelect(): void {
    this.validAccountType = true;
  }

  changeCurrencySelect(): void {
    this.validAccountCurrency = true;
  }

  changeLeverageSelect(): void {
    this.validLeverage = true;
  }

  changeMt5CurrencySelect(value) {
    this.selectedValue = value;
  }

  get controls() {
    return this.additionalAccountForm.controls;
  }
}
