import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidationService } from "../../services/validation/validation.service";
import { AccountService } from "../services/account/account.service";

@Component({
  selector: "app-withdrawal-step2",
  templateUrl: "./withdrawal-step2.component.html",
  styleUrls: ["./withdrawal-step2.component.scss"],
})
export class WithdrawalStep2Component implements OnInit {
  // skrillForm: FormGroup;
  // fasapayForm: FormGroup;
  // netellerForm: FormGroup;
  // wireTransferForm: FormGroup;
  // validSkrillEmail = true;
  // validComment = true;
  // validFasapayAccount = true;
  // validNetellerEmail = true;
  // validNetellerAccount = true;
  // validBeneficiaryName = true;
  // validBankName = true;
  // validBankCity = true;
  // validBankCountry = true;
  // validBankAddress = true;
  // validBankZip = true;
  // validBankSwift = true;
  // validBankAccount = true;
  // validEmail = true;
  withdrawalId
  withdrawalDeposits = []
  pspShortList
  alternativeMethodForm: FormGroup
  accountNumber
  showAlternativeMethod = false

  params = {
    pageIndex: 0,
    active: '',
    direction: '',
    pageSize: 10,
    search: '',
    filters: [],
  }
  processingAlternative
  showLazyLoadButton = false
  currencyShortList

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initForm()
  }

  ngOnInit() {
    this.getUICurrencyShortList()
    this.route.params.subscribe(param => {
      this.withdrawalId = param.id
      this.alternativeMethod()
    })

    this.route.queryParams.subscribe(queryParams => {
      this.accountNumber = queryParams.account
    })
    // this.skrillForm = this.formBuilder.group({
    //   skrill_email: ["", this.validationService.email()],
    //   comment: ["", [Validators.required]],
    // });

    // this.fasapayForm = this.formBuilder.group({
    //   fasapay_account: ["", [Validators.required]],
    //   comment: ["", [Validators.required]],
    // });

    // this.netellerForm = this.formBuilder.group({
    //   neteller_email: ["", this.validationService.email()],
    //   neteller_account: ["", [Validators.required]],
    //   comment: ["", [Validators.required]],
    // });

    // this.wireTransferForm = this.formBuilder.group({
    //   beneficiary_name: ["", [Validators.required]],
    //   bank_name: ["", [Validators.required]],
    //   bank_city: ["", [Validators.required]],
    //   bank_country: ["", [Validators.required]],
    //   bank_address: ["", [Validators.required]],
    //   bank_zip: ["", [Validators.required]],
    //   bank_swift: ["", [Validators.required]],
    //   bank_account: ["", [Validators.required]],
    //   email: ["", this.validationService.email()],
    // });
  }

  initForm() {
    this.alternativeMethodForm = this.fb.group({
      pspId: [null],
      pspAccount: [null],
      savePspAccount: [false],
      withdrawalAlternativeComment: [null],
    })
  }

  alternativeMethod() {
    this.accountService.getProcessingAlternative(this.withdrawalId).subscribe(res => {
      if (res['status'] == 1) {
        this.processingAlternative = res['data']
        this.getWithdrawalDeposits()
      }
    })
  }

  getWithdrawalDeposits() {
    this.accountService.getWithdrawalDeposits(this.withdrawalId, this.params).subscribe(res => {
      if (res['status'] == 1) {
        const { withdrawalDepositList } = res.data

        this.withdrawalDeposits.push(...withdrawalDepositList)
        this.showAlternativeMethod = this.processingAlternative.show
        this.showLazyLoadButton = this.processingAlternative.totalRows > this.params.pageSize

        if (this.showAlternativeMethod) {
          this.alternativeMethodsInit()
        }
      }
    })
  }

  alternativeMethodsInit() {
    this.alternativeMethodForm.get('pspId').setValidators([Validators.required])

    this.alternativeMethodForm
      .get('pspAccount')
      .setValidators([Validators.required])

    this.getPspShortList()
    this.getSavedAlternativeMethod()
  }

  getPspShortList() {
    this.accountService.getPspShortListAlternative().subscribe(res => {
      if (res['status'] == 1) {
        this.pspShortList = res.data
      }
    })
  }

  getUICurrencyShortList() {
    this.accountService.getUICurrencyShortList().subscribe(res => {
      if (res['status'] == 1) {
        this.currencyShortList = res.data
      }
    })
  }

  currencySymbolGenerator(currency) {
    return this.currencyShortList.find(cur => cur.id === currency).symbol
  }

  getSavedAlternativeMethod() {
    this.accountService.getSavedAlternativeMethod(
      this.withdrawalId,
      this.accountNumber
      ).subscribe(res => {
        if (res['status'] == 1) {
          this.alternativeMethodForm.get('pspId').setValue(res.data)
          this.alternativeMethodForm.get('savePspAccount').setValue(true)
        }
    })
  }

  get controls() { return this.alternativeMethodForm.controls; }

  createWithdrawal() {
    if (!this.showAlternativeMethod) {
      this.alternativeMethodForm.get('pspId').setValue(0)
    }
    this.accountService.updateWithdrawal(
      this.withdrawalId,
      this.alternativeMethodForm.value
      ).subscribe(res => {
        if (res['status'] == 1) {
          this.router.navigate(['withdrawal-success'])
        }
    })
  }


  lazyLoad() {
    this.params.pageIndex++
    if (
      Math.round(
        this.processingAlternative.totalRows / this.params.pageSize
      ) > this.params.pageIndex
    ) {
      this.getWithdrawalDeposits()
    } else {
      this.showLazyLoadButton = false
    }
  }

  // submitSkrillForm(): void {
  //   this.validSkrillEmail = this.skrillControls["skrill_email"].valid;
  //   this.validComment = this.skrillControls["comment"].valid;
  // }

  // submitFasapayForm(): void {
  //   this.validFasapayAccount = this.fasapayControls["fasapay_account"].valid;
  //   this.validComment = this.fasapayControls["comment"].valid;
  // }

  // submitNetellerForm(): void {
  //   this.validNetellerEmail = this.netellerControls["neteller_email"].valid;
  //   this.validNetellerAccount = this.netellerControls["neteller_account"].valid;
  //   this.validComment = this.netellerControls["comment"].valid;
  // }

  // submitWireTransferForm(): void {
  //   this.validBeneficiaryName = this.wireTransferControls[
  //     "beneficiary_name"
  //   ].valid;
  //   this.validBankName = this.wireTransferControls["bank_name"].valid;
  //   this.validBankCity = this.wireTransferControls["bank_city"].valid;
  //   this.validBankCountry = this.wireTransferControls["bank_country"].valid;
  //   this.validBankAddress = this.wireTransferControls["bank_address"].valid;
  //   this.validBankZip = this.wireTransferControls["bank_zip"].valid;
  //   this.validBankSwift = this.wireTransferControls["bank_swift"].valid;
  //   this.validBankAccount = this.wireTransferControls["bank_account"].valid;
  //   this.validEmail = this.wireTransferControls["email"].valid;
  // }

  // get skrillControls() {
  //   return this.skrillForm.controls;
  // }
  // get fasapayControls() {
  //   return this.fasapayForm.controls;
  // }
  // get netellerControls() {
  //   return this.netellerForm.controls;
  // }
  // get wireTransferControls() {
  //   return this.wireTransferForm.controls;
  // }
}
