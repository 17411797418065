import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BecomePartnerService } from "../../services/become-partner/become-partner.service";
import { AuthService } from "src/app/auth/services/auth/auth.service";
import { ClientInfo } from "src/app/models/become-a-partner.model";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { RegistrationService } from "src/app/auth/services/registration/registration.service";
import { BrandService } from "src/app/auth/services/brand/brand.service";
import { BrandIdEnum } from "src/app/brand.enum";

@Component({
  selector: "app-success-popup",
  templateUrl: "./success-popup.component.html",
  styleUrls: ["./success-popup.component.scss"],
})
export class SuccessPopupComponent implements OnInit {
  clientId: number;
  clientInfo: ClientInfo[];
  faCopy = faCopy;
  isEu: boolean;
  mainWebsiteUrl: string;
  isOtb: boolean;
  isRevolution: boolean;

  constructor(
    private modalService: NgbModal,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private brandService: BrandService
  ) {}

  ngOnInit() {
    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
        this.isEu = true;
        this.mainWebsiteUrl = "https://www.fxglobe.com/en/";
        break;
      case BrandIdEnum.International:
      case BrandIdEnum.southAfrica:
        this.mainWebsiteUrl = "https://www.fxglobe.io/";
        break;
      case BrandIdEnum.OTB:
        this.isOtb = true;
        this.mainWebsiteUrl = "https://otb-trading.com/";
        break;
      case BrandIdEnum.RevolutionFX:
        this.isRevolution = true;
        break;
      default:
        break;
    }
    this.clientId = this.authService.getAuthClientId();
    this.getPartnerDetails(this.clientId);
  }

  getPartnerDetails(clientId: number) {
    this.registrationService.getIbPartnerInfo(clientId).subscribe((result) => {
      this.clientInfo = result.data.clientInfo;
    });
  }

  continue() {
    this.modalService.dismissAll(SuccessPopupComponent);
  }

  copyText(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
}
