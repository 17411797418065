import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ZotapayTypeEnum } from 'src/app/enums/zota-pay.enum';

@Component({
  selector: 'app-zotapay-vietnam',
  templateUrl: './zotapay-vietnam.component.html',
  styleUrls: ['./zotapay-vietnam.component.scss']
})
export class ZotapayVietnamComponent implements OnInit {
  zotapayType = ZotapayTypeEnum.zotapayVietnam;
  constructor() { }

  ngOnInit() {
  }

}
