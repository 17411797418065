import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormService {

  constructor() { }

  /**
   * hasError - returns true if the control is invalid.
   *
   * @param name - name of control
   * @param form - form: FormGroup
   * @param touched - if true function will return true only if the control is touched
   */
  static hasError(name: string, form: FormGroup, touched?): boolean {
    const control = form.get(name);
    const condition = control && !control.valid;

    if (touched) {
      return condition && control.touched;
    } else {
      return condition;
    }
  }

  validateAllFormFields(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
