import { Component, OnInit } from "@angular/core";
import { BrandService } from "src/app/auth/services/brand/brand.service";

@Component({
  selector: "app-decline-message",
  templateUrl: "./decline-message.component.html",
  styleUrls: ["./decline-message.component.scss"]
})
export class DeclineMessageComponent implements OnInit {
  accountingEmail: string = "";
  public hostname: string;

  constructor(private brandService: BrandService) {}

  ngOnInit() {
    this.hostname = window.location.hostname;
    // switch (this.brandService.getCurrentWebsitesBrandId()) {
    //   case 3:
    //     this.accountingEmail = "info@swisscapital.fm";
    //     break;
    //   case 42:
    //     this.accountingEmail = "accounting@otb-trading.com";
    //     break;
    //   case 2:
    //     this.accountingEmail = "support@fxglobe.international";
    //     break;
    //   default:
    //     this.accountingEmail = "support@fxglobe.com";
    //     break;
    // }
  }
}
