import { BrandService } from "./auth/services/brand/brand.service";
import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Data, PageService } from "./page.service";
import { Title, Meta } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import { RegistrationService } from "./auth/services/registration/registration.service";
import { AlertService } from "./services/alert.service";
import { ToasterService } from "angular2-toaster";
import { NewHttpClientService } from "./services/new-http-client.service";

import { BrandIdEnum } from "./brand.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = false;
  debug = false;

  private loadingStatusSubscription;
  public localBrandId = `${localStorage.getItem("localBrandId")}`;

  constructor(
    private pageService: PageService,
    private titleService: Title,
    private meta: Meta,
    private registrationService: RegistrationService,
    private alertService: AlertService,
    private toasterService: ToasterService,
    private newHttpClientService: NewHttpClientService,
    private brandService: BrandService
  ) {
    this.getHostname();
    this.insertHotjar();
  }

  insertHotjar() {
    if (
      BrandIdEnum.RevolutionFX ===
      +this.brandService.getCurrentWebsitesBrandId()
    ) {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.innerHTML = `(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2563702, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `;
      head.insertBefore(script, head.firstChild);
    }
  }

  ngOnInit() {
    this.alertService.alerts.subscribe((alert) => {
      if (this.debug) {
        this.toasterService.pop(alert.type, alert.title, alert.text);
      }
    });

    const that = this;
    this.loadingStatusSubscription = this.pageService.loadingStatus.subscribe(
      (res) =>
        setTimeout(function () {
          that.loading = res;
        }, 0)
    );

    const title = environment.title;
    this.titleService.setTitle(title);

    const socialSharingImg = "social-sharing.jpg";

    const socialSharingImgURL =
      window.location.origin + "/assets/images/" + socialSharingImg;
    this.meta.addTag({ property: "og:image", content: socialSharingImgURL });
    this.meta.addTag({ property: "og:image:type", content: "image/jpeg" });
    this.meta.addTag({ property: "og:image:width", content: "1200" });
    this.meta.addTag({ property: "og:image:height", content: "630" });

    this.changeFavicon(environment.favicon);
    this.includeCookieJarJs();
    this.loadScript();
  }
  private loadScript() {
    const chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.setAttribute("id", "convrs-webchat");
    chatScript.defer = true;
    switch (+this.localBrandId) {
      case BrandIdEnum.EU:
        chatScript.src =
          "https://webchat.conv.rs/805443efc7e2677b2ddb2a5d2ec9ff5fe563bb02.js";
        break;
      case BrandIdEnum.International:
      case BrandIdEnum.southAfrica:
        chatScript.src =
          "https://webchat.conv.rs/bf35ca3602d6a4e5d3d701bb369fa989ff7a9c40.js";
        break;
      case BrandIdEnum.RevolutionFX:
        chatScript.src =
          "https://webchat.conv.rs/dfc2648a37f0495e67bb7fcb5717e4d3e9fd9719.js";
        break;
    }
    document.body.appendChild(chatScript);
  }

  getHostname() {
    const hostName =
      window.location.hostname === "localhost"
        ? "portal.applebrie.co"
        : window.location.hostname;
    const payload = {
      registrationUrl: `https://${hostName}/`,
    };
    this.newHttpClientService.getBrand<Data>(payload).subscribe((data) => {
      this.pageService.brandList.next(data.data);

      if (
        localStorage.getItem("localBrandId") &&
        (window.location.hostname === "localhost" ||
          window.location.hostname === "portal.applebrie.co")
      ) {
        this.pageService.brandShortlist.next(
          localStorage.getItem("localBrandId")
        );
      } else {
        this.pageService.brandShortlist.next(data.data[0].id);
        localStorage.setItem("localBrandId", data.data[0].id);
      }
    });
  }

  // Use special input combination to activate the debug mode
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    // CTRL + .
    if ((event.ctrlKey || event.metaKey) && event.keyCode === 190) {
      this.debug = !this.debug;

      let mode;
      if (this.debug) {
        mode = "on";
      } else {
        mode = "off";
      }

      this.toasterService.pop(
        "success",
        "Debug",
        "Debug mode has been turned " + mode
      );
    }
  }

  changeFavicon(favicon) {
    const nodeList = document.getElementsByTagName("link");
    for (let i = 0; i < nodeList.length; i++) {
      if (
        nodeList[i].getAttribute("rel") === "icon" ||
        nodeList[i].getAttribute("rel") === "shortcut icon"
      ) {
        nodeList[i].setAttribute("href", "assets/images/favicons/" + favicon);
      }
    }
  }

  includeCookieJarJs() {
    const hostname = window.location.hostname;
    const entities = hostname.split(".");
    if (entities.length === 3) {
      const rootUrl = entities[1] + "." + entities[2];
      switch (rootUrl) {
        case "fxglobe.com":
        case "fxglobe.international":
        case "vakfx.com":
          // Removing sub-domain parts, exchanging it with 'cookie-jar'.
          const node = document.createElement("script");
          node.src = `https://cookie-jar.${rootUrl}/Serve/reach.js`;
          node.type = "text/javascript";
          node.async = false;
          document.getElementsByTagName("head")[0].appendChild(node);
          break;
      }
    }
  }

  ngOnDestroy() {
    this.loadingStatusSubscription.unsubscribe();
    this.loadingStatusSubscription.complete();
  }
}
