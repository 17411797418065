import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimals'
})
export class TwoDecimalsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let numberWithTwoDecimals;
    // If there is a dot
    if (value.toString().indexOf('.') !== -1 ) {
      // Remove all after 2 decimals
      numberWithTwoDecimals = value.toString().substring(0, value.toString().indexOf('.') + 3);
      // Make 2 decimals if there is one decimal
      numberWithTwoDecimals = parseFloat(numberWithTwoDecimals).toFixed(2);
    } else {
      numberWithTwoDecimals = value + '.00';
    }

    return numberWithTwoDecimals;
  }

}
