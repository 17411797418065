import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToasterService } from "angular2-toaster";
import { AuthService } from "src/app/auth/services/auth/auth.service";
import { RegistrationService } from "src/app/auth/services/registration/registration.service";
import { AccountService } from "src/app/dashboard/services/account/account.service";
import { AppropriatenessTestService } from "src/app/dashboard/verify-account-layout/appropriateness-test/sevices/appropriateness-test.service";
import { Account, Currency } from "src/app/interfaces";
import { PageService } from "src/app/page.service";
import { AccountTypeService } from "../../account-type/services/account-type.service";
import { DepositTypeService } from "../services/deposit-type.service";
import {LanguageService} from '../../../../languages/services/language/language.service';

@Component({
  selector: "app-e-pay",
  templateUrl: "./e-pay.component.html",
  styleUrls: ["./e-pay.component.scss"],
})
export class EPayComponent implements OnInit {
  ePayFormValue = {};
  ePayForm: FormGroup;
  validAccount = true;
  validCurrency = true;
  validNetellerEmail = true;
  validSecureId = true;
  validDepositAmount = true;
  // validMerchantRef = true;
  clientId: number;
  accounts: Account[];
  activeAccountNumber: number;
  currencies: Currency[];
  currency: string;
  currentType: object;
  newAccount: number;
  currentMinDeposit: number ;
  private currentTypeSubscription;
  private accountsSubscription;
  private activeAccountSubscription;
  private currenciesSubscription;
  testTermsAccepted: boolean = false;
  isActive: boolean;
  openNewAccount: boolean = false;
  exChange = 1;
  errorMessage: string;
  paymentStatusSuccess = false;
  isChina = false;
  id = "15";

  @ViewChild("ePay", { static: true }) paymentform: ElementRef;
  constructor(
    private langService: LanguageService,
    private formBuilder: FormBuilder,
    private depositTypeService: DepositTypeService,
    private authService: AuthService,
    private accountService: AccountService,
    private accountTypeService: AccountTypeService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.langService.chosenLanguage.subscribe(res =>  this.isChina = res === "CN");
    this.clientId = this.authService.getAuthClientId();

    this.ePayForm = this.formBuilder.group({
      account: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      deposit_amount_usd: [0],
      deposit_amount: [
        "",
        [Validators.required, Validators.min(this.currentMinDeposit)],
      ],
    });

    this.accountService.getPspCurrency(this.id).subscribe((currencies) => {
      this.currencies = currencies.data;
    });

    this.accountService.getWalletAccounts().subscribe((res) => {
      if (res.status === 1) {
        this.accounts = res.data;
      }
    });

    this.activeAccountSubscription =
      this.accountService.activeAccountObservable.subscribe(
        (activeAccount: Account) => {
          if (activeAccount) {
            this.activeAccountNumber = activeAccount["account"];
            if (localStorage.getItem("tmp_account")) {
              this.ePayForm
                .get("account")
                .setValue(localStorage.getItem("tmp_account"));
              localStorage.removeItem("tmp_account");
            } else {
              this.ePayForm.get("account").setValue(this.activeAccountNumber);
            }
            this.changeAccount(this.activeAccountNumber);
          }
        }
      );
    this.clientId = this.authService.getAuthClientId();
  }

  submit(): void {
    if (this.ePayForm.valid) {
      // this.pageService.changeLoadingStatus(true);
      if (this.ePayForm.controls.account.value === "Open a new account") {
        // TODO: get leverage
        const accountData = {
          clientId: this.clientId,
          currencyId: this.ePayForm.value.currency,
          accountType: this.currentType["id"],
          leverage: 30,
        };
      } else {
        this.submitDeposit();
      }
    } else {
      this.validAccount = this.controls["account"].valid;
      this.validCurrency = this.controls["currency"].valid;
      this.validDepositAmount = this.controls["deposit_amount"].valid;
    }
  }

  get controls() {
    return this.ePayForm.controls;
  }

  submitDeposit() {
    let account = this.ePayForm.value.account;
    if (this.newAccount) {
      account = this.newAccount;
    }
    const data = {
      depositMethodId: this.id,
      depositMethodSubtypeId: null,
      amount: Number(this.ePayForm.value.deposit_amount),
      accountId: account,
      currencyISOCode: this.ePayForm.value.currency,
    };

    this.depositTypeService.submitDeposit(data).subscribe((res) => {
      if (res.status === 1) {
        this.extraCallForEPay(JSON.parse(res.data.extraParams));
      } else {
        this.errorMessage = res.message;
      }
    });
  }

  extraCallForEPay(body) {
    this.ePayFormValue = body;
    this.paymentStatusSuccess = true;
  }

  ngAfterViewChecked() {
    if (this.paymentStatusSuccess) {
      this.paymentform.nativeElement.submit();
    }
  }

  set(ev) {
    this.ePayForm.patchValue({
      deposit_amount: (ev / this.exChange).toFixed(2),
    });
  }
  get(ev) {
    this.ePayForm.patchValue({
      deposit_amount_usd: (ev * this.exChange).toFixed(2),
    });
  }

  focusOut(ev, control) {
    if (ev !== null) {
      this.ePayForm.get(control).setValue(Number(ev).toFixed(2));
    }
  }

  // onAccountChange(ev){
  //   this.getDepositAmount(ev.target.value)
  //   this.getCurrencyRate()
  // }

  // getDepositAmount(clientAccountId){
  //   this.depositTypeService
  //   .getDepositAmount(clientAccountId)
  //   .subscribe((result) => {
  //     if (!result.data.madeDeposit) {
  //       this.currentMinDeposit = result.data.minDepositAmount;
  //       this.ePayForm.controls.deposit_amount.setValidators([Validators.required, Validators.min(this.currentMinDeposit)]);
  //     }
  //   });
  // }

  getCurrencyRate() {
    this.ePayForm.patchValue({
      deposit_amount: null,
      deposit_amount_usd: null,
    });
    const { currency, account } = this.ePayForm.value;
    if (account && currency) {
      this.accountService
        .getCurrencyRate(currency, account)
        .subscribe((res) => {
          if (res.status === 1 && res.data) {
            this.exChange = res.data;
          } else {
            this.exChange = 1;
          }
        });
      const payload = {
        clientAccountId: account,
        depositCurrency: currency,
      };

      this.depositTypeService
        .getMinDepositAmountByCurrency(payload)
        .subscribe((result) => {
          if (!result.data.madeDeposit) {
            this.currentMinDeposit = result.data.minDepositAmount;
            this.ePayForm.controls.deposit_amount.setValidators([
              Validators.required,
              Validators.min(this.currentMinDeposit),
            ]);
          }
        });
    }
  }

  changeAccount(value) {
    this.accountTypeService.getAccountType(value);
    this.setActiveAccountCurrency(value);
  }

  // changeMerchantRef(value){
  //   AccountService
  // }

  setActiveAccountCurrency(value) {
    if (undefined !== value && this.currencies.length > 0) {
      const selectedAccount = this.accounts.find((account) => {
        return account.accountId === Number(value);
      });
      if (selectedAccount) {
        const activeAccountCurrency = this.currencies.find((currency) => {
          return currency.name === selectedAccount.currencyIsoCode;
        });
        this.ePayForm.get("currency").setValue(activeAccountCurrency.id);
      }
    }
  }

  updateValidation() {
    this.ePayForm.controls.deposit_amount.setValidators([
      Validators.required,
      Validators.min(this.currentMinDeposit),
    ]);
    this.ePayForm.controls.deposit_amount.updateValueAndValidity();
  }

  changeAmount(depositAmount) {
    this.depositTypeService.changeAmount(
      depositAmount,
      this.ePayForm.get("deposit_amount")
    );
    this.validDepositAmount = true;
  }

  ngOnDestroy() {}
}
