import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpStackService {

  hostname = 'https://api.ipstack.com';
  token = '1cf0c1b7e54e1c7e9d6a775f7704eae3';

  constructor(
    // private http: HttpClient,
  ) { }

  // public get() {
  //   return this.http.get(`${this.hostname}/check?access_key=${this.token}`);
  // }

}
