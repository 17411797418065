import { NewHttpClientService } from './../../../services/new-http-client.service';
import { Injectable } from '@angular/core';
// import { Http/ClientService } from '../../../services/http-client.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {

  prefix = 'Compliance';
  newPrefix = 'lead'
  clientId: number;

  constructor(
    // private http: HttpClientService,
    private newHttp: NewHttpClientService,
    private authService: AuthService,
  ) {
    this.clientId = this.authService.getAuthClientId();
  }

  getComplianceStatus(): Observable<any> {
    return this.newHttp.get(`${this.newPrefix}/${this.clientId}/complianceStatus`);
  }

}
