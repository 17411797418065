import { Component, OnInit } from '@angular/core';
import {DepositTypeService} from '../../../services/deposit-type.service';
import { RegistrationService } from '../../../../../../auth/services/registration/registration.service';

@Component({
  selector: 'app-review-message',
  templateUrl: './review-message.component.html',
  styleUrls: ['./review-message.component.scss']
})
export class ReviewMessageComponent implements OnInit {

  depositInfo: object;

  constructor(
    private depositTypeService: DepositTypeService,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
    this.depositInfo = this.depositTypeService.getDepositInfo();
  }

  submit() {
    this.registrationService.redirectToNextRegistrationStep();
  }

}
