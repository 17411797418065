import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  faGlobe,
  faDesktop,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWindows,
  faAndroid,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { RegistrationService } from "../../auth/services/registration/registration.service";
import { AuthService } from "../../auth/services/auth/auth.service";
import { BrandIdEnum } from "src/app/brand.enum";
import { BrandService } from "src/app/auth/services/brand/brand.service";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { NewHttpClientService } from "src/app/services/new-http-client.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.scss"],
})
export class DownloadComponent implements OnInit {
  downloadForm: FormGroup;
  validForm = true;
  validPhoneNumber = true;
  faWindows = faWindows;
  faGlobe = faGlobe;
  faAndroid = faAndroid;
  faApple = faApple;
  faDesktop = faDesktop;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  phoneNumber: string;
  windowsDownloadLink: string;
  macDownloadLink: string;
  showDownloadLinks = true;
  // TODO: remove once we have API call
  hideMobileAppSectionForNow = true;
  isOtb = false;
  isInternational = false;
  isEmerald = false;
  isKado = false;
  isGreenLine = false;
  southAfrica = false;
  growProMarkets = false;

  public isRevolution: boolean;
  public isTuDoFx: boolean;
  public tradeCenter: boolean;
  public tradeMarkets: boolean;
  public isPhenoFx: boolean;
  public micMarket: boolean;
  public isSefx: boolean;
  public success = false;
  public applyText = "";
  public applyButton = "";
  public successMessage = "";

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private brandService: BrandService,
    private newHttp: NewHttpClientService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.downloadForm = this.formBuilder.group({
      operating_system: ["", [Validators.required]],
      phone_number: ["", [Validators.required]],
    });

    const clientId = this.authService.getAuthClientId();
    // this.registrationService.getBasicInfo(clientId).subscribe(basicInfo => {
    //   this.phoneNumber = basicInfo['phoneNumber'];
    // });

    this.macDownloadLink =
      "https://www.fxglobe.com/wp-content/downloads/fxglobe_macos_set_main.zip";

    const hostname = window.location.hostname;

    // switch (hostname) {
    //   case 'portal.fxglobe.com':
    //   case 'portal.mgflex.com':
    //   case 'portal.applebrie.com':
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/13785/mt4/fxglobe4setup.exe';
    //     this.macDownloadLink = 'https://www.fxglobe.com/wp-content/downloads/fxglobe_macos_set_main.zip';
    //     break;
    //   case 'portal.fxglobe.international':
    //   case 'portal-dev.fxglobe.international':
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/globe.pro.limited/mt4/globepro4setup.exe';
    //     this.macDownloadLink = 'https://fxglobecdn.blob.core.windows.net/fxgweb/GlobePro.dmg';
    //     break;
    //   case 'portal.vakfx.com':
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/globe.pro.limited/mt4/globepro4setup.exe';
    //     break;
    //   case 'portaleu.vakfx.com':
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/7993/mt4/fxglobe4setup.exe';
    //     break;
    //   case 'portal.swisscapital.fm':
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/9472/mt4/ecnpool4setup.exe';
    //     this.macDownloadLink = '';
    //     break;
    //   case 'portal.fxcm-globe.com':
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/9472/mt4/ecnpool4setup.exe';
    //     break;
    //   case 'portal.otb-trading.com' :
    //     this.windowsDownloadLink = 'https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe';
    //     this.macDownloadLink = 'https://otb-trading.com/static/otbtrading_macos_set_main.zip';
    //     this.isOtb = true;
    //     break;
    // }

    switch (+this.brandService.getCurrentWebsitesBrandId()) {
      case BrandIdEnum.EU:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/13785/mt4/fxglobe4setup.exe";
        this.macDownloadLink =
          "https://www.fxglobe.com/wp-content/downloads/fxglobe_macos_set_main.zip";
        break;
      case BrandIdEnum.International:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/globe.pro.limited/mt4/globepro4setup.exe";
        this.macDownloadLink = "";
        this.isInternational = true;
        break;
      case BrandIdEnum.southAfrica:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/globe.pro.limited/mt4/globepro4setup.exe";
        this.macDownloadLink = "";
        this.southAfrica = true;
        break;
      case BrandIdEnum.OTB:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.macDownloadLink =
          "https://otb-trading.com/static/otbtrading_macos_set_main.zip";
        this.isOtb = true;
        break;
      case BrandIdEnum.RevolutionFX:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.macDownloadLink = "";
        this.isRevolution = true;
        break;
      case BrandIdEnum.TuDoFx:
        this.isTuDoFx = true;
        break;
      case BrandIdEnum.greenLine:
        this.windowsDownloadLink =
          " https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.isGreenLine = true;
        break;
      case BrandIdEnum.growProMarkets:
        this.windowsDownloadLink =
          " https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.growProMarkets = true;
        break;
      case BrandIdEnum.kado:
        this.windowsDownloadLink = "";
        this.macDownloadLink = "";
        this.isKado = true;
        break;
      case BrandIdEnum.emerald:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.macDownloadLink =
          "https://apps.apple.com/us/app/metatrader-4/id496212596";
        this.isEmerald = true;
        break;
      case BrandIdEnum.tradeCenter:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.macDownloadLink =
          "https://apps.apple.com/us/app/metatrader-4/id496212596";
        this.tradeCenter = true;
        break;
      case BrandIdEnum.tradeMarkets:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.macDownloadLink =
          "https://apps.apple.com/us/app/metatrader-4/id496212596";
        this.tradeMarkets = true;
        break;
      case BrandIdEnum.phenoFx:
        this.isPhenoFx = true;
        break;
      case BrandIdEnum.micMarket:
        this.windowsDownloadLink =
        "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
      this.macDownloadLink =
        "https://apps.apple.com/us/app/metatrader-4/id496212596";
        this.micMarket = true;
        break;
      case BrandIdEnum.sefx:
        this.windowsDownloadLink =
          "https://download.mql5.com/cdn/web/16659/mt4/denarium4setup.exe";
        this.macDownloadLink =
          "https://apps.apple.com/us/app/metatrader-4/id496212596";
        this.isSefx = true;
        break;
      default:
        break;
    }

    // if ( window.location.hostname === 'portal.fxcm-globe.com') {
    //   this.showDownloadLinks = false;
    // }

    this.getPopupInfo().subscribe((info) => {
      this.applyText = info.data.text;
      this.applyButton = info.data.confirmButtonText;
    });
  }

  submit(): void {
    this.validForm = this.downloadForm.valid;
    this.validPhoneNumber = this.controls["phone_number"].valid;
  }

  get controls() {
    return this.downloadForm.controls;
  }

  public openModal(content) {
    this.modalService.open(content);
  }

  public applyAccount() {
    this.sendEmail().subscribe((result) => {
      this.success = true;
      this.successMessage = result.message;
    });
  }

  public getPopupInfo(): Observable<any> {
    const url = `client/MT5AccountInfo`;
    return this.newHttp.get<any>(url);
  }

  public sendEmail(): Observable<any> {
    const url = `client/MT5AccountNotification`;
    return this.newHttp.post<any>(url, null);
  }
}
