import { Component, OnInit } from "@angular/core";
import { DepositTypeService } from "../../../services/deposit-type.service";
import { RegistrationService } from "../../../../../../auth/services/registration/registration.service";

@Component({
  selector: "app-success-message",
  templateUrl: "./success-message.component.html",
  styleUrls: ["./success-message.component.scss"]
})
export class SuccessMessageComponent implements OnInit {
  depositInfo: object;
  verifyAccountLink: string = "/verify-account";
  swissCapital: boolean = false;
  constructor(
    private depositTypeService: DepositTypeService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit() {
    this.depositInfo = this.depositTypeService.getDepositInfo();

    // if (window.location.hostname === "portal.swisscapital.fm") {
    //   this.swissCapital = true;
    // }
  }

  submit() {
    this.registrationService.redirectToNextRegistrationStep();
  }
}
