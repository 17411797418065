import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-national-id',
  templateUrl: './national-id.component.html',
  styleUrls: ['./national-id.component.scss']
})
export class NationalIdComponent implements OnInit {

  nationalIdForm: FormGroup;
  validNationalId = true;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.nationalIdForm = this.formBuilder.group({
      national_id: ['', [Validators.required]],
    });
  }

  submit(): void {
    this.validNationalId = this.controls['national_id'].valid;
  }

  get controls() { return this.nationalIdForm.controls; }

}
